import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { dateStandardFormat, getHour } from 'core/utils/date';
import { get, set } from 'lodash';
import { FlexDiv, SpanEllipsis } from 'views/custom-declaration/declaration-table/components/DeclarationsTable.styled';
import { toTitleCase } from 'views/declarations/utils/validation-utils';
import { Declaration } from '../../../../store/declarations/declaration';
import { Column } from './DeclarationHistoryTable';
import config from 'config/config';

const dmsCodeNotificationTypeMap: Record<string, string> = {
    '01': 'DMSACC',
    '02': 'DMSRCV',
    '03': 'DMSREJ',
    '05': 'DMSCTL',
    '06': 'DMSDOC',
    '07': 'DMSRES',
    '08': 'DMSROG',
    '09': 'DMSCLE',
    '10': 'DMSINV',
    '11': 'DMSREQ',
    '13': 'DMSTAX',
    '14': 'DMSCPI',
    '15': 'DMSCPR',
    '16': 'DMSEOG',
    '17': 'DMSEXT',
    '18': 'DMSGER',
    '50': 'DMSALV',
    '51': 'DMSQRY',
};

export const getConversationId = (notification: any, conversationIdKeys: string[] | undefined) => {
    if (conversationIdKeys) {
        for (let key of conversationIdKeys) {
            const value = get(notification, key);
            if (value) {
                return { key, value };
            }
        }
    }
    return { key: undefined, value: undefined };
};

export const HistoryUtils = {
    columns: {
        statusHistory: (
            keys: Column,
            onShowErrors: ({ errors, showRawData }: { errors: Object; showRawData: boolean }) => void,
            declaration: Declaration | undefined
        ): ColumnsType<any> => {
            const cols: ColumnsType<any> = [];
            if (keys.date) {
                cols.push({
                    title: 'Date',
                    dataIndex: 'date',
                    key: 'date',
                    render: (text: string, record: any) => {
                        const date = new Date((record && keys.date && record[keys.date]) ?? '');
                        const testId = 'date-column';
                        return record && keys.date && record[keys.date] ? (
                            {
                                children: (
                                    <FlexDiv data-testid={testId}>
                                        <span>{getHour(date)}</span>
                                        <span>{dateStandardFormat(date)}</span>
                                    </FlexDiv>
                                ),
                            }
                        ) : (
                            <SpanEllipsis data-testid={testId}>-</SpanEllipsis>
                        );
                    },
                    align: 'left',
                });
            }
            if (keys.user) {
                cols.push({
                    title: 'User',
                    dataIndex: 'user',
                    key: 'user',
                    render: (text: string, record: any) => {
                        const testId = 'user-column';
                        return record && keys.user && record[keys.user] ? (
                            {
                                children: <SpanEllipsis data-testid={testId}>{record[keys.user]}</SpanEllipsis>,
                            }
                        ) : (
                            <SpanEllipsis data-testid={testId}>-</SpanEllipsis>
                        );
                    },
                });
            }
            if (keys.action) {
                cols.push({
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    render: (text: string, record: any) => {
                        const testId = 'action-column';
                        return record && keys.action && record[keys.action] ? (
                            {
                                children: (
                                    <SpanEllipsis data-testid={testId}>{toTitleCase(record[keys.action])}</SpanEllipsis>
                                ),
                            }
                        ) : (
                            <SpanEllipsis data-testid={testId}>-</SpanEllipsis>
                        );
                    },
                });
            }
            if (keys.notificationType) {
                cols.push({
                    title: 'Notification Type',
                    dataIndex: 'notification-type',
                    key: 'notification-type',
                    render: (text: string, record: any) => {
                        const testId = 'notification-type-column';
                        return record && keys.notificationType && record[keys.notificationType] ? (
                            {
                                children: (
                                    <SpanEllipsis data-testid={testId}>
                                        {HistoryUtils.Cds.dmsCodeToNotificationType(record[keys.notificationType])}
                                    </SpanEllipsis>
                                ),
                            }
                        ) : (
                            <SpanEllipsis data-testid={testId}>-</SpanEllipsis>
                        );
                    },
                });
            }
            if (keys.description) {
                cols.push({
                    title: 'Description',
                    dataIndex: 'description',
                    key: 'description',
                    render: (text: string, record: any) => {
                        const testId = 'description-column';
                        return record && keys.description && record[keys.description] ? (
                            {
                                children: <SpanEllipsis data-testid={testId}>{record[keys.description]}</SpanEllipsis>,
                            }
                        ) : (
                            <SpanEllipsis data-testid={testId}>-</SpanEllipsis>
                        );
                    },
                });
            }
            if (keys.conversationId) {
                cols.push({
                    title: 'Transaction Id',
                    dataIndex: 'conversationId',
                    key: 'conversationId',
                    render: (text: string, record: any) => {
                        const testId = 'conversationId-column';
                        const conversationId = getConversationId(record, keys.conversationId);
                        return record && conversationId.key && conversationId.value ? (
                            {
                                children: <SpanEllipsis data-testid={testId}>{conversationId.value}</SpanEllipsis>,
                            }
                        ) : (
                            <SpanEllipsis data-testid={testId}>-</SpanEllipsis>
                        );
                    },
                });
            }
            if (keys.errors) {
                cols.push({
                    title: 'Errors',
                    dataIndex: 'errors',
                    key: 'errors',
                    render: (text: string, record: any) => {
                        const testId = 'errors-column';
                        return record && hasErrors(record) ? (
                            {
                                children: (
                                    <SpanEllipsis data-testid={testId}>
                                        <Button
                                            danger
                                            type="link"
                                            onClick={() =>
                                                onShowErrors({
                                                    errors: set(
                                                        {
                                                            declarationExternalEntity:
                                                                declaration?.declarationExternalEntity,
                                                            declarationInternalType:
                                                                declaration?.declarationInternalType,
                                                        },
                                                        getCurrentDeclarationNotificationsPath(declaration) ?? '',
                                                        [record.errors]
                                                    ),
                                                    showRawData: false,
                                                })
                                            }
                                        >
                                            View errors
                                        </Button>
                                    </SpanEllipsis>
                                ),
                            }
                        ) : (
                            <SpanEllipsis data-testid={testId}>-</SpanEllipsis>
                        );
                    },
                });
            }
            if (keys.rawMessage && config.isPIT) {
                cols.push({
                    title: 'Raw Data',
                    dataIndex: 'rawMessage',
                    key: 'rawMessage',
                    render: (text: string, record: any) => {
                        const testId = 'raw-data-column';
                        return record && (record.rawMessage || record.notification) ? (
                            {
                                children: (
                                    <SpanEllipsis data-testid={testId}>
                                        <Button
                                            danger
                                            type="link"
                                            onClick={() =>
                                                onShowErrors({
                                                    errors: set(
                                                        {
                                                            declarationExternalEntity:
                                                                declaration?.declarationExternalEntity,
                                                            declarationInternalType:
                                                                declaration?.declarationInternalType,
                                                            actionRequired: declaration?.actionRequired,
                                                        },
                                                        getCurrentDeclarationNotificationsPath(declaration) ?? '',
                                                        [record.errors || record.notification]
                                                    ),
                                                    showRawData: true,
                                                })
                                            }
                                        >
                                            View raw data
                                        </Button>
                                    </SpanEllipsis>
                                ),
                            }
                        ) : (
                            <SpanEllipsis data-testid={testId}>-</SpanEllipsis>
                        );
                    },
                });
            }
            return cols;
        },
    },
    Cds: {
        dmsCodeToNotificationType(dmsCode: string): string | undefined {
            return dmsCodeNotificationTypeMap[dmsCode] ?? undefined;
        },
    },
};

const getCurrentDeclarationNotificationsPath = (declaration: Declaration | undefined): string | undefined => {
    if (!declaration) return undefined;
    if (declaration.irelandImportDeclaration) {
        return 'irelandImportDeclaration.notifications';
    } else if (declaration.irelandH7ImportDeclaration) {
        return 'irelandH7ImportDeclaration.notifications';
    } else if (declaration.entrySummaryDeclaration) {
        return 'entrySummaryDeclaration.notifications';
    } else if (declaration.cdsDeclaration) {
        return 'cdsDeclaration.notifications';
    } else if (declaration.ieExportDeclaration) {
        return 'ieExportDeclaration.notifications';
    } else if (declaration.gvmsDeclaration) {
        return 'gvmsDeclaration.notifications';
    } else if (declaration.ieNctsDeclaration) {
        return 'ieNctsDeclaration.notifications';
    } else {
        throw new Error('Can"t get declaration type');
    }
};

const hasErrors = (notification: any | undefined) => {
    return (
        !!notification?.submissionErrors?.length ||
        !!notification?.validationMessages?.length ||
        !!notification?.notification?.functionalError?.length ||
        !!notification?.notification?.xmlError?.length ||
        !!notification?.message?.ruleFailures
    );
};
