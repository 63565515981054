export enum AISMessageTypes {
    H1 = 'H1',
    H7 = 'H7',
}

export enum AESMessageTypes {
    B1 = 'B1',
    B2 = 'B2',
    B3 = 'B3',
    B4 = 'B4',
    C1 = 'C1',
    C2 = 'C2',
}

export enum ARRIVALMessageTypes {
    ARRIVAL = 'ARRIVAL',
}

export enum CDSImportMessageTypes {
    H1 = 'H1',
    H2 = 'H2',
}

export enum CDSExportMessageTypes {
    B1 = 'B1',
}

export enum ENSMessageTypes {
    ENS = 'ENS',
}

export enum GVMSMessageTypes {
    STANDARD_MOVEMENT_GMR = 'STANDARD_MOVEMENT_GMR',
    TIR_MOVEMENT_GMR = 'TIR_MOVEMENT_GMR',
    UNACCOMPANIED_ATA_CARNET_CONTAINER_MOVEMENT = 'UNACCOMPANIED_ATA_CARNET_CONTAINER_MOVEMENT',
    INDIRECT_EXPORT_DECLARATIONS_MOVEMENT_GMR = 'INDIRECT_EXPORT_DECLARATIONS_MOVEMENT_GMR',
    EMPTY_MOVEMENT_GMR = 'EMPTY_MOVEMENT_GMR',
    ORAL_OR_BY_CONDUCT_DECLARATION = 'ORAL_OR_BY_CONDUCT_DECLARATION',
    EXEMPTION_MOVEMENT_RECORD = 'EXEMPTION_MOVEMENT_RECORD',
    UKC = 'UKC',
}

export enum TSDMessageTypes {
    G4 = 'G_4',
    G4G3 = 'G_4_G_3',
}

export enum NCTSMessageTypes {
    D1 = 'D1',
    D2 = 'D2',
    D3 = 'D3',
    D4 = 'D4',
}

export enum ETDMessageTypes {
    D3 = 'D3',
}

export enum DeclarationTypes {
    CDS = 'CDS',
    ENS = 'ENS',
    NCTS = 'NCTS',
    AIS = 'AIS',
    ETD = 'ETD',
    AES = 'AES',
}
export const MessageTypesMerge = {
    ...AISMessageTypes,
    ...AESMessageTypes,
    ...ARRIVALMessageTypes,
    ...CDSImportMessageTypes,
    ...CDSExportMessageTypes,
    ...ENSMessageTypes,
    ...GVMSMessageTypes,
    ...TSDMessageTypes,
    ...NCTSMessageTypes,
    ...ETDMessageTypes,
};

export type MessageTypes =
    | AISMessageTypes
    | AESMessageTypes
    | ARRIVALMessageTypes
    | CDSImportMessageTypes
    | CDSExportMessageTypes
    | ENSMessageTypes
    | GVMSMessageTypes
    | TSDMessageTypes
    | NCTSMessageTypes;
