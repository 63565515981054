import { objectIsEmpty } from 'core/utils/objects';
import { FormikValues } from 'formik';
import { get, isArray, isBoolean, isEqual, isObject, isString, some } from 'lodash';
import { DeclarationCustomer } from 'store/declarations/common/declaration-customer';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationName } from 'store/declarations/enums/common/declaration-name';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import {
    EnsGoodsShipmentItem,
    IrelandEntrySummaryDeclaration,
} from 'store/declarations/ireland/entry-summary-declaration';
import { IrelandExportDeclaration, IrelandExportItem } from 'store/declarations/ireland/export-declaration';
import { IrelandH7ImportDeclaration } from 'store/declarations/ireland/h7-import-declaration';
import { IrelandImportDeclaration } from 'store/declarations/ireland/import-declaration';
import { CdsDeclaration } from 'store/declarations/uk/cds-declaration';
import { ArrivalAtExitDeclaration } from '../../../store/declarations/ireland/arrival-at-exit-declaration';
import { ElectronicTransportDocument } from '../../../store/declarations/ireland/electronic-transport-document';
import { NctsDeclaration } from '../../../store/declarations/ireland/ncts-declaration';
import { IrelandPbnRecord } from '../../../store/declarations/ireland/pbn-declaration';
import { IrelandTemporaryStorageDeclaration } from '../../../store/declarations/ireland/temporary-storage-declaration';
import { UkGvmsRecord } from '../../../store/declarations/uk/gvms-declaration';
import { DeclarationType } from '../common/declarationType';
import { DeclarationTypes } from 'store/declarations/enums/common/declaration-types';

export const disableSaveOrSubmitDeclarationButton = (status: DeclarationStatus) =>
    status !== DeclarationStatus.DRAFT &&
    status !== DeclarationStatus.INVALID &&
    status !== DeclarationStatus.REJECTED &&
    status !== DeclarationStatus.DECLARATION_REJECTED;

export const canDeclarationBeInvalidated = (status: DeclarationStatus | undefined) => {
    if (!status) return false;
    return (
        status === DeclarationStatus.REGISTERED ||
        status === DeclarationStatus.ACCEPTED ||
        status === DeclarationStatus.INSUFFICIENT_FUNDS
    );
};

export type DeclarationsPayloads =
    | CdsDeclaration
    | IrelandImportDeclaration
    | IrelandH7ImportDeclaration
    | IrelandExportDeclaration
    | IrelandEntrySummaryDeclaration
    | UkGvmsRecord
    | IrelandEntrySummaryDeclaration
    | ElectronicTransportDocument
    | IrelandTemporaryStorageDeclaration
    | ArrivalAtExitDeclaration
    | IrelandPbnRecord
    | NctsDeclaration
    | undefined;

export interface DescriptionOfGoodsInformation {
    numberOfItems: number;
    netMass: number;
    grossMass: number;
    numberOfPackages: number;
}

const EMPTY_DESCRIPTION_OF_GOODS: Readonly<DescriptionOfGoodsInformation> = {
    numberOfItems: 0,
    netMass: 0.0,
    grossMass: 0.0,
    numberOfPackages: 0.0,
};

export const getDeclarationPayloadItems = (declaration: any) => {
    if (!declaration) return null;

    return (
        declaration?.goodsShipment?.goodsShipmentItem ??
        declaration?.goodsShipment?.governmentAgencyGoodsItem ??
        declaration?.goodsShipment?.goodsItem ??
        declaration?.goodsShipment?.goodsItems ??
        declaration?.goodsShipment?.goodsShipmentItems ??
        declaration?.goodsShipment?.goodsItem ??
        declaration?.goodsShipment?.goodsShipmentItem ??
        declaration?.consignment?.houseConsignment?.map((hc: any) => hc.consignmentItem).flat() ??
        declaration?.cdsGovernmentAgencyGoodsItems ??
        null
    );
};

export const getDeclarationItems = (declaration: Declaration | undefined): any | null => {
    if (!declaration) return null;

    return (
        declaration.irelandImportDeclaration?.goodsShipment?.goodsShipmentItem ??
        declaration.irelandH7ImportDeclaration?.goodsShipment?.governmentAgencyGoodsItem ??
        declaration.ieExportDeclaration?.goodsShipment?.goodsItem ??
        declaration.entrySummaryDeclaration?.goodsShipment?.goodsItems ??
        declaration.ieImportElectronicTransportDocument?.goodsShipment?.goodsShipmentItem ??
        declaration.ieArrivalAtExitDeclaration?.goodsShipment?.goodsItem ??
        declaration.ieImportTemporaryStorageDeclaration?.goodsShipment?.goodsShipmentItem ??
        declaration.preBoardingNotification ??
        declaration.ieNctsDeclaration?.consignment.houseConsignment.map((hc) => hc.consignmentItem).flat() ??
        declaration.cdsDeclaration?.cdsGovernmentAgencyGoodsItems ??
        declaration.gvmsDeclaration
    );
};

export const getDeclarationType = (declaration: Declaration | undefined): DeclarationType | null => {
    if (!declaration) return null;

    if (declaration.irelandImportDeclaration) return DeclarationType.IE_IMPORT_H1;
    if (declaration.irelandH7ImportDeclaration) return DeclarationType.IE_IMPORT_H7;
    if (declaration.ieExportDeclaration) return DeclarationType.IE_EXPORT;
    if (declaration.entrySummaryDeclaration) return DeclarationType.IE_ENS;
    if (declaration.ieImportElectronicTransportDocument) return DeclarationType.IE_ETD;
    if (declaration.ieArrivalAtExitDeclaration) return DeclarationType.IE_ARRIVAL;
    if (declaration.ieImportTemporaryStorageDeclaration) return DeclarationType.IE_TSD;
    if (declaration.preBoardingNotification) return DeclarationType.PBN;
    if (declaration.ieNctsDeclaration) return DeclarationType.IE_NCTS;
    if (declaration.cdsDeclaration) return DeclarationType.UK;
    if (declaration.gvmsDeclaration) return DeclarationType.GVMS;

    return null;
};

export const processDeclaration = (declaration: Declaration | undefined) => {
    const declarationType = getDeclarationType(declaration);

    switch (declarationType) {
        case DeclarationType.IE_IMPORT_H1:
        case DeclarationType.IE_IMPORT_H7:
            return DeclarationTypes.AIS;
        case DeclarationType.IE_ENS:
            return DeclarationTypes.ENS;
        case DeclarationType.IE_ETD:
            return DeclarationTypes.ETD;
        case DeclarationType.IE_EXPORT:
            return DeclarationTypes.AES;
        case DeclarationType.IE_NCTS:
            return DeclarationTypes.NCTS;
        case DeclarationType.UK:
            return DeclarationTypes.CDS;
        default:
            return undefined;
    }
};

export const getDeclarationItemsPath = (declaration: Declaration | undefined): string | null => {
    if (!declaration) return null;

    if (declaration.irelandImportDeclaration) return 'goodsShipment.goodsShipmentItem';
    if (declaration.irelandH7ImportDeclaration) return 'goodsShipment.governmentAgencyGoodsItem';
    if (declaration.ieExportDeclaration) return 'goodsShipment.goodsItem';
    if (declaration.entrySummaryDeclaration) return 'goodsShipment.goodsItem';
    if (declaration.ieImportElectronicTransportDocument) return 'goodsShipment.goodsShipmentItems';
    if (declaration.ieArrivalAtExitDeclaration) return 'goodsShipment.goodsItem';
    if (declaration.ieImportTemporaryStorageDeclaration) return 'goodsShipment.goodsShipmentItem';
    if (declaration.preBoardingNotification) return '';
    if (declaration.ieNctsDeclaration) return 'consignment.houseConsignment.0.consignmentItem'; // TODO DS
    if (declaration.cdsDeclaration) return 'cdsGovernmentAgencyGoodsItems';
    if (declaration.gvmsDeclaration) return '';

    return null;
};

export const getDeclarationPayload = (declaration: Declaration | undefined): DeclarationsPayloads | null => {
    if (!declaration) return null;

    const payload =
        declaration.irelandImportDeclaration ??
        declaration.irelandH7ImportDeclaration ??
        declaration.ieExportDeclaration ??
        declaration.entrySummaryDeclaration ??
        declaration.ieImportElectronicTransportDocument ??
        declaration.ieArrivalAtExitDeclaration ??
        declaration.ieImportTemporaryStorageDeclaration ??
        declaration.preBoardingNotification ??
        declaration.ieNctsDeclaration ??
        declaration.cdsDeclaration ??
        declaration.gvmsDeclaration;

    return payload;
};

export const getMessageTypeByDeclaration = (declaration: Declaration) => {
    if (declaration.declarationExternalEntity === DeclarationExternalEntity.REVENUE) {
        if (declaration.declarationInternalType === DeclarationInternalType.IMPORT) {
            return declaration.irelandImportDeclaration
                ? declaration.irelandImportDeclaration?.messageType
                : DeclarationName.H7;
        } else if (declaration.declarationInternalType === DeclarationInternalType.EXPORT) {
            return declaration.ieExportDeclaration?.messageType;
        } else if (declaration.declarationInternalType === DeclarationInternalType.ENS) {
            return DeclarationName.ENS;
        } else if (declaration.declarationInternalType === DeclarationInternalType.ETD) {
            return declaration?.ieImportElectronicTransportDocument?.declaration?.messageType;
        } else if (declaration.declarationInternalType === DeclarationInternalType.ARRIVAL) {
            return DeclarationName.ARRIVAL;
        } else if (declaration.declarationInternalType === DeclarationInternalType.TEMPORARY) {
            return declaration?.ieImportTemporaryStorageDeclaration?.declaration?.msgType;
        } else if (declaration?.declarationInternalType === DeclarationInternalType.PBN) {
            return '-';
        } else if (declaration?.declarationInternalType === DeclarationInternalType.NCTS) {
            return declaration?.ieNctsDeclaration?.messageType;
        }
    } else if (declaration.declarationExternalEntity === DeclarationExternalEntity.CDS) {
        return declaration.cdsDeclaration?.messageType;
    } else if (declaration.declarationExternalEntity === DeclarationExternalEntity.GVMS) {
        return declaration.gvmsDeclaration?.messageType;
    }

    console.error(`Unhandled declaration internal type ${declaration.declarationInternalType}`);

    return '-';
};

export const externalEntityMap: Record<DeclarationExternalEntity, string> = {
    [DeclarationExternalEntity.REVENUE]: 'Irish',
    [DeclarationExternalEntity.CDS]: 'UK',
    [DeclarationExternalEntity.CHIEF]: 'UK',
    [DeclarationExternalEntity.GVMS]: 'UK',
};

export const getDeclarationCountry = (declaration: Declaration) => {
    if (declaration.declarationExternalEntity === DeclarationExternalEntity.REVENUE) {
        return 'IE';
    } else {
        return 'UK';
    }
};

export const getExporterName = (payload: {
    exporter?: DeclarationCustomer;
    consignor?: DeclarationCustomer;
    revenueExportGoodsShipment?: IrelandExportItem;
    goodsShipment?: any;
}): string => {
    const exporter = getExporter(payload);
    return exporter?.name ?? exporter?.eori ?? 'N/A';
};

export const getExporter = (payload: {
    exporter?: DeclarationCustomer;
    goodsShipment?: {
        exporter?: DeclarationCustomer;
    };
}): DeclarationCustomer | undefined => {
    const exporter = payload?.exporter; // IE H1, IE Export, CDS Export
    const goodsShipmentExporter = payload?.goodsShipment?.exporter; // IE H7

    return exporter ?? goodsShipmentExporter ?? undefined;
};

export const getImporterName = (payload: {
    consignee?: DeclarationCustomer;
    goodsShipment?: { importer?: DeclarationCustomer; goodsItems?: EnsGoodsShipmentItem[] };
    revenueExportGoodsShipment?: IrelandExportItem;
}): string => {
    const importer = getImporter(payload);
    return importer?.name ?? importer?.eori ?? 'N/A';
};

export const getImporter = (payload: {
    goodsShipment?: {
        importer?: DeclarationCustomer;
    };
}): DeclarationCustomer | undefined => {
    const goodsShipmentImporter = payload?.goodsShipment?.importer; // AIS, CDS

    return goodsShipmentImporter ?? undefined;
};

export const getDeclarantName = (payload: {
    declarant?: DeclarationCustomer;
    representative?: DeclarationCustomer;
    representativeEori?: string;
    personLodgingTheSummaryDeclaration?: DeclarationCustomer;
}): string => {
    if (payload?.declarant) {
        return payload?.declarant?.name ?? payload?.declarant?.eori ?? 'N/A';
    } else {
        if (payload?.representative) {
            return payload.representative.name ?? payload.representative.eori ?? 'N/A';
        } else if (payload?.representativeEori) {
            return payload.representativeEori ?? 'N/A';
        }

        if (payload?.personLodgingTheSummaryDeclaration) {
            return (
                payload.personLodgingTheSummaryDeclaration.name ??
                payload.personLodgingTheSummaryDeclaration.eori ??
                'N/A'
            );
        }
    }
    return 'N/A';
};

export const getReferenceNumber = (declaration: Declaration) => {
    return declaration?.referenceNumber ?? 'N/A';
};

export const getMrn = (declaration: Declaration) => {
    return declaration?.mrn;
};

export const getLrn = (declaration: Declaration, lrnPath: string) => {
    const payload = getDeclarationPayload(declaration);
    return get(payload, lrnPath);
};

const getIrelandH1ItemsInfo = (declaration: Declaration): DescriptionOfGoodsInformation => {
    const items = declaration?.irelandImportDeclaration?.goodsShipment?.goodsShipmentItem;
    if (items) {
        const numberOfItems = items?.length ?? 0;
        const toNumber = (value: any) => {
            const number = parseFloat(value);
            if (Number.isNaN(number)) return null;
            return number;
        };
        const netMass = items.map((i) => toNumber(i.goodsInformation?.netMass) ?? 0.0).reduce((a, b) => a + b, 0);
        const grossMass = items.map((i) => toNumber(i.goodsInformation?.netMass) ?? 0.0).reduce((a, b) => a + b, 0);

        const numberOfPackages = items
            .map((i) =>
                i.goodsInformation?.packaging
                    ? i.goodsInformation?.packaging.map((p) => +(p.packageNumber ?? 0)).reduce((a, b) => a + b, 0)
                    : 0
            )
            .reduce((a, b) => a + b, 0);

        return { numberOfItems, netMass, grossMass, numberOfPackages };
    }
    return EMPTY_DESCRIPTION_OF_GOODS;
};

const getIrelandH7ItemsInfo = (declaration: Declaration): DescriptionOfGoodsInformation => {
    const items = declaration?.irelandH7ImportDeclaration?.goodsShipment?.governmentAgencyGoodsItem;
    if (items) {
        const numberOfItems = items?.length ?? 0;
        const netMass = 0.0;
        const grossMass = declaration?.irelandH7ImportDeclaration?.goodsShipment?.grossMass ?? 0.0;
        const numberOfPackages = items.map((i) => +(i?.packagingNumberOfPackages ?? 0)).reduce((a, b) => a + b, 0);
        return { numberOfItems, netMass, grossMass, numberOfPackages };
    }
    return EMPTY_DESCRIPTION_OF_GOODS;
};

const getIrelandExportItemsInfo = (declaration: Declaration) => {
    const items = declaration?.ieExportDeclaration?.goodsShipment?.goodsItem;
    if (items) {
        const numberOfItems = items?.length ?? 0;
        const netMass = items?.map((i) => +(i?.commodity?.goodsMeasure?.netMass ?? 0.0)).reduce((a, b) => a + b, 0);
        const grossMass = items.map((i) => +(i?.commodity?.goodsMeasure?.grossMass ?? 0.0)).reduce((a, b) => a + b, 0);
        const numberOfPackages = items
            .map((i) => +(i?.packaging?.reduce((acc, current) => +(current.numberOfPackages ?? 0) + acc, 0) ?? 0))
            .reduce((a, b) => a + b, 0);
        return { numberOfItems, netMass, grossMass, numberOfPackages };
    }
    return EMPTY_DESCRIPTION_OF_GOODS;
};

const getIrelandEnsItemsInfo = (declaration: Declaration) => {
    const items = declaration?.entrySummaryDeclaration?.goodsShipment?.goodsItems;
    if (items) {
        const numberOfItems = items?.length ?? 0;
        const netMass = 0.0;
        const grossMass = declaration?.entrySummaryDeclaration?.totalGrossMass ?? 0.0;
        const numberOfPackages = items
            .map((i) =>
                i.goodsPackaging ? i.goodsPackaging.map((p) => +(p.marks ?? 0)).reduce((a, b) => a + b, 0) : 0
            )
            .reduce((a, b) => a + b, 0);
        return { numberOfItems, netMass, grossMass, numberOfPackages };
    }
    return EMPTY_DESCRIPTION_OF_GOODS;
};

const getIrelandArrivalItemsInfo = (declaration: Declaration) => {
    const items = declaration?.ieArrivalAtExitDeclaration?.goodsShipment?.goodsItem;
    if (items) {
        const numberOfItems = items?.length ?? 0;
        const netMass = items?.map((i) => +(i?.commodity?.goodsMeasure?.netMass ?? 0.0)).reduce((a, b) => a + b, 0);
        const grossMass = items.map((i) => +(i?.commodity?.goodsMeasure?.grossMass ?? 0.0)).reduce((a, b) => a + b, 0);
        const numberOfPackages = items
            .map((i) => +(i?.packaging?.reduce((acc, current) => +(current.numberOfPackages ?? 0) + acc, 0) ?? 0))
            .reduce((a, b) => a + b, 0);
        return { numberOfItems, netMass, grossMass, numberOfPackages };
    }
    return EMPTY_DESCRIPTION_OF_GOODS;
};

const getIrelandTemporaryItemsInfo = (declaration: Declaration) => {
    const items = declaration?.ieImportTemporaryStorageDeclaration?.goodsShipment?.goodsShipmentItem;
    if (items) {
        const numberOfItems = items?.length ?? 0;
        const netMass = 0.0;
        const grossMass = items.map((i) => +(i?.goodsInformation.grossMass65 ?? 0.0)).reduce((a, b) => a + b, 0);
        const numberOfPackages = items
            .map(
                (i) =>
                    +(
                        i?.goodsInformation.packaging?.reduce(
                            (acc, current) => +(current.packageNumber610 ?? 0) + acc,
                            0
                        ) ?? 0
                    )
            )
            .reduce((a, b) => a + b, 0);
        return { numberOfItems, netMass, grossMass, numberOfPackages };
    }
    return EMPTY_DESCRIPTION_OF_GOODS;
};

const getIrelandEtdItemsInfo = (declaration: Declaration) => {
    const items = declaration?.ieImportElectronicTransportDocument?.goodsShipment?.goodsShipmentItem;
    if (items) {
        const numberOfItems = items?.length ?? 0;
        const netMass = 0.0;
        const grossMass = items.map((i) => +(i?.goodsInformation?.grossMass65 ?? 0.0)).reduce((a, b) => a + b, 0);
        const numberOfPackages = items
            .map(
                (i) =>
                    +(
                        i?.goodsInformation?.packaging?.reduce(
                            (acc, current) => +(current.packageNumber610 ?? 0) + acc,
                            0
                        ) ?? 0
                    )
            )
            .reduce((a, b) => a + b, 0);
        return { numberOfItems, netMass, grossMass, numberOfPackages };
    }
    return EMPTY_DESCRIPTION_OF_GOODS;
};

const getIrelandNctsItemsInfo = (declaration: Declaration): DescriptionOfGoodsInformation => {
    const items = declaration?.ieNctsDeclaration?.consignment?.houseConsignment;
    if (items) {
        const numberOfItems =
            items?.reduce((total, consignment) => {
                return total + consignment.consignmentItem.length;
            }, 0) ?? 0;

        const netMass =
            items.reduce((total, consignment) => {
                const consignmentTotal =
                    consignment.consignmentItem.reduce((subTotal, item) => {
                        const netMass = item.commodity?.goodsMeasure?.netMass || 0;
                        return subTotal + netMass;
                    }, 0) || 0;
                return total + consignmentTotal;
            }, 0) || 0;

        const grossMass = declaration?.ieNctsDeclaration?.consignment?.grossMass ?? 0.0;

        const numberOfPackages =
            items?.reduce((total, consignment) => {
                const consignmentTotal =
                    consignment?.consignmentItem?.reduce((subTotal, item) => {
                        const packagingLength = item.packaging ? item.packaging.length : 0;
                        return subTotal + packagingLength;
                    }, 0) || 0;

                return total + consignmentTotal;
            }, 0) || 0;

        return { numberOfItems, netMass, grossMass, numberOfPackages };
    }
    return EMPTY_DESCRIPTION_OF_GOODS;
};

const getUkExportItemsInfo = (declaration: Declaration) => {
    const items = declaration?.cdsDeclaration?.cdsGovernmentAgencyGoodsItems?.map(
        ({ governmentAgencyGoodsItem }) => governmentAgencyGoodsItem
    );
    if (items) {
        const numberOfItems = items?.length ?? 0;
        const numberOfPackages = items
            .map(
                (i) =>
                    i?.packaging?.reduce((acc, { quantityQuantity }) => acc + (Number(quantityQuantity) || 0), 0) ?? 0
            )
            .reduce((acc, current) => acc + current, 0);
        const netMass = items
            .map((i) => i?.commodity?.goodsMeasure?.netNetWeightMeasure ?? 0.0)
            .reduce((a: any, b: any) => a + b, 0);
        const grossMass = items
            .map((i) => i?.commodity?.goodsMeasure?.grossMassMeasure ?? 0.0)
            .reduce((a: any, b: any) => a + b, 0);
        return { numberOfItems, netMass, grossMass, numberOfPackages };
    }
    return EMPTY_DESCRIPTION_OF_GOODS;
};

export const getDescriptionOfGoods = (declaration: Declaration): DescriptionOfGoodsInformation => {
    if (declaration.declarationExternalEntity === DeclarationExternalEntity.REVENUE) {
        if (declaration.declarationInternalType === DeclarationInternalType.IMPORT) {
            return declaration.irelandImportDeclaration
                ? getIrelandH1ItemsInfo(declaration)
                : getIrelandH7ItemsInfo(declaration);
        } else if (declaration.declarationInternalType === DeclarationInternalType.EXPORT) {
            return getIrelandExportItemsInfo(declaration);
        } else if (declaration.declarationInternalType === DeclarationInternalType.ENS) {
            return getIrelandEnsItemsInfo(declaration);
        } else if (declaration.declarationInternalType === DeclarationInternalType.NCTS) {
            return getIrelandNctsItemsInfo(declaration);
        } else if (declaration.declarationInternalType === DeclarationInternalType.ARRIVAL) {
            return getIrelandArrivalItemsInfo(declaration);
        } else if (declaration.declarationInternalType === DeclarationInternalType.TEMPORARY) {
            return getIrelandTemporaryItemsInfo(declaration);
        } else if (declaration.declarationInternalType === DeclarationInternalType.ETD) {
            return getIrelandEtdItemsInfo(declaration);
        }
    } else if (declaration.declarationExternalEntity === DeclarationExternalEntity.CDS) {
        return getUkExportItemsInfo(declaration);
    }

    return EMPTY_DESCRIPTION_OF_GOODS;
};

const getObjectDifferences = (data: FormikValues, oldData: FormikValues) => {
    const record: any = {};
    Object.keys(data).forEach((key: string) => {
        const old = oldData[key] ?? {};
        const current = data[key] ?? {};
        if (isString(current) || Number.isFinite(current) || isBoolean(current) || !current) {
            if (!isEqual(old, current)) {
                record[key] = current;
            }
        } else {
            if (!isEqual(current, old)) {
                record[key] = getObjectDifferences(current, old);
            }
        }
    });
    return record;
};

const handleEmptyObjects = (data: any, contains: boolean[]) => {
    Object.values(data).forEach((d) => {
        if (isObject(d)) {
            if (objectIsEmpty(d)) {
                contains.push(true);
            } else {
                handleEmptyObjects(d, contains);
            }
        } else {
            if (isArray(d)) {
                d.forEach((obj) => {
                    if (obj && Object.keys(obj).length === 0) {
                        contains.push(true);
                    }
                });
            }
        }
    });
};

/**
 * @param initialValue
 * @param currentValue
 * @returns
 * False if no changes are made on the declaration\
 * True if changes are made on the declaration
 */
// TODO CHECK LOGIC There is an issue with this implementation that is why in some places only isEqual is used for the comparison.
// In some occasions it says that the declaration is not changed but it actually it is.
export const declarationChanged = (initialValue: FormikValues, currentValue: FormikValues) => {
    const initial = { ...initialValue };
    const current = { ...currentValue };
    if (isEqual(initial, current)) {
        return false;
    } else {
        const diff = getObjectDifferences(current, initial);
        const containsEmptyObjects: [] = [];
        handleEmptyObjects(diff, containsEmptyObjects);
        if (containsEmptyObjects?.length) {
            if (containsEmptyObjects.every((o) => !!o === true)) {
                return false;
            } else {
                return true;
            }
        }
        return true;
    }
};

export const isDeclarationAmendable = (declaration: Declaration) => {
    const amendmentStatuses = [
        DeclarationStatus.ACCEPTED,
        DeclarationStatus.REGISTERED,
        DeclarationStatus.AWAITING_RISK,
        DeclarationStatus.INSUFFICIENT_FUNDS,
        DeclarationStatus.UNDER_PAYMENT,
        DeclarationStatus.RISKED,
        DeclarationStatus.UNDER_CONTROL,
    ];

    const gvmsAmendmentStatuses = [
        DeclarationStatus.OPEN,
        DeclarationStatus.NOT_FINALISABLE,
        DeclarationStatus.CHECKED_IN,
    ];

    const isAmendable = (statuses: DeclarationStatus[], exclude = false) => {
        const hasSome = some(statuses, (status) => declaration?.status === status);
        return exclude ? !hasSome : hasSome;
    };

    if (declaration?.gvmsDeclaration) {
        return isAmendable(gvmsAmendmentStatuses);
    }

    return isAmendable(amendmentStatuses);
};

export const isDeclarationRefundRemissionRequested = (declaration: Declaration | undefined) => {
    const declarationType = declaration?.irelandImportDeclaration ?? declaration?.irelandH7ImportDeclaration;

    return Boolean(declarationType?.refundApplicationForDebtRemissionRequested);
};

export const isDeclarationRefundDepositRequested = (declaration: Declaration | undefined) => {
    const declarationType = declaration?.irelandImportDeclaration ?? declaration?.irelandH7ImportDeclaration;

    return Boolean(declarationType?.refundApplicationForDebtDepositRequested);
};

export const getDeclarationKey = (
    declaration: Pick<Declaration, 'declarationExternalEntity' | 'declarationInternalType' | 'cdsDeclaration'> & {
        h7?: boolean;
        formType?: string;
    }
) => {
    const getIrelandDeclarationType = () => {
        switch (declaration.declarationInternalType) {
            case DeclarationInternalType.IMPORT:
                if (declaration.h7 || declaration.formType === 'H7') {
                    return DeclarationType.IE_IMPORT_H7;
                } else {
                    return DeclarationType.IE_IMPORT_H1;
                }
            case DeclarationInternalType.EXPORT:
                return DeclarationType.IE_EXPORT;
            case DeclarationInternalType.ENS:
                return DeclarationType.IE_ENS;
            case DeclarationInternalType.ETD:
                return DeclarationType.IE_ETD;
            case DeclarationInternalType.TEMPORARY:
                return DeclarationType.IE_TSD;
            case DeclarationInternalType.ARRIVAL:
                return DeclarationType.IE_ARRIVAL;
            case DeclarationInternalType.PBN:
                return DeclarationType.PBN;
            case DeclarationInternalType.NCTS:
                return DeclarationType.IE_NCTS;
            default:
                return null;
        }
    };

    switch (declaration.declarationExternalEntity) {
        case DeclarationExternalEntity.REVENUE:
            return getIrelandDeclarationType();

        case DeclarationExternalEntity.CDS:
            return DeclarationExternalEntity.CDS;
        case DeclarationExternalEntity.GVMS:
            return DeclarationType.GVMS;
        default:
            // shouldn't happen, or should show an error page
            throw Error('Declaration must have an external entity');
    }
};
