import { Modal } from 'antd';
import config from 'config';
import { FormikProvider, setNestedObjectValues, useFormik } from 'formik';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import MasterDetails from 'views/declarations/MasterDetails';
import { TemplateResponse, Template, TemplateContext, useTemplateContext } from './TemplateContext';
import axiosClient from 'config/axios';
import { SuccessResponse } from 'core/http/response';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import parseTemplateOptions from './parseTemplateOptions';
import DeclarationProductView from 'views/declarations/DeclarationProductView';
import TemplateHeader from './TemplateHeader';
import useTemplates from 'hooks/useTemplates';
import { TransformData } from 'views/declarations/common/declaration-view/DeclarationView';
import { clone, cloneDeep, isEmpty, isEqual, set, setWith } from 'lodash';
import { createTemplate } from 'store/template/client';
import validate, {
    FormModel,
    injectTemplateValidations,
    transformErrorsForFormik,
} from 'views/declarations/uk/export/validations/validations';
import * as Yup from 'yup';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import ValidationErrorContainer from 'views/declarations/common/ValidationErrorContainer/ValidationErrorContainer';
import useGetDeclarationMapValues from '../../../../hooks/useGetDeclarationMapValues';
import { TemplateDashboardState } from '../../../../views/templates/TemplatesDashboard';

const transformTemplateData = (
    template: Template | undefined,
    transform: TransformData | undefined | null,
    target: 'forClient' | 'forServer'
) => {
    const templateCopy = cloneDeep(template);
    if (transform && templateCopy) {
        templateCopy.master.defaults = transform?.declaration?.[target]?.(templateCopy?.master?.defaults, true);
        templateCopy.product.defaults = transform?.product?.[target]?.(
            templateCopy?.product?.defaults?.governmentAgencyGoodsItem ?? templateCopy?.product?.defaults,
            true
        );
    }
    return templateCopy;
};

export const SModalHeader = styled.div`
    position: sticky;
    position: -webkit-sticky; /* For Safari */
    top: 0;
    z-index: 2;
    background-color: white;
    padding-top: 1rem;
`;

export const SModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 8px;
    }
`;

interface Props {
    creating?: boolean;
}

const TemplateModal = ({ creating }: Props): ReactElement => {
    const { listTemplates } = useTemplates();
    const { closeModal, open, templateId, options, setForm, form, isViewOnly, onCreateTemplateListing } =
        useTemplateContext();
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const { setFormDeclarationErrors } = useDeclarationFormErrors();

    const [activeForm, setActiveForm] = useState<'master' | 'product'>('master');

    const [template, setTemplate] = useState<TemplateResponse | undefined>(undefined);
    const [saveChanges, setSaveChanges] = useState<boolean>(false);
    const [editedTemplateName, setEditedTemplateName] = useState<string | null | undefined>(undefined);

    const [errorsModalOpen, setErrorsModalOpen] = useState(false);
    const openErrorsModal = () => setErrorsModalOpen(true);
    const closeErrorsModal = () => setErrorsModalOpen(false);

    const isCds = useMemo(() => options?.country === 'CDS' || options?.country === 'UK', [options?.country]);

    const decViewMapOptions = useMemo(() => {
        const _template = template
            ? (setWith(clone(template), 'formType', options?.formType, clone) as any)
            : undefined;
        const _options = _template ?? {
            declarationInternalType: options?.declarationType,
            declarationExternalType: options?.country === 'IRELAND' ? 'REVENUE' : 'CDS',
            formType: options?.formType,
        };

        _options.declarationExternalEntity = _options.declarationExternalType;
        delete _options.declarationExternalType;
        if (!_options) return null;

        return _options;
    }, [options?.country, options?.declarationType, options?.formType, template]);

    const declarationViewMapValues = useGetDeclarationMapValues({ options: decViewMapOptions });

    const templateValidation = useMemo(() => {
        const master = injectTemplateValidations(declarationViewMapValues?.declarationFormValidations);
        const item = injectTemplateValidations(declarationViewMapValues?.productFormValidations);
        return { master, item };
    }, [declarationViewMapValues?.declarationFormValidations, declarationViewMapValues?.productFormValidations]);

    const templateData = useMemo(() => {
        const data = cloneDeep(template?.template);
        if (isCds && data) {
            data.master.defaults = { cdsDeclarationPayload: data.master.defaults };
        }
        const transformedData = transformTemplateData(data, declarationViewMapValues?.transformData, 'forClient');
        if (isCds && transformedData) {
            transformedData.master.defaults = transformedData.master.defaults.cdsDeclarationPayload;
        }
        return transformedData;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template?.template]);

    const templateFormik = useFormik<Template>({
        initialValues: templateData ?? {
            master: { defaults: declarationViewMapValues?.structure ?? {}, meta: {} },
            product: { defaults: declarationViewMapValues?.itemStructure ?? {}, meta: {} },
        },
        validateOnMount: true,
        validateOnChange: false,
        enableReinitialize: true,
        validationSchema:
            declarationViewMapValues?.declarationValidationSchema && declarationViewMapValues?.productValidationSchema
                ? Yup.object().shape({
                      master: Yup.object().shape({
                          defaults:
                              declarationViewMapValues.declarationValidationSchema instanceof Function
                                  ? declarationViewMapValues.declarationValidationSchema(1)
                                  : declarationViewMapValues.declarationValidationSchema,
                      }),
                      product: Yup.object().shape({
                          defaults: declarationViewMapValues.productValidationSchema,
                      }),
                  })
                : undefined,
        validate:
            declarationViewMapValues?.declarationFormValidations && declarationViewMapValues?.productFormValidations
                ? async (values) => {
                      // TODO: Memoize already validated fields to speed up the process.
                      // If we don't do this, we may end up with a lot of unnecessary requests to eori check service for example.
                      return transformErrorsForFormik(
                          await validate(new FormModel(values), {
                              childValidators: {
                                  'master.defaults': templateValidation.master,
                                  'product.defaults': templateValidation.item,
                              },
                          })
                      );
                  }
                : () => {},
        onSubmit: () => {},
    });

    useEffect(() => {
        if (!templateId) return;

        showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Loading template data...' } });
        axiosClient
            .get<SuccessResponse<TemplateResponse>>(`${config.declarationTemplatesUrl}/${templateId}`)
            .then((res) => {
                hideGlobalOverlay();

                const _template = res.data.payload;
                if (!_template) return;
                setTemplate(_template);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateId]);

    useEffect(() => {
        const isTemplateUpdated = !isEqual(templateFormik.initialValues, templateFormik.values);

        setSaveChanges(isTemplateUpdated);
    }, [templateFormik.initialValues, templateFormik.values]);

    const { path, declaration } = useMemo(() => parseTemplateOptions(options), [options]);

    // This is duplicating
    const edit = async (data: Template | undefined, templateName?: string | null) => {
        const { id, ..._template } = template ?? ({} as TemplateResponse);
        if (!options?.country || !options.declarationType || !options.formType || !data)
            throw new Error('Data missing for creating template');
        const country =
            options.country === 'CDS'
                ? 'uk'
                : options.country === 'REVENUE'
                ? 'ireland'
                : (options.country.toLowerCase() as 'ireland' | 'uk');
        await createTemplate(country, options.declarationType.toLowerCase() as 'import' | 'export', options.formType!, {
            ..._template,
            templateName: templateName ?? 'Template',
            template: data,
        });
    };
    const create = async (data: Template | undefined, templateName?: string | null) => {
        if (!options?.country || !options.declarationType || !options.formType || !data)
            throw new Error('Data missing for creating template');
        const country =
            options.country === 'CDS'
                ? 'uk'
                : options.country === 'REVENUE'
                ? 'ireland'
                : (options.country.toLowerCase() as 'ireland' | 'uk');
        await createTemplate(country, options.declarationType.toLowerCase() as 'import' | 'export', options.formType!, {
            declarationName: options.formType,
            templateName: templateName ?? 'Template',
            template: data,
        });
    };

    const handleOkay = async (templateName?: string | null) => {
        const errors = (await templateFormik.validateForm()) as any;
        if (!isEmpty(errors)) {
            templateFormik.setTouched(setNestedObjectValues(errors, true));
            let newErrors = {
                ...(errors.master?.defaults ?? {}),
            };
            if (declarationViewMapValues?.itemPath)
                newErrors = set(
                    newErrors,
                    declarationViewMapValues.itemPath,
                    errors.product?.defaults ? [errors.product.defaults] : []
                );
            if (declarationViewMapValues?.parseForm) {
                setFormDeclarationErrors(declarationViewMapValues.parseForm(newErrors));
            }
            openErrorsModal();
            return;
        }

        const values = cloneDeep(templateFormik.values);
        if (isCds) {
            values.master.defaults = {
                cdsDeclarationPayload: templateFormik.values.master.defaults,
            };
        }
        const data = transformTemplateData(values, declarationViewMapValues?.transformData, 'forServer');
        if (isCds && data) {
            data.master.defaults = data.master.defaults.cdsDeclarationPayload;
        }
        const isEditing = Boolean(templateId);
        if (isEditing) {
            await edit(data, templateName);
        } else {
            await create(data, templateName);
        }

        const [country, type] = path?.split('/') ?? ['ireland', 'import'];

        if (!options?.formType) throw new Error('No form type set');

        listTemplates(country as any, type as any, options?.formType).then(() => {
            if (isEditing) return;

            onCreateTemplateListing?.({
                country,
                internalType: type,
                formType: options.formType,
            } as TemplateDashboardState);
            return;
        });
        openSuccessModal();
    };

    const handleChangeForm = (form: 'master' | 'product') => {
        setActiveForm(form);
        setForm?.(form);
    };

    const openSuccessModal = () =>
        Modal.success({
            content: `Template ${editedTemplateName ?? options?.name} has been successfully created!`,
            closable: true,
            onOk: () => closeModal?.(),
            onCancel: () => closeModal?.(),
        });

    const saveChangesModal = () =>
        Modal.confirm({
            content: `Do you want to save Template ${editedTemplateName ?? options?.name} ?`,
            closable: true,
            cancelText: 'No',
            okText: 'Yes',
            onOk: () => handleOkay(),
            onCancel: () => closeModal?.(),
        });

    return (
        <SModal
            visible={open}
            width={'100rem'}
            onCancel={() => (saveChanges ? saveChangesModal() : closeModal?.())}
            onOk={() => handleOkay()}
            footer={false}
            closable={false}
            destroyOnClose
            maskClosable={false}
        >
            <FormikProvider value={templateFormik}>
                <TemplateContext.Provider value={{ templateFormik, template: true, form, options, isViewOnly }}>
                    <div style={{ position: 'relative' }}>
                        <TemplateHeader
                            creating={creating}
                            onSave={handleOkay}
                            onClose={saveChanges ? saveChangesModal : closeModal}
                            onChangeActiveForm={handleChangeForm}
                            active={activeForm}
                            setEditedTemplateName={(editedTemplateName: string | null) =>
                                setEditedTemplateName(editedTemplateName)
                            }
                        />
                        {activeForm === 'master' && <MasterDetails declaration={declaration} />}
                        {activeForm === 'product' && <DeclarationProductView declaration={declaration} />}
                    </div>
                </TemplateContext.Provider>
            </FormikProvider>
            <ValidationErrorContainer isOpen={errorsModalOpen} close={closeErrorsModal} />
        </SModal>
    );
};

export default TemplateModal;
