import { Col, Dropdown, Menu, Row, notification, message } from 'antd';
import Button from 'components/ui/base/button/Button';
import Drawer from 'components/ui/base/drawer/Drawer';
import Notification from 'components/ui/base/notification/Notification';
import { H5 } from 'components/ui/base/typography';
import BulkUpload, { BulkUploadUploadFunc, FileType } from 'components/ui/composed/bulkUpload/BulkUpload';
import { TemplateResponse } from 'components/ui/composed/template/TemplateContext';
import useCustomers from 'hooks/useCustomers';
import useDeclarations from 'hooks/useDeclarations';
import useJobs from 'hooks/useJobs';
import debounce from 'lodash.debounce';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Customer } from 'store/customers/customer';
import { downloadIrelandH1ImportDeclarationSubmissionDetails } from 'store/declarations/client';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { DeclarationJobInfo, JobResponse } from 'store/jobs/job';
import CreateNewCustomer from 'views/customers/components/CreateNewCustomer';
import { getMessageTypeByDeclaration } from 'views/declarations/utils/declaration-utils';
import {
    CloseIcon,
    ColButtonFillForm,
    Container,
    CustomRow,
    DownIcon,
    FileIcon,
    InvoiceButton,
    LeftRow,
    ReverseCol,
    SectionTitle,
    TitleRow,
} from './components/InvoiceUpload.styles';
import SelectCustomer from './components/SelectCustomer';
import SelectFormType from './components/SelectFormType';
import SelectJob from './components/SelectJob';
import { createDeclarations, createOrDuplicateDeclarations, getDeclarationNameLabels } from './utils';
import { getTemplates } from 'store/template/client';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import axiosClient from '../../config/axios';
import config from 'config/config';
import { SuccessResponse } from 'core/http/response';
import SelectInvoiceFile from './components/SelectInvoiceFile';
import { uploadFile as upload } from '../declarations/sections/customer-documents/components/UploadUtils';
import { confirmUploadFile as confirmUpload, documentsPreUpload as preUpload } from '../../store/documents/client';
import { RcFile } from 'antd/lib/upload';
import { createDocumentAis as attachInvoiceToDeclaration } from '../../store/file-upload/client';
import {
    AESMessageTypes,
    AISMessageTypes,
    CDSExportMessageTypes,
    CDSImportMessageTypes,
    MessageTypes,
} from '../../store/declarations/enums/common/declaration-types';
import SelectDeclaration from './components/SelectDeclaration';
import { ListPayload } from 'core/http/response';
import { listJobsWithDeclarations } from 'store/jobs/client';
import { PresignedUrlResponse } from 'store/documents/document';

const BrokerInvoiceUpload: FC = () => {
    const { t } = useTranslation('customs_declarations');
    const { hideGlobalOverlay, showGlobalOverlay } = useGlobalOverlay();
    const { country, type } = useParams<{
        country: DeclarationCountry;
        type: DeclarationInternalType;
    }>();
    const navigate = useNavigate();
    const location = useLocation();
    const { customers, listCustomers } = useCustomers();
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>(undefined);
    const [showCreateCustomer, setShowCreateCustomer] = useState(false);
    const [myDeclaration, setMyDeclaration] = useState<Declaration | undefined>(undefined);
    const {
        createIrelandImportDeclaration,
        createIrelandH1ImportDeclarationWithFile,
        createIrelandH7ImportDeclaration,
        createIrelandExportDeclaration,
        createUkExportDeclaration,
        createEntrySummaryDeclaration,
        createElectronicTransportDocument,
        createTemporaryStorageDeclaration,
        createArrivalAtExitDeclaration,
        createUkImportNewDeclaration,
        duplicateDeclaration,
        getDeclaration,
        createGvmsRecord,
        createPbnRecord,
        createNctsDeclaration,
    } = useDeclarations();

    const [jobs, setJobs] = useState<DeclarationJobInfo[]>();
    const [selectedDeclaration, setSelectedDeclaration] = useState<{ id: string; mrn: string } | undefined>(undefined);
    const [selectedFormType, setSelectedFormType] = useState<MessageTypes | undefined>(undefined);
    const [fillForm, setFillForm] = useState(false);
    const [duplicateDeclarationId, setDuplicateDeclarationId] = useState<string>();
    const [mirrorDeclarationId, setMirrorDeclarationId] = useState<string | null>(null);
    const [uploadedInvoiceFile, setUploadedInvoiceFile] = useState<RcFile | null>(null);
    const [creatingDeclaration, setCreatingDeclaration] = useState<boolean>(false);
    const [s3Link, setS3Link] = useState<string | undefined>(undefined);

    const locationState = useMemo(
        () =>
            location.state as {
                declaration?: Declaration;
                job?: JobResponse;
                mirroring?: Boolean;
                jobId?: string;
            } | null,
        [location.state]
    );

    const [selectedJobId, setSelectedJobId] = useState(locationState?.jobId);
    const { createJob, listJobs, editJob } = useJobs();

    const getErrorMessage = (error: any) => error.response?.data || error.message;

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const jobsData: ListPayload<DeclarationJobInfo> = await listJobsWithDeclarations({
                    page: 0,
                    size: 200,
                });
                if (jobsData && jobsData.list) {
                    setJobs(jobsData.list);
                }
            } catch (error) {
                getErrorMessage(error);
            }
        };

        fetchJobs();
    }, []);

    const [hSubmissionDetailsFile, setHSubmissionDetailsFile] = useState<File>();

    const isEnsDeclaration = useMemo(
        () => country === DeclarationCountry.IRELAND && type === DeclarationInternalType.ENS,
        [country, type]
    );

    const isGvmsRecord = country === DeclarationCountry.UK && type === DeclarationInternalType.GVMS;

    const isPbnRecord = country === DeclarationCountry.IRELAND && type === DeclarationInternalType.PBN;

    const isTsdDeclaration = useMemo(
        () => country === DeclarationCountry.IRELAND && type === DeclarationInternalType.TEMPORARY,
        [country, type]
    );

    const isEtdDeclaration = useMemo(
        () => country === DeclarationCountry.IRELAND && type === DeclarationInternalType.ETD,
        [country, type]
    );

    const isArrivalDeclaration = useMemo(
        () => country === DeclarationCountry.IRELAND && type === DeclarationInternalType.ARRIVAL,
        [country, type]
    );

    const disabledFillForm = useMemo(() => {
        if (creatingDeclaration) return true;

        if (!!selectedJobId && !!selectedCustomer) {
            if (isEnsDeclaration || isPbnRecord || isEtdDeclaration) {
                return false;
            }

            if (isArrivalDeclaration) return !selectedDeclaration;

            return !selectedFormType;
        }

        return true;
    }, [
        creatingDeclaration,
        selectedJobId,
        selectedCustomer,
        isEnsDeclaration,
        isPbnRecord,
        isEtdDeclaration,
        isArrivalDeclaration,
        selectedDeclaration,
        selectedFormType,
    ]);

    const [formTypeDisabled, setFormTypeDisabled] = useState(false);

    const selectionsDisabled = useMemo(
        () => !!duplicateDeclarationId || !!myDeclaration || formTypeDisabled,
        [duplicateDeclarationId, formTypeDisabled, myDeclaration]
    );

    const hasCreatedDeclaration = useMemo(() => !!myDeclaration, [myDeclaration]);

    const isH1FormTypeSelected = useMemo(
        () => country === DeclarationCountry.IRELAND && selectedFormType === AISMessageTypes.H1,
        [selectedFormType, country]
    );

    const isH7FormTypeSelected = useMemo(
        () => country === DeclarationCountry.IRELAND && selectedFormType === AISMessageTypes.H7,
        [selectedFormType, country]
    );

    const isNCTS = country === DeclarationCountry.IRELAND && type === DeclarationInternalType.NCTS;

    const invoice = {
        hidden: () => {
            const isIrelandImport = country === DeclarationCountry.IRELAND && type === DeclarationInternalType.IMPORT;
            return !isIrelandImport && !isNCTS;
        },
        disabled: () => {
            const oneOfAllowedTypes = isH1FormTypeSelected || isH7FormTypeSelected || isNCTS;
            return disabledFillForm || !oneOfAllowedTypes;
        },
    };

    const hasTemplatesImplementation = (
        country: DeclarationCountry | undefined,
        type: DeclarationInternalType | undefined,
        selectedFormType: any
    ) => {
        const hasAisTemplates =
            type === DeclarationInternalType.IMPORT &&
            AISMessageTypes[selectedFormType as keyof typeof AISMessageTypes];
        const hasAesTemplates =
            type === DeclarationInternalType.EXPORT &&
            AESMessageTypes[selectedFormType as keyof typeof AESMessageTypes];
        const hasEnsTemplate = type === DeclarationInternalType.ENS;
        const hasCdsTemplate =
            country === DeclarationCountry.UK &&
            (type === DeclarationInternalType.EXPORT || type === DeclarationInternalType.IMPORT) &&
            (CDSExportMessageTypes[selectedFormType as keyof typeof CDSExportMessageTypes] ||
                CDSImportMessageTypes[selectedFormType as keyof typeof CDSImportMessageTypes]);

        return (
            (country === DeclarationCountry.IRELAND && (hasAisTemplates || hasEnsTemplate || hasAesTemplates)) ||
            hasCdsTemplate
        );
    };

    const [templates, setTemplates] = useState<TemplateResponse[] | undefined>(undefined);
    const [template, setTemplate] = useState<TemplateResponse | undefined>(undefined);
    useEffect(() => {
        if (!country || !type || !(selectedFormType || isEnsDeclaration)) {
            setTemplates(undefined);
            return;
        }

        if (!hasTemplatesImplementation(country, type, selectedFormType)) {
            setTemplates(undefined);
            return;
        }

        showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Loading template data...' } });
        getTemplates(country!, type!.toLowerCase() as 'import' | 'export', selectedFormType as MessageTypes, {
            size: 9999,
        })
            ?.then((payload) => setTemplates(payload.list))
            .finally(() => {
                hideGlobalOverlay();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFormType]);

    const handleTemplateSelect = (templateId: string | undefined) => {
        if (templateId) {
            setTemplate(templates?.find(({ id }) => id === templateId));
        }
    };

    const templateInputDisabled = useMemo(() => {
        const previousInformation = locationState;
        return (
            !!previousInformation?.declaration?.templateId ||
            !selectedCustomer ||
            !selectedJobId ||
            !(selectedFormType || isEnsDeclaration) ||
            !templates?.length
        );
    }, [selectedFormType, locationState, selectedCustomer, selectedJobId, templates?.length, isEnsDeclaration]);

    useEffect(() => {
        if (!customers.list.length) listCustomers({ size: 200 });
        if (
            !jobs?.length ||
            !customers.list.length ||
            !locationState?.declaration ||
            !locationState?.declaration?.customerId
        ) {
            return;
        }

        handleSelectCustomer(locationState?.declaration.customerId);

        if (locationState?.mirroring) {
            setMirrorDeclarationId(locationState?.declaration.id ?? null);
            // ! Temporary until other mirroring options are available
            // TODO Mirroring - Remove when necessary
            setFormTypeDisabled(true);
            setSelectedFormType(AISMessageTypes.H1);
        } else {
            handleTemplateSelect(locationState.declaration.templateId);
            const name = getMessageTypeByDeclaration(locationState.declaration);
            getDeclarationNameLabels(country, type).forEach((a: any) => {
                if (a.key === name) return setSelectedFormType(a.key as any);
            });
            setDuplicateDeclarationId(locationState.declaration.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationState, jobs, customers, templates]);

    const handleSelectCustomer = useCallback(
        async (value: string) => {
            const element = customers.list.find((e) => e.id === value);
            if (element?.id && selectedJobId) {
                setSelectedCustomer(element);
                editJob(selectedJobId, { customerId: element?.id });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [customers, selectedJobId, editJob]
    );

    const handleSelectJob = useCallback(
        async (id: string) => {
            setSelectedJobId(id);

            if (selectedCustomer?.id) {
                editJob(id, { customerId: selectedCustomer.id });
            }
        },
        [country, editJob, navigate, selectedCustomer?.id, type]
    );

    const convertJobResponseToJobInfo = (job: JobResponse) => {
        const newJobInfo: DeclarationJobInfo = {
            declarant: '-',
            declarationType: '-',
            declarationsSize: 0,
            exporter: '-',
            importer: '-',
            jobId: job.id,
            jobReferenceId: job.referenceId,
        };
        return newJobInfo;
    };

    const createNewJob = async () => {
        try {
            const job: JobResponse = await createJob();
            const newJobInfo = convertJobResponseToJobInfo(job);
            if (jobs) {
                setJobs([newJobInfo, ...jobs]);
                setSelectedJobId(newJobInfo.jobId);
            }
        } catch (err) {
            console.error();
        }
    };

    const createDeclaration = async (
        country: DeclarationCountry,
        customerId: string,
        jobId: string,
        messageType?: MessageTypes,
        selectedDeclaration?: { id: string; mrn: string }
    ) => {
        return createDeclarations(
            country,
            customerId,
            jobId,
            type!, // FIXME remove assertion
            createIrelandH7ImportDeclaration,
            createIrelandImportDeclaration,
            createEntrySummaryDeclaration,
            createTemporaryStorageDeclaration,
            createIrelandExportDeclaration,
            createElectronicTransportDocument,
            createUkExportDeclaration,
            createUkImportNewDeclaration,
            createArrivalAtExitDeclaration,
            createGvmsRecord,
            createPbnRecord,
            createNctsDeclaration,
            messageType,
            template,
            selectedDeclaration
        );
    };

    useEffect(() => {
        if (myDeclaration && fillForm) {
            setFillForm(false);
            navigate(`/declarations/${myDeclaration.id}`, { state: { comingFromCreateDecPage: true } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fillForm, myDeclaration]);

    const handleFillFormClick = async () => {
        if (myDeclaration) return;

        setCreatingDeclaration(true);

        try {
            if (isH1FormTypeSelected && hSubmissionDetailsFile) {
                await handleCreateIrelandH1ImportDeclarationWithFile();
            } else {
                await handleCreateDeclaration().then(handleCreateInvoice);
            }
        } catch (error) {
        } finally {
            setCreatingDeclaration(false);
        }

        setFillForm(true);
    };

    const handleCreateInvoice = async (createdDeclaration: Partial<Declaration> | undefined) => {
        if (!uploadedInvoiceFile || !createdDeclaration?.id || !createdDeclaration?.customerId) return;

        const preAndConfirmUploadPayload = {
            fileSize: uploadedInvoiceFile.size,
            customerId: createdDeclaration.customerId,
            declarationId: createdDeclaration.id,
            fileName: uploadedInvoiceFile.name,
        };

        try {
            const { preSignedUrl } = await preUpload(preAndConfirmUploadPayload);

            const match = preSignedUrl.match(/\/([^/?]+)\?/);
            const uuid = match?.[1];

            await upload(uploadedInvoiceFile, preSignedUrl);
            const { payload: confirmUploadPayload } = (await confirmUpload({
                s3id: uuid,
                ...preAndConfirmUploadPayload,
            })) as unknown as any;

            await attachInvoiceToDeclaration(
                {
                    fileId: confirmUploadPayload.id,
                    fileReference: confirmUploadPayload.link,
                    fileName: confirmUploadPayload.filename,
                    fileInfo: {
                        documentType: confirmUploadPayload.contentType,
                    },
                    invoice: true,
                },
                confirmUploadPayload.declarationId
            );
            await getDeclaration(createdDeclaration.id);
        } catch (e) {
            message.error(
                'Invoice uploading failed! Please create a new declaration in order to attach an invoice to it.',
                5
            );
        }
    };

    const handleCreateIrelandH1ImportDeclarationWithFile = async () => {
        const _jobId = selectedJobId;
        const _customerId = selectedCustomer?.id;
        if (!_jobId || !_customerId || !hSubmissionDetailsFile) {
            Notification({
                type: 'error',
                messageTitle: 'Error creating declaration',
                description: 'Please select Job, Customer, and a Submission Details File',
            });

            return;
        }

        const response = await createIrelandH1ImportDeclarationWithFile(hSubmissionDetailsFile, _jobId, _customerId);

        if (response) {
            Notification({
                type: 'success',
                messageTitle: 'Declaration created',
                description: 'Successfully created declaration',
            });

            setMyDeclaration(response);
            return Promise.resolve(response);
        } else {
            Notification({
                type: 'error',
                messageTitle: 'Error creating declaration',
                description: 'An error occurred while creating a declaration',
            });
            return Promise.reject('Error creating declaration');
        }
    };

    const addNewCustomer = (newCustomer: Customer) => {
        if (selectedJobId && newCustomer.id) {
            editJob(selectedJobId, { customerId: newCustomer.id });
        }
        setSelectedCustomer(newCustomer);
        setShowCreateCustomer(false);
    };

    const mirrorDeclaration = async () => {
        if (!mirrorDeclarationId) throw new Error('No declaration to be mirrored');

        /**
         * ! Currently working only for B1 -> H1.
         * * When other declaration types are needed,
         * * this request will have to be updated
         */
        // TODO Mirroring - Handle for other declaration types when necessary
        try {
            const response = await axiosClient.post<SuccessResponse<Declaration>>(
                `${config.declarationsUrl}/declarations/${mirrorDeclarationId}/mirror`
            );
            setMyDeclaration(response.data.payload);

            return Promise.resolve(response.data.payload);
        } catch (error) {
            notification.error({ message: 'An error occurred while trying to mirror a declaration!' });
            console.error(error);
            return Promise.reject('An error occurred while trying to mirror a declaration!');
        }
    };

    const handleCreateDeclaration = async () => {
        if (myDeclaration) return;

        const jId = selectedJobId;
        const customerId = selectedCustomer?.id;

        if (locationState?.mirroring) {
            mirrorDeclaration();
            return;
        }

        return await createOrDuplicateDeclarations(
            duplicateDeclaration,
            createDeclaration,
            jId!, // FIXME remove assertion
            setMyDeclaration,
            country!, // FIXME remove assertion
            customerId,
            duplicateDeclarationId,
            selectedFormType,
            selectedDeclaration
        );
    };

    const querySearchJob = async (query: string) => {
        if (query) {
            const params = { query };
            await listJobs(params);
        } else {
            await listJobs({ size: 200 });
        }
    };

    const debouncedSearchJob = debounce((query: string) => querySearchJob(query), 500);
    const querySearchCustomer = async (query: string) => {
        if (query) {
            const params = { query };
            await listCustomers(params);
        } else {
            await listCustomers({ size: 200 });
        }
    };

    const debouncedSearchCustomers = debounce((query: string) => querySearchCustomer(query), 500);

    const checkConflicts = async (s3Key: string, templateId: string) => {
        if (!templateId) return;

        try {
            const response = await axiosClient.get(`${config.declarationsUrl}/declarations/bulk/upload/conflicts`, {
                params: {
                    s3Link: s3Key,
                    templateId: templateId,
                },
            });

            if (response.data.payload.list.length) {
                Notification({
                    type: 'error',
                    messageTitle: t('bulk-upload.conflict-check-error-message.title'),
                    description: t('bulk-upload.conflict-check-error-message.description'),
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleUpload: BulkUploadUploadFunc = async (options: Parameters<BulkUploadUploadFunc>[0]) => {
        const file = options.file as File;

        const preSignUrlPayload = {
            fileSize: file.size,
            fileName: file.name,
        };

        try {
            const { preSignedUrl }: PresignedUrlResponse = await axiosClient
                .get('/cms/shared/files/upload', { params: preSignUrlPayload })
                .then((res) => res.data.payload);

            await upload(file, preSignedUrl);

            const match = preSignedUrl.match(/\/([^/?]+)\?/);
            const uuid = match?.[1];
            const responseResult = await axiosClient.post('/cms/shared/files', {
                s3key: uuid,
                ...preSignUrlPayload,
            });

            const newLink = responseResult.data.payload.link;
            if (newLink && template) {
                checkConflicts(newLink, template.id);
            }
            setS3Link(newLink);
            setHSubmissionDetailsFile(file as File);
            notification.success({ message: 'Files uploaded successfully' });
        } catch (error) {
            console.error(error);
        }

        return true;
    };

    const handleUploadSubmit = async () => {
        try {
            let requestUrl = `${config.declarationsUrl}/declarations/bulk/upload?s3Link=${s3Link}&customerId=${selectedCustomer?.id}&jobId=${selectedJobId}`;
            const requestBody = {};
            if (template?.id) {
                requestUrl += `&templateId=${template.id}`;
            }
            await axiosClient.post(requestUrl, requestBody);
            navigate(`/customs-declarations/${country}/${type}`);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDownload = () => {
        return downloadIrelandH1ImportDeclarationSubmissionDetails();
    };

    const handleDelete = () => {
        setHSubmissionDetailsFile(undefined);
    };

    useEffect(() => {
        if (locationState?.job && jobs && !jobs.some((job) => locationState.job?.id === job.jobId)) {
            const locationStateJob = convertJobResponseToJobInfo(locationState.job);
            setJobs([locationStateJob, ...jobs]);
        }
    }, [locationState?.job, jobs]);

    return (
        <div style={{ padding: '0 50px' }}>
            <TitleRow>
                <Col span={18}>
                    <H5>{t(`create${country}Declaration${type}`)}</H5>
                </Col>
                <ColButtonFillForm span={6}>
                    {hSubmissionDetailsFile ? (
                        <Button size="large" type="primary" onClick={handleUploadSubmit}>
                            {t('CreateBulkDeclarations')}
                        </Button>
                    ) : (
                        <Button size="large" disabled={disabledFillForm} type="primary" onClick={handleFillFormClick}>
                            {t('fillForm')}
                        </Button>
                    )}
                </ColButtonFillForm>
            </TitleRow>
            <Container>
                <LeftRow gutter={32}>
                    <Col span={14}>
                        <SelectJob
                            disabled={hasCreatedDeclaration}
                            selectedJobId={selectedJobId}
                            onSelect={handleSelectJob}
                            jobs={jobs}
                            createNewJob={createNewJob}
                            onCancel={() => {
                                setSelectedJobId(undefined);
                                setSelectedCustomer(undefined);
                            }}
                            onClear={() => {
                                setSelectedJobId(undefined);
                            }}
                            search={(e) => debouncedSearchJob(e)}
                        />

                        <SelectCustomer
                            disabled={hasCreatedDeclaration || !selectedJobId}
                            customer={selectedCustomer}
                            customers={customers?.list}
                            setShowCreateCustomer={setShowCreateCustomer}
                            handleSelectCustomer={handleSelectCustomer}
                            setCustomer={setSelectedCustomer}
                            search={(e) => debouncedSearchCustomers(e)}
                        />

                        {isArrivalDeclaration && (
                            <SelectDeclaration
                                disabled={!selectedCustomer || !selectedJobId}
                                selectedDeclarationMrn={selectedDeclaration?.mrn}
                                setSelectedDeclaration={setSelectedDeclaration}
                            />
                        )}

                        {!isPbnRecord && !isEnsDeclaration && !isEtdDeclaration && !isArrivalDeclaration && (
                            <SelectFormType
                                setFormType={setSelectedFormType}
                                disableFormType={!selectedCustomer || !selectedJobId || selectionsDisabled}
                                selectedFormTypeLabel={
                                    (getDeclarationNameLabels(country, type) as any[]).find(
                                        (label: any) => label.key === selectedFormType
                                    )?.value
                                }
                            />
                        )}

                        {!isTsdDeclaration && !isGvmsRecord && !isEtdDeclaration && !isArrivalDeclaration && (
                            <CustomRow>
                                <Col span={16}>
                                    <SectionTitle
                                        className={`${(templateInputDisabled || selectionsDisabled) && 'disabled'}`}
                                    >
                                        {t('selectTemplate')}
                                    </SectionTitle>
                                </Col>
                                <ReverseCol span={8}>
                                    <Dropdown
                                        disabled={templateInputDisabled || selectionsDisabled}
                                        overlayStyle={{
                                            overflow: 'auto',
                                            height: 250,
                                            filter: `drop-shadow(0 5px 10px rgba(9, 0, 255, 0.09))`,
                                        }}
                                        overlay={
                                            <Menu onClick={(item) => handleTemplateSelect(item.key)}>
                                                {templates?.map((template) => (
                                                    <Menu.Item key={template.id}>{template.templateName}</Menu.Item>
                                                ))}
                                            </Menu>
                                        }
                                    >
                                        <InvoiceButton size="large">
                                            <DownIcon /> {t('selectTemplate')}
                                        </InvoiceButton>
                                    </Dropdown>
                                </ReverseCol>
                                {template && (
                                    <Row align="middle">
                                        <CloseIcon
                                            className={`${templateInputDisabled && 'disabled'}`}
                                            onClick={() => {
                                                if (!templateInputDisabled) {
                                                    setTemplate(undefined);
                                                }
                                            }}
                                            disabled={templateInputDisabled}
                                        />
                                        <FileIcon /> {template.templateName}
                                    </Row>
                                )}
                            </CustomRow>
                        )}

                        {!invoice.hidden() && (
                            <SelectInvoiceFile
                                disabled={invoice.disabled()}
                                onUploadedInvoiceFile={setUploadedInvoiceFile}
                                uploadedInvoiceFile={uploadedInvoiceFile}
                            />
                        )}

                        {country === DeclarationCountry.IRELAND && type === DeclarationInternalType.IMPORT && (
                            <BulkUpload
                                showFile
                                onDownload={handleDownload}
                                onUpload={handleUpload}
                                onDelete={handleDelete}
                                fileType={[FileType.XLS, FileType.XLSX, FileType.CSV]}
                                disabled={!isH1FormTypeSelected && !isH7FormTypeSelected}
                            />
                        )}
                    </Col>
                </LeftRow>
                <Drawer
                    title="Add New Customer"
                    width="627"
                    visible={showCreateCustomer}
                    onClose={() => setShowCreateCustomer((oldState) => !oldState)}
                >
                    <CreateNewCustomer
                        refreshCustomers={listCustomers}
                        closeModal={() => setShowCreateCustomer(false)}
                        handleOk={addNewCustomer}
                    />
                </Drawer>
            </Container>
        </div>
    );
};
export default BrokerInvoiceUpload;
