import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { FormikProps, FormikProvider, useFormik, useFormikContext } from 'formik';
import useDeclarations from 'hooks/useDeclarations';
import { useEffect, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { useDeclarationContext } from 'utils/DeclarationContext';
import IrelandEnsForm from './ireland/ens/IrelandEnsForm';
import IrelandExportForm from './ireland/export/IrelandExportForm';
import IrelandH1Form from './ireland/import/h1/IrelandH1Form';
import IrelandH7Form from './ireland/import/h7/IrelandH7Form';
import IrelandTsdForm from './ireland/tsd/IrelandTsdForm';
import UkForm from './uk/UkForm';
import UkGvmsForm from './uk/gvms/UkGvmsForm';
import PbnForm from './ireland/pbn/PbnForm';
import IrelandNctsForm from './ireland/ncts/IrelandNctsForm';
import IrelandEtdForm from './ireland/etd/IrelandEtdForm';

interface Props {
    declaration?: Declaration | null;
}

const MasterDetails = ({ declaration: declarationProp }: Props) => {
    const context = useMasterDetails();
    const contextFormik = useFormikContext();

    const { template } = useTemplateContext();
    const { setForm } = useDeclarationContext();
    useEffect(() => {
        if (template) return;
        setForm?.('master');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mockFormik = useFormik({
        initialValues: {},
        onSubmit: () => {},
    });

    const { declaration: _declaration } = useDeclarations();
    const declaration = useMemo(() => {
        return declarationProp ?? _declaration;
    }, [declarationProp, _declaration]);

    const formik = useMemo(
        () => contextFormik || context?.formik || mockFormik,
        [context?.formik, contextFormik, mockFormik]
    );

    const container = useMemo(() => {
        if (declaration) {
            switch (declaration.declarationExternalEntity) {
                case DeclarationExternalEntity.REVENUE: {
                    if (DeclarationInternalType.IMPORT === declaration.declarationInternalType) {
                        if (declaration.irelandImportDeclaration) {
                            return (
                                <IrelandH1Form
                                    formik={formik}
                                    toggleHelp={context?.toggleHelp}
                                    viewOnly={context?.viewOnly}
                                    selectCard={context?.selectCard}
                                />
                            );
                        } else {
                            return (
                                <IrelandH7Form
                                    formik={formik}
                                    toggleHelp={context?.toggleHelp}
                                    viewOnly={context?.viewOnly}
                                />
                            );
                        }
                    } else if (
                        DeclarationInternalType.EXPORT === declaration.declarationInternalType ||
                        DeclarationInternalType.ARRIVAL === declaration.declarationInternalType
                    ) {
                        return <IrelandExportForm formik={formik} />;
                    } else if (DeclarationInternalType.ENS === declaration.declarationInternalType) {
                        return (
                            <IrelandEnsForm
                                formik={formik}
                                toggleHelp={context?.toggleHelp}
                                viewOnly={context?.viewOnly}
                            />
                        );
                    } else if (DeclarationInternalType.TEMPORARY === declaration.declarationInternalType) {
                        return <IrelandTsdForm formik={formik} />;
                    } else if (DeclarationInternalType.PBN === declaration.declarationInternalType) {
                        return <PbnForm formik={formik} />;
                    } else if (DeclarationInternalType.NCTS === declaration.declarationInternalType) {
                        return <IrelandNctsForm formik={formik} />;
                    } else if (DeclarationInternalType.ETD === declaration.declarationInternalType) {
                        return <IrelandEtdForm formik={formik} />;
                    } else return <></>;
                }
                case DeclarationExternalEntity.CDS:
                    return <UkForm formik={formik} toggleHelp={context?.toggleHelp} viewOnly={context?.viewOnly} />;

                case DeclarationExternalEntity.GVMS:
                    return <UkGvmsForm formik={formik} />;

                default:
                    <></>;
            }
        }
        return <></>;
    }, [declaration, formik, context?.toggleHelp, context?.viewOnly, context?.selectCard]);

    return <FormikProvider value={formik}>{container}</FormikProvider>;
};
export default MasterDetails;

export function useMasterDetails() {
    return useOutletContext<
        | {
              formik: FormikProps<any>;
              toggleHelp?: (refNumber: string | number) => void;
              viewOnly?: boolean;
              selectCard?: (id: string) => void;
          }
        | undefined
    >();
}
