import { PaginatedParams } from 'core/http/pagination';
import { GoodsShipmentItem as EtdGoodsShipmentItem } from 'store/declarations/ireland/electronic-transport-document';
import { EnsGoodsShipmentItem } from 'store/declarations/ireland/entry-summary-declaration';
import { IrelandExportItem } from 'store/declarations/ireland/export-declaration';
import { GoodsShipmentItem as H7GoodsShipmentItem } from 'store/declarations/ireland/h7-import-declaration';
import { GoodsShipmentItem as H1GoodsShipmentItem } from 'store/declarations/ireland/import-declaration';
import {
    createCdsProduct,
    createIrelandEnsProduct,
    createIrelandEtdProduct,
    createIrelandExportProduct,
    createIrelandH7ImportProduct,
    createIrelandImportProduct,
    createIrelandTsdProduct,
    deleteIrelandEnsProduct,
    deleteIrelandExportProduct,
    deleteIrelandH7ImportProduct,
    deleteIrelandImportProduct,
    deleteIrelandTsdProduct,
    deleteCdsProduct,
    getIrelandEnsProduct,
    getIrelandEtdProduct,
    getIrelandExportProduct,
    getIrelandH1Product,
    getIrelandH7Product,
    getIrelandTsdProduct,
    getCdsProduct,
    listIrelandEnsProducts,
    listIrelandEtdProductsOfDeclaration,
    listIrelandExportProduct,
    listIrelandH1ImportProduct,
    listIrelandH7ImportProduct,
    listIrelandTsdProductsOfDeclaration,
    listCdsProducts,
    updateCdsProduct,
    updateIrelandEnsProduct,
    updateIrelandEtdProduct,
    updateIrelandExportProduct,
    updateIrelandH7ImportProduct,
    updateIrelandImportProduct,
    updateIrelandTsdProduct,
    listArrivalAtExitProduct,
    ArrivalAtExitProduct,
    createArrivalAtExitProduct,
    updateArrivalAtExitProduct,
    getArrivalAtExitProduct,
    deleteArrivalAtExitProduct,
    createIrelandNctsProduct,
    updateIrelandNctsProduct,
    getIrelandNctsProduct,
    deleteIrelandNctsProduct,
    listIrelandNctsProductsOfDeclaration,
    deleteIrelandEtdProduct,
} from './client';
import { CdsGovernmentAgencyGoodsItem } from 'store/declarations/uk/cds-declaration';

export const doCreateIrelandImportProduct: Function =
    (product: H1GoodsShipmentItem, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await createIrelandImportProduct(product, declarationId);
            dispatch({
                type: 'CREATE_IRELAND_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateIrelandImportProduct: Function =
    (product: H1GoodsShipmentItem, declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await updateIrelandImportProduct(product, declarationId, productId);
            dispatch({
                type: 'UPDATE_IRELAND_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteIrelandImportProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DELETE_IRELAND_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await deleteIrelandImportProduct(productId, declarationId);
            dispatch({
                type: 'DELETE_IRELAND_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DELETE_IRELAND_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doCreateIrelandH7ImportProduct: Function =
    (product: H7GoodsShipmentItem, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_H7_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await createIrelandH7ImportProduct(product, declarationId);
            dispatch({
                type: 'CREATE_IRELAND_H7_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_H7_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateIrelandH7ImportProduct: Function =
    (product: H7GoodsShipmentItem, declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_H7_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await updateIrelandH7ImportProduct(product, declarationId, productId);
            dispatch({
                type: 'UPDATE_IRELAND_H7_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_H7_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteIrelandH7ImportProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DELETE_IRELAND_H7_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await deleteIrelandH7ImportProduct(productId, declarationId);
            dispatch({
                type: 'DELETE_IRELAND_H7_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DELETE_IRELAND_H7_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doCreateIrelandExportProduct: Function =
    (product: IrelandExportItem, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await createIrelandExportProduct(product, declarationId);
            dispatch({
                type: 'CREATE_IRELAND_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doCreateArrivalAtExitProduct: Function =
    (product: ArrivalAtExitProduct, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await createArrivalAtExitProduct(product, declarationId);
            dispatch({
                type: 'CREATE_IRELAND_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateIrelandExportProduct: Function =
    (product: IrelandExportItem, declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await updateIrelandExportProduct(product, declarationId, productId);
            dispatch({
                type: 'UPDATE_IRELAND_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateArrivalAtExitProduct: Function =
    (product: ArrivalAtExitProduct, declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await updateArrivalAtExitProduct(product, declarationId, productId);
            dispatch({
                type: 'UPDATE_IRELAND_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteIrelandExportProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DELETE_IRELAND_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await deleteIrelandExportProduct(productId, declarationId);
            dispatch({
                type: 'DELETE_IRELAND_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DELETE_IRELAND_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteArrivalAtExitProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DELETE_IRELAND_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await deleteArrivalAtExitProduct(productId, declarationId);
            dispatch({
                type: 'DELETE_IRELAND_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DELETE_IRELAND_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doCreateIrelandEnsProduct: Function =
    (product: EnsGoodsShipmentItem, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_ENS_PRODUCT_REQUEST' });

        try {
            const payload = await createIrelandEnsProduct(product, declarationId);
            dispatch({
                type: 'CREATE_IRELAND_ENS_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_ENS_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateIrelandEnsProduct: Function =
    (product: EnsGoodsShipmentItem, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_ENS_PRODUCT_REQUEST' });

        try {
            const payload = await updateIrelandEnsProduct(product, declarationId);
            dispatch({
                type: 'UPDATE_IRELAND_ENS_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_ENS_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteIrelandEnsProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DELETE_IRELAND_ENS_PRODUCT_REQUEST' });

        try {
            const payload = await deleteIrelandEnsProduct(productId, declarationId);
            dispatch({
                type: 'DELETE_IRELAND_ENS_PRODUCT_SUCCESS',
                payload,
            });
            return payload || true;
        } catch (e: any) {
            console.log('here');
            dispatch({ type: 'DELETE_IRELAND_ENS_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doGetIrelandEnsProduct: Function =
    (declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_IRELAND_ENS_PRODUCT_REQUEST' });

        try {
            const payload = await getIrelandEnsProduct(declarationId, productId);
            dispatch({
                type: 'GET_IRELAND_ENS_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_IRELAND_ENS_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doListIrelandEnsProducts: Function =
    (declarationId: string, params?: Partial<PaginatedParams>) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_IRELAND_ENS_PRODUCTS_REQUEST' });

        try {
            const payload = await listIrelandEnsProducts(declarationId, params);
            dispatch({
                type: 'LIST_IRELAND_ENS_PRODUCTS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'LIST_IRELAND_ENS_PRODUCTS_ERROR', error: e?.response?.data });
        }
    };

export const doListIrelandH1Products: Function =
    (declarationId: string, params?: Partial<PaginatedParams>) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_IRELAND_H1_PRODUCTS_REQUEST' });

        try {
            const payload = await listIrelandH1ImportProduct(declarationId, params);
            dispatch({
                type: 'LIST_IRELAND_H1_PRODUCTS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'LIST_IRELAND_H1_PRODUCTS_ERROR', error: e?.response?.data });
        }
    };

export const doListIrelandExportProducts: Function =
    (declarationId: string, params?: Partial<PaginatedParams>) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_IRELAND_EXPORT_PRODUCTS_REQUEST' });

        try {
            const payload = await listIrelandExportProduct(declarationId, params);
            dispatch({
                type: 'LIST_IRELAND_EXPORT_PRODUCTS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'LIST_IRELAND_EXPORT_PRODUCTS_ERROR', error: e?.response?.data });
        }
    };

export const doListArrivalAtExitProducts: Function =
    (declarationId: string, params?: Partial<PaginatedParams>) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_IRELAND_EXPORT_PRODUCTS_REQUEST' });

        try {
            const payload = await listArrivalAtExitProduct(declarationId, params);
            dispatch({
                type: 'LIST_IRELAND_EXPORT_PRODUCTS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'LIST_IRELAND_EXPORT_PRODUCTS_ERROR', error: e?.response?.data });
        }
    };

export const doGetIrelandH1Product: Function =
    (declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_IRELAND_H1_PRODUCT_REQUEST' });

        try {
            const payload = await getIrelandH1Product(declarationId, productId);
            dispatch({
                type: 'GET_IRELAND_H1_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_IRELAND_H1_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doGetCdsProduct: Function = (declarationId: string, productId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_UK_EXPORT_PRODUCT_REQUEST' });

    try {
        const payload = await getCdsProduct(declarationId, productId);
        dispatch({
            type: 'GET_UK_EXPORT_PRODUCT_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'GET_UK_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
    }
};

export const doListIrelandH7Products: Function =
    (declarationId: string, params?: Partial<PaginatedParams>) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_IRELAND_H7_PRODUCTS_REQUEST' });

        try {
            const payload = await listIrelandH7ImportProduct(declarationId, params);
            dispatch({
                type: 'LIST_IRELAND_H7_PRODUCTS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'LIST_IRELAND_H7_PRODUCTS_ERROR', error: e?.response?.data });
        }
    };

export const doListCdsProducts: Function =
    (declarationId: string, params?: Partial<PaginatedParams>) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_UK_EXPORT_PRODUCTS_REQUEST' });

        try {
            const payload = await listCdsProducts(declarationId, params);
            dispatch({
                type: 'LIST_UK_EXPORT_PRODUCTS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'LIST_UK_EXPORT_PRODUCTS_ERROR', error: e?.response?.data });
        }
    };

export const doGetIrelandH7Product: Function =
    (declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_IRELAND_H7_PRODUCT_REQUEST' });

        try {
            const payload = await getIrelandH7Product(declarationId, productId);
            dispatch({
                type: 'GET_IRELAND_H7_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_IRELAND_H7_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doGetIrelandExportProduct: Function =
    (declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_IRELAND_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await getIrelandExportProduct(declarationId, productId);
            dispatch({
                type: 'GET_IRELAND_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_IRELAND_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doGetArrivalAtExitProduct: Function =
    (declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_IRELAND_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await getArrivalAtExitProduct(declarationId, productId);
            dispatch({
                type: 'GET_IRELAND_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_IRELAND_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doClearError: Function = () => async (dispatch: Function) => {
    dispatch({ type: 'CLEAR_PRODUCTS_ERROR' });
};

export const doCreateIrelandTsdProduct: Function =
    (product: any, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await createIrelandTsdProduct(product, declarationId);
            dispatch({
                type: 'CREATE_IRELAND_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doCreateIrelandNctsProduct: Function =
    (product: any, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await createIrelandNctsProduct(product, declarationId);
            dispatch({
                type: 'CREATE_IRELAND_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteIrelandTsdProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DELETE_IRELAND_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await deleteIrelandTsdProduct(productId, declarationId);
            dispatch({
                type: 'DELETE_IRELAND_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DELETE_IRELAND_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteIrelandNctsProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DELETE_IRELAND_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await deleteIrelandNctsProduct(productId, declarationId);

            dispatch({
                type: 'DELETE_IRELAND_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DELETE_IRELAND_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteIrelandEtdProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DELETE_IRELAND_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await deleteIrelandEtdProduct(productId, declarationId);
            dispatch({
                type: 'DELETE_IRELAND_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DELETE_IRELAND_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateIrelandTsdProduct: Function =
    (product: any, declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await updateIrelandTsdProduct(product, declarationId, productId);
            dispatch({
                type: 'UPDATE_IRELAND_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateIrelandNctsProduct: Function =
    (product: any, declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_IMPORT_PRODUCT_REQUEST' });

        try {
            const payload = await updateIrelandNctsProduct(product, declarationId, productId);
            dispatch({
                type: 'UPDATE_IRELAND_IMPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_IMPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doListIrelandTsdProducts: Function =
    (declarationId: string, params?: Partial<PaginatedParams>) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_IRELAND_H1_PRODUCTS_REQUEST' });

        try {
            const payload = await listIrelandTsdProductsOfDeclaration(declarationId, params);
            dispatch({
                type: 'LIST_IRELAND_H1_PRODUCTS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'LIST_IRELAND_H1_PRODUCTS_ERROR', error: e?.response?.data });
        }

        dispatch({ type: 'LIST_IRELAND_TSD_PRODUCT_REQUEST' });
    };

export const doListIrelandNctsProducts: Function =
    (declarationId: string, params?: Partial<PaginatedParams>) => async (dispatch: Function) => {
        dispatch({ type: 'LIST_IRELAND_H1_PRODUCTS_REQUEST' });

        try {
            const payload = await listIrelandNctsProductsOfDeclaration(declarationId, params);
            dispatch({
                type: 'LIST_IRELAND_H1_PRODUCTS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'LIST_IRELAND_H1_PRODUCTS_ERROR', error: e?.response?.data });
        }

        dispatch({ type: 'LIST_IRELAND_TSD_PRODUCT_REQUEST' });
    };

export const doGetIrelandTsdProduct: Function =
    (declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_IRELAND_H1_PRODUCT_REQUEST' });

        try {
            const payload = await getIrelandTsdProduct(declarationId, productId);
            dispatch({
                type: 'GET_IRELAND_H1_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_IRELAND_H1_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doGetIrelandNctsProduct: Function =
    (declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_IRELAND_H1_PRODUCT_REQUEST' });

        try {
            const payload = await getIrelandNctsProduct(declarationId, productId);
            dispatch({
                type: 'GET_IRELAND_H1_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_IRELAND_H1_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doCreateIrelandEtdProduct: Function =
    (product: EtdGoodsShipmentItem, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_IRELAND_ETD_PRODUCT_REQUEST' });

        try {
            const payload = await createIrelandEtdProduct(product, declarationId);
            dispatch({
                type: 'CREATE_IRELAND_ETD_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_IRELAND_ETD_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateIrelandEtdProduct: Function =
    (product: EtdGoodsShipmentItem, declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_IRELAND_ETD_PRODUCT_REQUEST' });

        try {
            const payload = await updateIrelandEtdProduct(product, declarationId, productId);
            dispatch({
                type: 'UPDATE_IRELAND_ETD_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_IRELAND_ETD_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doListIrelandEtdProducts: Function = (declarationId: string) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_IRELAND_ETD_PRODUCT_REQUEST' });

    try {
        const payload = await listIrelandEtdProductsOfDeclaration(declarationId);
        dispatch({
            type: 'LIST_IRELAND_ETD_PRODUCT_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        dispatch({ type: 'LIST_IRELAND_ETD_PRODUCT_ERROR', error: e?.response?.data });
    }
};

export const doGetIrelandEtdProduct: Function =
    (declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'GET_IRELAND_ETD_PRODUCT_REQUEST' });

        try {
            const payload = await getIrelandEtdProduct(declarationId, productId);
            dispatch({
                type: 'GET_IRELAND_ETD_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'GET_IRELAND_ETD_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doCreateCdsProduct: Function =
    (product: CdsGovernmentAgencyGoodsItem, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'CREATE_UK_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await createCdsProduct(product, declarationId);
            dispatch({
                type: 'CREATE_UK_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'CREATE_UK_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doUpdateCdsProduct: Function =
    (product: CdsGovernmentAgencyGoodsItem, declarationId: string, productId: string) => async (dispatch: Function) => {
        dispatch({ type: 'UPDATE_UK_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await updateCdsProduct(product, declarationId, productId);
            dispatch({
                type: 'UPDATE_UK_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'UPDATE_UK_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };

export const doDeleteCdsProduct: Function =
    (productId: string, declarationId: string) => async (dispatch: Function) => {
        dispatch({ type: 'DELETE_UK_EXPORT_PRODUCT_REQUEST' });

        try {
            const payload = await deleteCdsProduct(productId, declarationId);
            dispatch({
                type: 'DELETE_UK_EXPORT_PRODUCT_SUCCESS',
                payload,
            });
            return payload;
        } catch (e: any) {
            dispatch({ type: 'DELETE_UK_EXPORT_PRODUCT_ERROR', error: e?.response?.data });
        }
    };
