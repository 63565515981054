import config from 'config';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { DownloadProductInfo, GeneralizedProductTemplate, ProductTemplate } from './products';

export type ListProductTemplateParams = Partial<PaginatedParams> & {
    declarationTemplateId?: string;
    messageType: string;
};

/** LIST PRODUCT TEMPLATES */

export const listProducts = (params?: Partial<ListProductTemplateParams>): Promise<ListPayload<ProductTemplate>> =>
    axiosClient
        .get<ListResponse<ProductTemplate>>(`${config.declarationsUrl}/ireland/products`, { params })
        .then((response) => response.data.payload);

export const listH7Products = (params?: Partial<ListProductTemplateParams>): Promise<ListPayload<ProductTemplate>> =>
    axiosClient
        .get<ListResponse<ProductTemplate>>(`${config.declarationsUrl}/ireland/products/h7`, { params })
        .then((response) => response.data.payload);

export const listCdsExportProducts = ({
    messageType,
    ...params
}: ListProductTemplateParams): Promise<ListPayload<GeneralizedProductTemplate>> =>
    axiosClient
        .get<ListResponse<GeneralizedProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/cds/export?${new URLSearchParams({
                declarationName: messageType,
            })}`,
            {
                params,
            }
        )
        .then((response) => response.data.payload);

export const listCdsImportProductsTemplates = ({
    messageType,
    ...params
}: ListProductTemplateParams): Promise<ListPayload<GeneralizedProductTemplate>> =>
    axiosClient
        .get<ListResponse<GeneralizedProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/cds/import?${new URLSearchParams({
                declarationName: messageType,
            })}`,
            {
                params,
            }
        )
        .then((response) => response.data.payload);

export const listEnsProducts = (
    params?: Partial<ListProductTemplateParams>
): Promise<ListPayload<GeneralizedProductTemplate>> =>
    axiosClient
        .get<ListResponse<GeneralizedProductTemplate>>(`${config.declarationsUrl}${config.productTemplateUrl}/ens`, {
            params,
        })
        .then((response) => response.data.payload);

export const listAesProductTemplates = ({
    messageType,
    ...params
}: ListProductTemplateParams): Promise<ListPayload<GeneralizedProductTemplate>> =>
    axiosClient
        .get<ListResponse<GeneralizedProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/ie/export?${new URLSearchParams({
                declarationName: messageType,
            })}`,
            {
                params,
            }
        )
        .then((response) => response.data.payload);

export const listNctsProductTemplates = ({
    messageType,
    ...params
}: ListProductTemplateParams): Promise<ListPayload<GeneralizedProductTemplate>> =>
    axiosClient
        .get<ListResponse<GeneralizedProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/ie/ncts?${new URLSearchParams({
                declarationName: messageType,
            })}`,
            {
                params,
            }
        )
        .then((response) => response.data.payload);

/** CREATE PRODUCT TEMPLATES */

export const createIrelandProductTemplate = (product: ProductTemplate): Promise<ProductTemplate> =>
    axiosClient
        .post<SuccessResponse<ProductTemplate>>(`${config.declarationsUrl}/ireland/products`, product)
        .then((response) => response.data.payload);

export const createH7ProductTemplate = (product: ProductTemplate): Promise<ProductTemplate> =>
    axiosClient
        .post<SuccessResponse<ProductTemplate>>(`${config.declarationsUrl}/ireland/products/h7`, product)
        .then((response) => response.data.payload);

export const createCdsExportProductTemplate = (product: ProductTemplate): Promise<GeneralizedProductTemplate> =>
    axiosClient
        .post<SuccessResponse<GeneralizedProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/cds/export`,
            product
        )
        .then((response) => response.data.payload);

export const createCdsImportProductTemplate = (product: ProductTemplate): Promise<GeneralizedProductTemplate> =>
    axiosClient
        .post<SuccessResponse<GeneralizedProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/cds/import`,
            product
        )
        .then((response) => response.data.payload);

export const createEnsProductTemplate = (product: ProductTemplate): Promise<GeneralizedProductTemplate> =>
    axiosClient
        .post<SuccessResponse<GeneralizedProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/ens`,
            product
        )
        .then((response) => response.data.payload);

export const createAesProductTemplate = (product: ProductTemplate): Promise<GeneralizedProductTemplate> =>
    axiosClient
        .post<SuccessResponse<GeneralizedProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/ie/export`,
            product
        )
        .then((response) => response.data.payload);

export const createNctsProductTemplate = (product: ProductTemplate): Promise<GeneralizedProductTemplate> =>
    axiosClient
        .post<SuccessResponse<GeneralizedProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/ie/ncts`,
            product
        )
        .then((response) => response.data.payload);

/** EDIT PRODUCT TEMPLATES */

export const editProductTemplate = (product: ProductTemplate): Promise<ProductTemplate> =>
    axiosClient
        .put<SuccessResponse<ProductTemplate>>(`${config.declarationsUrl}/ireland/products`, product)
        .then((response) => response.data.payload);

export const editH7ProductTemplate = (product: ProductTemplate): Promise<ProductTemplate> =>
    axiosClient
        .put<SuccessResponse<ProductTemplate>>(`${config.declarationsUrl}/ireland/products/h7`, product)
        .then((response) => response.data.payload);

export const editCdsExportProductTemplate = (product: ProductTemplate): Promise<GeneralizedProductTemplate> =>
    axiosClient
        .put<SuccessResponse<GeneralizedProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/cds/export/${product.id}`,
            product
        )
        .then((response) => response.data.payload);

export const editCdsImportProductTemplate = (product: ProductTemplate): Promise<GeneralizedProductTemplate> =>
    axiosClient
        .put<SuccessResponse<GeneralizedProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/cds/import/${product?.id}`,
            product
        )
        .then((response) => response.data.payload);

export const editEnsProductTemplate = (product: ProductTemplate): Promise<GeneralizedProductTemplate> =>
    axiosClient
        .put<SuccessResponse<GeneralizedProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/ens/${product.id}`,
            product
        )
        .then((response) => response.data.payload);

export const editAesProductTemplate = (product: ProductTemplate): Promise<GeneralizedProductTemplate> =>
    axiosClient
        .put<SuccessResponse<GeneralizedProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/ie/export/${product.id}`,
            product
        )
        .then((response) => response.data.payload);

export const editNctsProductTemplate = (product: ProductTemplate): Promise<GeneralizedProductTemplate> =>
    axiosClient
        .put<SuccessResponse<GeneralizedProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/ie/ncts/${product.id}`,
            product
        )
        .then((response) => response.data.payload);

/** GET PRODUCT TEMPLATES */

export const getProduct = (productId: string): Promise<ProductTemplate> =>
    axiosClient
        .get<SuccessResponse<ProductTemplate>>(`${config.declarationsUrl}/ireland/products/${productId}`)
        .then((response) => response.data.payload);

export const getH7Product = (productId: string): Promise<ProductTemplate> =>
    axiosClient
        .get<SuccessResponse<ProductTemplate>>(`${config.declarationsUrl}/ireland/products/h7/${productId}`)
        .then((response) => response.data.payload);

export const getGeneralizedProductTemplate = (productId: string): Promise<GeneralizedProductTemplate> =>
    axiosClient
        .get<SuccessResponse<GeneralizedProductTemplate>>(
            `${config.declarationsUrl}${config.productTemplateUrl}/${productId}`
        )
        .then((response) => response.data.payload);

/** DELETE PRODUCT TEMPLATES */

export const deleteProduct = (productId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .delete<SuccessResponse<void>>(`${config.declarationsUrl}/ireland/products/${productId}`)
        .then((response) => {
            return response.data;
        });

export const deleteH7Product = (productId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .delete<SuccessResponse<void>>(`${config.declarationsUrl}/ireland/products/h7/${productId}`)
        .then((response) => {
            return response.data;
        });

export const deleteGeneralizedProductTemplate = (productId: string): Promise<SuccessResponse<void>> =>
    axiosClient
        .delete<SuccessResponse<void>>(`${config.declarationsUrl}${config.productTemplateUrl}/${productId}`)
        .then((response) => response.data);

/** LIST TAGS */

export const listH1Tags = (): Promise<string[]> =>
    axiosClient.get<string[]>(`${config.declarationsUrl}/ireland/products/tags`).then((response) => {
        return response.data;
    });

export const listH7Tags = (): Promise<string[]> =>
    axiosClient.get<string[]>(`${config.declarationsUrl}/ireland/products/h7/tags`).then((response) => response.data);

export const listCdsExportTags = (messageType: string): Promise<string[]> =>
    axiosClient
        .get<string[]>(
            `${config.declarationsUrl}${config.productTemplateUrl}/cds/export/tags?${new URLSearchParams({
                declarationName: messageType,
            })}`
        )
        .then((response) => response.data);

export const listCdsImportTags = (messageType: string): Promise<string[]> =>
    axiosClient
        .get<string[]>(
            `${config.declarationsUrl}${config.productTemplateUrl}/cds/import/tags?${new URLSearchParams({
                declarationName: messageType,
            })}`
        )
        .then((response) => response.data);

export const listEnsTags = (): Promise<string[]> =>
    axiosClient
        .get<string[]>(`${config.declarationsUrl}${config.productTemplateUrl}/ens/tags`)
        .then((response) => response.data);

export const listAesTags = (messageType: string): Promise<string[]> =>
    axiosClient
        .get<string[]>(
            `${config.declarationsUrl}${config.productTemplateUrl}/ie/export/tags?${new URLSearchParams({
                declarationName: messageType,
            })}`
        )
        .then((response) => response.data);

export const listNctsTags = (messageType: string): Promise<string[]> =>
    axiosClient
        .get<string[]>(
            `${config.declarationsUrl}${config.productTemplateUrl}/ie/ncts/tags?${new URLSearchParams({
                declarationName: messageType,
            })}`
        )
        .then((response) => response.data);

/** UTILS */

export const uploadProductTemplate = (file: File): Promise<ProductTemplate[]> =>
    axiosClient
        .post<ProductTemplate[]>(`${config.declarationsUrl}/ireland/products/upload`, file, {
            params: { filename: file.name },
            headers: { 'content-type': file.type },
        })
        .then((response) => response.data);

export const downloadProductTemplate = (): Promise<DownloadProductInfo> =>
    axiosClient
        .get<SuccessResponse<DownloadProductInfo>>(`${config.declarationsUrl}/ireland/products/download`)
        .then((response) => response.data.payload);

export const createBulkProducts = (products: ProductTemplate[]): Promise<ProductTemplate[]> =>
    axiosClient
        .post<SuccessResponse<ProductTemplate[]>>(`${config.declarationsUrl}/ireland/products/bulk`, products)
        .then((response) => response.data.payload);

export const downloadUkProductTemplate = (): Promise<DownloadProductInfo> =>
    axiosClient
        .get<SuccessResponse<DownloadProductInfo>>(`${config.declarationsUrl}/uk/products/download`)
        .then((response) => response.data.payload);

export const uploadUkProductTemplate = (file: File): Promise<ProductTemplate[]> =>
    axiosClient
        .post<ProductTemplate[]>(`${config.declarationsUrl}/uk/products/upload`, file, {
            params: { filename: file.name },
            headers: { 'content-type': file.type },
        })
        .then((response) => response.data);

export const createUkBulkProducts = (products: ProductTemplate[]): Promise<ProductTemplate[]> =>
    axiosClient
        .post<SuccessResponse<ProductTemplate[]>>(`${config.declarationsUrl}/uk/products/bulk`, products)
        .then((response) => response.data.payload);
