import Drawer from 'components/ui/base/drawer/Drawer';
import { FormikProps, FormikProvider } from 'formik';
import { FC, useMemo, useState } from 'react';
import { Declaration } from 'store/declarations/declaration';
import IrelandEnsDeclarationErrors from './declaration-errors/ens/IrelandEnsDeclarationErrors';
import AisAesDeclarationErrors from './declaration-errors/AisAesDeclarationErrors';
import UkExportDeclarationErrors from './declaration-errors/cds/UkExportDeclarationErrors';
import { CdsExportNotification } from 'store/declarations/uk/cds-declaration';
import ConditionalWrapper from '../../../../components/ConditionalWrapper';
import AesActionRequiringNotification from './declaration-notifications/aes/AesActionRequiringNotification';
import { getDeclarationPayload } from '../../utils/declaration-utils';
import extractNotificationsData from './notificationUtils';
import GvmsDeclarationErrors from '../../uk/gvms/components/GvmsDeclarationErrors';
import { DeclarationStatus } from '../../../../store/declarations/enums/common/declaration-status';
import { WarningOutlined } from '@ant-design/icons';
import { colors, fonts } from '../../../../theme';
import styled from 'styled-components';
import ActionRequiredNotification from './declaration-notifications/ActionRequiredNotification';
import { NCTSInformationExchangeMessages } from 'store/declarations/enums/ireland/ncts/NCTSInformationExchangeMessages';
import { Title } from './DeclarationsNotificationsDrawer.styles';
import CC022C from './declaration-notifications/ncts/CC022C';
import TR015V from './declaration-notifications/ncts/TR015V';
import CC055C from './declaration-notifications/ncts/CC055C';
import RawData from './declaration-notifications/ncts/RawData';

export const ErrorButton = styled.span`
    font-style: normal;
    font-weight: ${fonts.weight.regular};
    font-size: 1.2rem;
    line-height: 2.2rem;
    text-align: center;
    color: ${(props) => props.color || colors.error};
    cursor: pointer;
    margin-right: 2.9rem;
`;

const DeclarationNotificationsDrawerButton = ({
    declaration,
    formik,
}: {
    declaration: Declaration | undefined;
    formik: FormikProps<any>;
}) => {
    const [showNotificationsDrawer, setShowNotificationsDrawer] = useState<boolean>(false);

    if (!declaration || declaration?.status === DeclarationStatus.SUBMITTED) return null;

    const declarationPayload = getDeclarationPayload(declaration);

    const { declarationNotificationErrors } = extractNotificationsData(declarationPayload);

    const handleOpenDrawer = () => setShowNotificationsDrawer(true);
    let button;
    if (declarationNotificationErrors?.length) {
        button = (
            <ErrorButton onClick={handleOpenDrawer}>
                View error messages <WarningOutlined />
            </ErrorButton>
        );
    } else if (declaration?.actionRequired) {
        button = (
            <ErrorButton color={colors.action} onClick={handleOpenDrawer}>
                View notifications requiring action <WarningOutlined />
            </ErrorButton>
        );
    }

    return (
        <>
            <DeclarationNotificationsDrawer
                visible={showNotificationsDrawer}
                onClose={() => setShowNotificationsDrawer(false)}
                declaration={declaration}
                formik={formik}
            />
            {button}
        </>
    );
};

interface Props {
    declaration?: Declaration;
    visible: boolean;
    onClose: () => void;
    formik?: FormikProps<any>;
    showRawData?: boolean;
}

export const DeclarationNotificationsDrawer: FC<Props> = ({ declaration, visible, onClose, formik, showRawData }) => {
    const drawerContent = useMemo(() => {
        if (!declaration) return <></>;
        const declarationPayload = getDeclarationPayload(declaration);

        if (!declarationPayload) return <></>;

        const {
            sortedNotifications,
            latestNotification,
            declarationNotificationErrors: errors,
        } = extractNotificationsData(declarationPayload);

        const additionalInformation = (latestNotification as CdsExportNotification | undefined)
            ?.additionalInformationMessages; // Cds Export

        let DeclarationNotificationsComponent;
        let specificProps;
        let drawerType: 'requireAction' | 'showErrors' = 'showErrors';
        switch (declarationPayload) {
            case declaration?.irelandImportDeclaration:
            case declaration?.irelandH7ImportDeclaration:
            case declaration?.ieExportDeclaration:
            case declaration?.ieArrivalAtExitDeclaration:
                if (declaration?.actionRequired) {
                    DeclarationNotificationsComponent = AesActionRequiringNotification;
                    drawerType = 'requireAction';
                    break;
                }

                DeclarationNotificationsComponent = AisAesDeclarationErrors;

                if (!errors) {
                    DeclarationNotificationsComponent = RawData;
                    specificProps = {
                        notification: latestNotification,
                    } as any;
                } else {
                    specificProps = { showRawData, errors };
                }
                break;

            case declaration?.ieNctsDeclaration:
                if (declaration?.actionRequired) {
                    DeclarationNotificationsComponent = ActionRequiredNotification;
                    drawerType = 'requireAction';
                    specificProps = {
                        render: (notification: any, rawMessage: string) => {
                            const specificRender = () => {
                                switch (notification.messageType) {
                                    case NCTSInformationExchangeMessages.CC022C:
                                        return <CC022C notification={notification} />;
                                    case NCTSInformationExchangeMessages.CC055C:
                                        return <CC055C notification={notification} />;
                                    case NCTSInformationExchangeMessages.CC043C:
                                    case NCTSInformationExchangeMessages.CC140C:
                                    case NCTSInformationExchangeMessages.TR064:
                                    case NCTSInformationExchangeMessages.TR864:
                                    case NCTSInformationExchangeMessages.TR062:
                                    case NCTSInformationExchangeMessages.TR862:
                                    case NCTSInformationExchangeMessages.TR084:
                                    case NCTSInformationExchangeMessages.TR884:
                                    case NCTSInformationExchangeMessages.TR082:
                                    case NCTSInformationExchangeMessages.TR882:
                                        return <RawData notification={notification} rawMessage={rawMessage} />;
                                    case NCTSInformationExchangeMessages.TR015V:
                                        return <TR015V notification={notification} />;
                                    default:
                                        return <RawData notification={notification} rawMessage={rawMessage} />;
                                }
                            };

                            return (
                                <>
                                    <Title>Notification details</Title>
                                    {specificRender()}
                                </>
                            );
                        },
                        rawMessageOnly: true,
                    };
                    break;
                }

                DeclarationNotificationsComponent = AisAesDeclarationErrors;
                if (!errors) {
                    DeclarationNotificationsComponent = RawData;
                    specificProps = {
                        notification: latestNotification,
                    } as any;
                } else {
                    specificProps = { showRawData, errors };
                }
                break;

            case declaration?.entrySummaryDeclaration:
                DeclarationNotificationsComponent = IrelandEnsDeclarationErrors;
                specificProps = { showRawData, notifications: sortedNotifications, errors };
                break;

            case declaration?.cdsDeclaration:
                DeclarationNotificationsComponent = UkExportDeclarationErrors;
                specificProps = { showRawData, additionalInformation, notifications: sortedNotifications, errors };
                break;

            case declaration?.gvmsDeclaration:
                DeclarationNotificationsComponent = GvmsDeclarationErrors;
                specificProps = { errors };
                break;

            default:
                return <></>;
        }

        return {
            component: (
                <DeclarationNotificationsComponent
                    onClose={onClose}
                    latestNotification={latestNotification}
                    {...(specificProps || {})}
                />
            ),
            drawerType,
        };
    }, [onClose, declaration, showRawData]);

    const drawerProps =
        'drawerType' in drawerContent && drawerContent.drawerType === 'showErrors'
            ? { warning: true, title: `${declaration?.gvmsDeclaration ? 'Record' : 'Declaration'} errors` }
            : { caution: true, title: 'Notifications requiring action' };

    return (
        <Drawer visible={visible} onClose={onClose} width={820} {...drawerProps}>
            <ConditionalWrapper
                condition={Boolean(formik)}
                wrapper={(children) => <FormikProvider value={formik as FormikProps<any>}> {children}</FormikProvider>}
            >
                {'component' in drawerContent ? drawerContent.component : <></>}
            </ConditionalWrapper>
        </Drawer>
    );
};
export default DeclarationNotificationsDrawerButton;
