import { FormikProps } from 'formik';
import { useMemo } from 'react';
import useCustomers from '../../../../../hooks/useCustomers';
import useDeclarations from '../../../../../hooks/useDeclarations';
import useIndividuals from '../../../../../hooks/useIndividuals';
import useSession from '../../../../../hooks/useSession';
import InvoiceModalButton from '../../../ireland/import/h1/invoice/InvoiceModalButton';
import DeclarationNotificationsDrawerButton from '../../declaration-errors-drawer/DeclarationNotificationsDrawerButton';
import SubmissionErrorsDrawerButton from '../../submissionErrors/SubmissionErrorsDrawerButton';
import AlertManager from './alerts/AlertManager';
import SubmissionModalsManager from './SubmissionModalsManager';

interface Props {
    submitting: boolean;
    submissionModalsActions: { validateForm: () => Promise<boolean>; onCancel: () => void };
    formik: FormikProps<any>;
    saveAsDraft: Function;
}

const DeclarationPopupManager = ({ submitting, submissionModalsActions, formik, saveAsDraft }: Props) => {
    const { declaration, error } = useDeclarations();
    const { userInfo } = useSession();
    const { individual } = useIndividuals({ individualId: userInfo?.individualId });
    const { customer } = useCustomers({ customerId: userInfo?.customerId });

    const hasRightCertificate: boolean | undefined = useMemo(() => {
        if (declaration?.cdsDeclaration || declaration?.gvmsDeclaration) {
            return individual?.hasHmrcToken;
        } else if (
            declaration?.ieExportDeclaration ||
            declaration?.ieArrivalAtExitDeclaration ||
            declaration?.ieNctsDeclaration
        ) {
            return customer?.hasAesCertificate;
        } else if (
            declaration?.irelandImportDeclaration ||
            declaration?.irelandH7ImportDeclaration ||
            declaration?.ieImportTemporaryStorageDeclaration ||
            declaration?.ieImportElectronicTransportDocument
        ) {
            return customer?.hasAisCertificate;
        } else if (declaration?.entrySummaryDeclaration) {
            return customer?.hasAepIcsCertificate;
        } else if (declaration?.preBoardingNotification) {
            return true;
        }
    }, [customer, individual, declaration]);

    const showSubmissionErrorsDrawer =
        error?.category === 'VALIDATION' &&
        (declaration?.irelandImportDeclaration ||
            declaration?.irelandH7ImportDeclaration ||
            declaration?.entrySummaryDeclaration);

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10, position: 'absolute', right: 0, bottom: 7 }}>
                <InvoiceModalButton declaration={declaration} saveAsDraft={saveAsDraft} formikValues={formik.values} />

                {showSubmissionErrorsDrawer ? (
                    <SubmissionErrorsDrawerButton declarationError={error} />
                ) : (
                    <DeclarationNotificationsDrawerButton declaration={declaration} formik={formik} />
                )}
            </div>

            <SubmissionModalsManager
                customerId={declaration?.customerId}
                isGvms={Boolean(declaration?.gvmsDeclaration)}
                hasRightCertificate={hasRightCertificate}
                submitting={submitting}
                submissionModalsActions={submissionModalsActions}
            />

            <AlertManager declaration={declaration} hasRightCertificate={hasRightCertificate} />
        </>
    );
};

export default DeclarationPopupManager;
