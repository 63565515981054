import { Reducer } from 'redux';
import { CodelistsActions } from './actionType';
import { IrelandCodelistTypes, IrelandNctsCodelists, UkCodelistTypes } from './code';

export interface CodelistsState {
    irelandCodelists?: IrelandCodelistTypes;
    ukCodelists?: UkCodelistTypes;
    nctsCodelists?: IrelandNctsCodelists;
}

export const codelistsInitialState: Readonly<CodelistsState> = {};

const codelists: Reducer<CodelistsState, CodelistsActions> = (
    state = codelistsInitialState,
    action: CodelistsActions
) => {
    switch (action.type) {
        case 'GET_IRELAND_CODELISTS_SUCCESS':
            return {
                ...state,
                irelandCodelists: action.payload,
            };
        case 'GET_IRELAND_CODELISTS_ERROR':
            return {
                ...state,
                irelandCodelists: action.payload,
            };
        case 'GET_UK_CODELISTS_REQUEST':
            return {
                ...state,
            };
        case 'GET_UK_CODELISTS_SUCCESS':
            return {
                ...state,
                ukCodelists: action.payload,
            };
        case 'GET_UK_CODELISTS_ERROR':
            return {
                ...state,
                ukCodelists: action.payload,
            };
        default:
            return state;
    }
};

export default codelists;
