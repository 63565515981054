import { Col, Row } from 'antd';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import FormEori from 'components/ui/composed/formEori/FormEori';
import useCodelists from 'hooks/useCodelists';
import { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import addPathPrefix from 'utils/addPathPrefix';
import { getFormikProps, handleToggleHelp } from 'views/declarations/utils/form-utils';
import { invalidEoriMessage } from 'views/declarations/utils/validation-utils';
import { addToItemOfUcc, Party, Paths } from './PartiesCard';
import PartiesEoriFormProps from './PartiesEoriFormProps';
import { FormCardContainer } from '../cards/NewFormCard';
import { validators } from 'views/declarations/uk/export/validations/validations';
import useFormUtils from '../../../../hooks/useFormUtils';
import { useFormikContext } from 'formik';
import { IrelandExportDeclaration } from '../../../../store/declarations/ireland/export-declaration';
import { deepObjHasTruthyNonObjValue } from '../../../../core/utils/objects';
import { DeclarationInternalType } from '../../../../store/declarations/enums/common/declaration-internal-type';

const createEoriPath = (party: Party, paths: Paths) => {
    return {
        eori: addPathPrefix(party.path, paths.eori || paths.eoriByPath?.(party.path)),
        ...(party.hasRepresentativeStatus &&
            paths.additional?.representativeStatus && {
                representativeStatus: addPathPrefix(party.path, paths.additional.representativeStatus),
            }),
    };
};

const PartiesEoriForm = ({
    party,
    paths: pathsProp,
    refNumber,
    eoriRequired,
    eoriLabel,
    condensed,
    onBlur,
    disabled,
    ...other
}: PartiesEoriFormProps): ReactElement => {
    const { t } = useTranslation('form');
    const { aisCodelists, aesCodelists, nctsCodelists } = useCodelists();
    const { isAis, isAes, internalType } = useFormUtils();
    const formik = useFormikContext();

    const eoriLevelRequired = useMemo(() => {
        if (!isAes) return false;

        return deepObjHasTruthyNonObjValue((formik?.values as IrelandExportDeclaration)?.representative);
    }, [isAes, formik.values]);

    const paths = useMemo(() => createEoriPath(party, pathsProp), [party, pathsProp]);

    const onError = useCallback(() => {
        other.getFieldHelpers!(paths.eori).setError(invalidEoriMessage);
    }, [other.getFieldHelpers, paths.eori]);

    const onSuccess = useCallback(() => {
        other.getFieldHelpers!(paths.eori).setError(undefined);
    }, [other.getFieldHelpers, paths.eori]);

    const representativeCodelist = useMemo(() => {
        if (
            isAis ||
            internalType === DeclarationInternalType.TEMPORARY ||
            internalType === DeclarationInternalType.ETD
        ) {
            return aisCodelists?.representativeStatusCode;
        } else if (isAes) {
            return aesCodelists?.representativeStatusCode;
        } else if (internalType === DeclarationInternalType.NCTS) {
            return nctsCodelists?.representativeStatusCode;
        }
    }, [
        aisCodelists?.representativeStatusCode,
        isAis,
        isAes,
        aesCodelists?.representativeStatusCode,
        internalType,
        nctsCodelists?.representativeStatusCode,
    ]);

    if (!condensed) {
        return (
            <>
                <Row gutter={12}>
                    <Col sm={24}>
                        <FormEori
                            declaration
                            required={eoriRequired || eoriLevelRequired}
                            viewOnly={other.viewOnly}
                            refNumber={addToItemOfUcc(refNumber, 1)}
                            label={t(eoriLabel ?? 'identificationNumber')}
                            {...getFormikProps(paths.eori, other)}
                            refClicked={(ref) => handleToggleHelp(ref, other)}
                            onError={onError}
                            onSuccess={onSuccess}
                            condensed={condensed}
                            onBlur={onBlur}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
                {paths.representativeStatus && (
                    <FormSelect
                        required={eoriLevelRequired}
                        viewOnly={other.viewOnly}
                        disabled={other.amendment || disabled}
                        {...getFormikProps(paths.representativeStatus, other)}
                        refNumber={addToItemOfUcc(refNumber, 1)}
                        label={t('Representative status')}
                        selectOptions={aisCodelists?.representativeStatusCode}
                        condensed={condensed}
                        onChange={onBlur}
                        codeValidation={[validators.exact(1)]}
                    />
                )}
            </>
        );
    }

    return (
        <FormCardContainer>
            <FormEori
                declaration
                required={eoriRequired || eoriLevelRequired}
                viewOnly={other.viewOnly}
                refNumber={addToItemOfUcc(refNumber, 1)}
                label={t(eoriLabel ?? 'identificationNumber')}
                {...getFormikProps(paths.eori, other)}
                refClicked={(ref) => handleToggleHelp(ref, other)}
                onError={onError}
                onSuccess={onSuccess}
                condensed={condensed}
                onBlur={onBlur}
                disabled={disabled}
            />
            {paths.representativeStatus && (
                <FormSelect
                    required={eoriLevelRequired}
                    viewOnly={other.viewOnly}
                    disabled={other.amendment || disabled}
                    {...getFormikProps(paths.representativeStatus, other)}
                    refNumber={addToItemOfUcc(refNumber, 1)}
                    label={isAes ? 'Status' : t('Representative status')}
                    selectOptions={representativeCodelist}
                    condensed={condensed}
                    onChange={onBlur}
                    codeValidation={[validators.exact(1)]}
                />
            )}
        </FormCardContainer>
    );
};

export default PartiesEoriForm;
