import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { GoodsShipmentItem as EtdGoodsShipmentItem } from 'store/declarations/ireland/electronic-transport-document';
import { EnsGoodsShipmentItem as EnsProduct } from 'store/declarations/ireland/entry-summary-declaration';
import { IrelandExportItem } from 'store/declarations/ireland/export-declaration';
import { GoodsShipmentItem as H7GoodsShipmentItem } from 'store/declarations/ireland/h7-import-declaration';
import { GoodsShipmentItem as H1GoodsShipmentItem } from 'store/declarations/ireland/import-declaration';
import { ProductsActions } from './actionType';
import { CdsDeclaration } from 'store/declarations/uk/cds-declaration';

type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

export type CdsExportGovernmentAgencyGoodsItemPayload = ArrayElement<CdsDeclaration['cdsGovernmentAgencyGoodsItems']>;

interface ProductsEntities {
    [key: string]:
        | H1GoodsShipmentItem
        | H7GoodsShipmentItem
        | IrelandExportItem
        | EnsProduct
        | EtdGoodsShipmentItem
        | CdsExportGovernmentAgencyGoodsItemPayload;
}

type ProductsTypes =
    | H1GoodsShipmentItem
    | H7GoodsShipmentItem
    | IrelandExportItem
    | EnsProduct
    | EtdGoodsShipmentItem
    | CdsExportGovernmentAgencyGoodsItemPayload;

export interface ProductsState {
    isLoading: boolean;
    error?: ErrorResponse;
    entities: ProductsEntities;
    products?:
        | ListPayload<H1GoodsShipmentItem>
        | ListPayload<H7GoodsShipmentItem>
        | ListPayload<IrelandExportItem>
        | ListPayload<EnsProduct>
        | ListPayload<EtdGoodsShipmentItem>
        | ListPayload<CdsExportGovernmentAgencyGoodsItemPayload>
        | null;
}

export const productsInitialState: Readonly<ProductsState> = {
    isLoading: false,
    products: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
    entities: {},
};

const products: Reducer<ProductsState, ProductsActions> = (state = productsInitialState, action): ProductsState => {
    switch (action.type) {
        case 'CREATE_IRELAND_IMPORT_PRODUCT_REQUEST':
        case 'CREATE_UK_EXPORT_PRODUCT_REQUEST':
        case 'CREATE_IRELAND_ETD_PRODUCT_REQUEST':
        case 'UPDATE_IRELAND_EXPORT_PRODUCT_REQUEST':
        case 'GET_IRELAND_H7_PRODUCT_REQUEST':
        case 'GET_IRELAND_EXPORT_PRODUCT_REQUEST':
        case 'GET_UK_EXPORT_PRODUCT_REQUEST':
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case 'GET_IRELAND_ENS_PRODUCT_SUCCESS':
        case 'GET_IRELAND_H1_PRODUCT_SUCCESS':
        case 'GET_IRELAND_H7_PRODUCT_SUCCESS':
        case 'GET_IRELAND_EXPORT_PRODUCT_SUCCESS':
        case 'GET_UK_EXPORT_PRODUCT_SUCCESS':
        case 'GET_IRELAND_ETD_PRODUCT_SUCCESS':
        case 'CREATE_IRELAND_IMPORT_PRODUCT_SUCCESS':
        case 'UPDATE_IRELAND_IMPORT_PRODUCT_SUCCESS':
        case 'CREATE_IRELAND_H7_IMPORT_PRODUCT_SUCCESS':
        case 'UPDATE_IRELAND_H7_IMPORT_PRODUCT_SUCCESS':
        case 'CREATE_IRELAND_EXPORT_PRODUCT_SUCCESS':
        case 'UPDATE_IRELAND_EXPORT_PRODUCT_SUCCESS':
        case 'CREATE_IRELAND_ENS_PRODUCT_SUCCESS':
        case 'CREATE_IRELAND_ETD_PRODUCT_SUCCESS':
        case 'UPDATE_IRELAND_ETD_PRODUCT_SUCCESS':
        case 'CREATE_UK_EXPORT_PRODUCT_SUCCESS':
        case 'UPDATE_UK_EXPORT_PRODUCT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id!]: action.payload },
            };
        case 'CREATE_IRELAND_IMPORT_PRODUCT_ERROR':
        case 'UPDATE_IRELAND_IMPORT_PRODUCT_ERROR':
        case 'CREATE_IRELAND_H7_IMPORT_PRODUCT_ERROR':
        case 'UPDATE_IRELAND_H7_IMPORT_PRODUCT_ERROR':
        case 'CREATE_IRELAND_EXPORT_PRODUCT_ERROR':
        case 'UPDATE_IRELAND_EXPORT_PRODUCT_ERROR':
        case 'LIST_IRELAND_ENS_PRODUCTS_ERROR':
        case 'LIST_IRELAND_H1_PRODUCTS_ERROR':
        case 'LIST_IRELAND_H7_PRODUCTS_ERROR':
        case 'LIST_IRELAND_EXPORT_PRODUCTS_ERROR':
        case 'LIST_UK_EXPORT_PRODUCTS_ERROR':
        case 'CREATE_IRELAND_ETD_PRODUCT_ERROR':
        case 'UPDATE_IRELAND_ETD_PRODUCT_ERROR':
        case 'LIST_IRELAND_ETD_PRODUCT_ERROR':
        case 'GET_IRELAND_ETD_PRODUCT_ERROR':
        case 'CREATE_IRELAND_TSD_PRODUCT_ERROR':
        case 'CREATE_UK_EXPORT_PRODUCT_ERROR':
        case 'UPDATE_UK_EXPORT_PRODUCT_ERROR':
            return {
                ...state,
                isLoading: false,
                products: state.products,
                error: action.error,
            };
        case 'UPDATE_IRELAND_IMPORT_PRODUCT_REQUEST':
        case 'DELETE_IRELAND_IMPORT_PRODUCT_REQUEST':
        case 'CREATE_IRELAND_H7_IMPORT_PRODUCT_REQUEST':
        case 'UPDATE_IRELAND_H7_IMPORT_PRODUCT_REQUEST':
        case 'DELETE_IRELAND_H7_IMPORT_PRODUCT_REQUEST':
        case 'CREATE_IRELAND_EXPORT_PRODUCT_REQUEST':
        case 'DELETE_IRELAND_EXPORT_PRODUCT_REQUEST':
        case 'CREATE_IRELAND_ENS_PRODUCT_REQUEST':
        case 'UPDATE_IRELAND_ENS_PRODUCT_REQUEST':
        case 'DELETE_IRELAND_ENS_PRODUCT_REQUEST':
        case 'GET_IRELAND_ENS_PRODUCT_REQUEST':
        case 'LIST_IRELAND_ENS_PRODUCTS_REQUEST':
        case 'LIST_IRELAND_H1_PRODUCTS_REQUEST':
        case 'GET_IRELAND_H1_PRODUCT_REQUEST':
        case 'LIST_IRELAND_H7_PRODUCTS_REQUEST':
        case 'LIST_IRELAND_EXPORT_PRODUCTS_REQUEST':
        case 'LIST_UK_EXPORT_PRODUCTS_REQUEST':
        case 'UPDATE_IRELAND_ETD_PRODUCT_REQUEST':
        case 'LIST_IRELAND_ETD_PRODUCT_REQUEST':
        case 'GET_IRELAND_ETD_PRODUCT_REQUEST':
        case 'UPDATE_UK_EXPORT_PRODUCT_REQUEST':
        case 'DELETE_UK_EXPORT_PRODUCT_REQUEST':
            return {
                ...state,
                error: undefined,
                isLoading: true,
            };

        case 'DELETE_IRELAND_IMPORT_PRODUCT_SUCCESS':
        case 'DELETE_IRELAND_H7_IMPORT_PRODUCT_SUCCESS':
        case 'DELETE_IRELAND_EXPORT_PRODUCT_SUCCESS':
        case 'DELETE_IRELAND_ENS_PRODUCT_SUCCESS':
        case 'DELETE_UK_EXPORT_PRODUCT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        case 'DELETE_IRELAND_IMPORT_PRODUCT_ERROR':
        case 'DELETE_IRELAND_H7_IMPORT_PRODUCT_ERROR':
        case 'DELETE_IRELAND_EXPORT_PRODUCT_ERROR':
        case 'DELETE_IRELAND_ENS_PRODUCT_ERROR':
        case 'DELETE_UK_EXPORT_PRODUCT_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };

        case 'CREATE_IRELAND_ENS_PRODUCT_ERROR':
        case 'UPDATE_IRELAND_ENS_PRODUCT_ERROR':
        case 'GET_IRELAND_ENS_PRODUCT_ERROR':
        case 'GET_IRELAND_H1_PRODUCT_ERROR':
        case 'GET_IRELAND_H7_PRODUCT_ERROR':
        case 'GET_IRELAND_EXPORT_PRODUCT_ERROR':
        case 'GET_UK_EXPORT_PRODUCT_ERROR':
            return {
                ...state,
                isLoading: false,
                entities: state.entities,
                error: action.error,
            };
        case 'UPDATE_IRELAND_ENS_PRODUCT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                entities: { ...state.entities, [action.payload.id!]: action.payload },
                products: {
                    ...state.products,
                    list:
                        state.products?.list.map((obj: ProductsTypes) => {
                            if (obj.id === action.payload.id) {
                                return action.payload as ProductsTypes;
                            }
                            return obj;
                        }) ?? ([] as ProductsTypes[]),
                } as ListPayload<ProductsTypes>,
            };

        case 'LIST_IRELAND_ENS_PRODUCTS_SUCCESS':
        case 'LIST_IRELAND_H1_PRODUCTS_SUCCESS':
        case 'LIST_IRELAND_H7_PRODUCTS_SUCCESS':
        case 'LIST_IRELAND_EXPORT_PRODUCTS_SUCCESS':
        case 'LIST_UK_EXPORT_PRODUCTS_SUCCESS':
        case 'LIST_IRELAND_ETD_PRODUCT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                products: action.payload,
            };

        case 'CLEAR_PRODUCTS_ERROR':
            return {
                ...state,
                error: undefined,
            };

        default:
            return state;
    }
};

export default products;
