import { PaginatedParams } from 'core/http/pagination';
import { GoodsShipmentItem as EtdGoodsShipmentItem } from 'store/declarations/ireland/electronic-transport-document';
import { EnsGoodsShipmentItem } from 'store/declarations/ireland/entry-summary-declaration';
import { IrelandExportItem } from 'store/declarations/ireland/export-declaration';
import { GoodsShipmentItem as H7GoodsShipmentItem } from 'store/declarations/ireland/h7-import-declaration';
import { GoodsShipmentItem as H1GoodsShipmentItem } from 'store/declarations/ireland/import-declaration';
import {
    doClearError,
    doCreateIrelandEnsProduct,
    doCreateIrelandEtdProduct,
    doCreateIrelandTsdProduct,
    doCreateIrelandExportProduct,
    doCreateIrelandH7ImportProduct,
    doCreateIrelandImportProduct,
    doDeleteIrelandEnsProduct,
    doDeleteIrelandExportProduct,
    doDeleteIrelandH7ImportProduct,
    doDeleteIrelandImportProduct,
    doGetIrelandEnsProduct,
    doGetIrelandEtdProduct,
    doGetIrelandExportProduct,
    doGetIrelandH1Product,
    doGetIrelandH7Product,
    doGetCdsProduct,
    doListIrelandEnsProducts,
    doListIrelandEtdProducts,
    doListIrelandTsdProducts,
    doListIrelandExportProducts,
    doListIrelandH1Products,
    doListIrelandH7Products,
    doListCdsProducts,
    doUpdateIrelandEnsProduct,
    doUpdateIrelandEtdProduct,
    doUpdateIrelandTsdProduct,
    doUpdateIrelandExportProduct,
    doUpdateIrelandH7ImportProduct,
    doUpdateIrelandImportProduct,
    doDeleteIrelandTsdProduct,
    doGetIrelandTsdProduct,
    doDeleteCdsProduct,
    doCreateCdsProduct,
    doUpdateCdsProduct,
    doListArrivalAtExitProducts,
    doCreateArrivalAtExitProduct,
    doUpdateArrivalAtExitProduct,
    doGetArrivalAtExitProduct,
    doDeleteArrivalAtExitProduct,
    doCreateIrelandNctsProduct,
    doUpdateIrelandNctsProduct,
    doGetIrelandNctsProduct,
    doDeleteIrelandNctsProduct,
    doListIrelandNctsProducts,
    doDeleteIrelandEtdProduct,
} from 'store/products/action';
import { useAppDispatch, useAppSelector } from '../config/hooks';
import useResponseNotification from 'hooks/useResponseNotification';
import { useTranslation } from 'react-i18next';
import { createProductNotifs, deleteDeclarationProductNotifs, saveProductNotifs } from '../utils/notificationMessages';
import { CdsGovernmentAgencyGoodsItem } from 'store/declarations/uk/cds-declaration';
import { CdsExportGovernmentAgencyGoodsItemPayload } from 'store/products/reducer';
import { DeclarationInternalType } from '../store/declarations/enums/common/declaration-internal-type';
import { DeclarationCountry } from '../store/declarations/enums/common/declaration-country';
import { AISMessageTypes, ENSMessageTypes, MessageTypes } from '../store/declarations/enums/common/declaration-types';
import { TemplateData } from '../components/ui/composed/template/TemplateContext';
import { ArrivalAtExitProduct } from '../store/products/client';
import { IrelandTemporaryStorageDeclaration } from '../store/declarations/ireland/temporary-storage-declaration';

interface UseProductsProps {
    productId?: string;
}

export interface DeclarationTypes {
    formType: MessageTypes | string | undefined;
    internalType: DeclarationInternalType | string | undefined;
    country: DeclarationCountry | string | undefined;
}

export type CreateProductFunction = (product: any, declarationId: string) => Promise<any>;
export type UpdateProductFunction = (product: any, declarationId: string, productId: string) => Promise<any>;
export type DeleteProductFunction = (productId: any, declarationId: string) => Promise<any>;
export type ListProductFunction = (declarationId: string, params: Partial<PaginatedParams>) => Promise<any>;

const object = {} as Record<any, any>;

const useProducts = ({ productId }: UseProductsProps = {}) => {
    const isLoading = useAppSelector((state) => state.products.isLoading);
    const error = useAppSelector((state) => state.products.error);
    const products = useAppSelector((state) => state.products.products);
    const product = useAppSelector((state) => (productId ? state.products.entities[productId] : object));
    const { withResponseNotifications } = useResponseNotification();
    const { t } = useTranslation('common');

    const dispatch = useAppDispatch();

    const createIrelandImportProduct = async (product: H1GoodsShipmentItem, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doCreateIrelandImportProduct(product, declarationId)),
            createProductNotifs(t)
        );

    const createIrelandEtdProduct = async (product: EtdGoodsShipmentItem, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doCreateIrelandEtdProduct(product, declarationId)),
            createProductNotifs(t)
        );

    const createIrelandTsdProduct = async (
        product: IrelandTemporaryStorageDeclaration['goodsShipment']['goodsShipmentItem'][0],
        declarationId: string
    ) =>
        withResponseNotifications(
            await dispatch(doCreateIrelandTsdProduct(product, declarationId)),
            createProductNotifs(t)
        );

    const createIrelandNctsProduct = async (product: any, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doCreateIrelandNctsProduct(product, declarationId)),
            createProductNotifs(t)
        );

    const updateIrelandTsdProduct = async (
        product: IrelandTemporaryStorageDeclaration['goodsShipment']['goodsShipmentItem'][0],
        declarationId: string,
        productId: string
    ) =>
        withResponseNotifications(
            await dispatch(doUpdateIrelandTsdProduct(product, declarationId, productId)),
            saveProductNotifs(t)
        );

    const updateIrelandNctsProduct = async (product: any, declarationId: string, productId: string) =>
        withResponseNotifications(
            await dispatch(doUpdateIrelandNctsProduct(product, declarationId, productId)),
            saveProductNotifs(t)
        );

    const deleteIrelandTsdProduct = (productId: string, declarationId: string) => {
        return dispatch(doDeleteIrelandTsdProduct(productId, declarationId));
    };

    const deleteIrelandNctsProduct = (productId: string, declarationId: string) => {
        return dispatch(doDeleteIrelandNctsProduct(productId, declarationId));
    };

    const deleteIrelandEtdProduct = (productId: string, declarationId: string) => {
        return dispatch(doDeleteIrelandEtdProduct(productId, declarationId));
    };

    const listIrelandTsdProducts = (declarationId: string, params?: Partial<PaginatedParams>) => {
        return dispatch(doListIrelandTsdProducts(declarationId, params));
    };

    const listIrelandNctsProducts = (declarationId: string, params?: Partial<PaginatedParams>) => {
        return dispatch(doListIrelandNctsProducts(declarationId, params));
    };

    const getIrelandTsdProduct = (productId: string, declarationId: string) => {
        return dispatch(doGetIrelandTsdProduct(productId, declarationId));
    };

    const getIrelandNctsProduct = (productId: string, declarationId: string) => {
        return dispatch(doGetIrelandNctsProduct(productId, declarationId));
    };

    const updateIrelandImportProduct = async (product: H1GoodsShipmentItem, declarationId: string, productId: string) =>
        withResponseNotifications(
            await dispatch(doUpdateIrelandImportProduct(product, declarationId, productId)),
            saveProductNotifs(t)
        );

    const deleteIrelandImportProduct = (productId: string, declarationId: string) => {
        return dispatch(doDeleteIrelandImportProduct(productId, declarationId));
    };

    const createIrelandH7ImportProduct = async (product: H7GoodsShipmentItem, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doCreateIrelandH7ImportProduct(product, declarationId)),
            createProductNotifs(t)
        );

    const updateIrelandH7ImportProduct = async (
        product: H7GoodsShipmentItem,
        declarationId: string,
        productId: string
    ) =>
        withResponseNotifications(
            await dispatch(doUpdateIrelandH7ImportProduct(product, declarationId, productId)),
            saveProductNotifs(t)
        );

    const deleteIrelandH7ImportProduct = (productId: string, declarationId: string) => {
        return dispatch(doDeleteIrelandH7ImportProduct(productId, declarationId));
    };

    const createIrelandExportProduct = async (product: IrelandExportItem, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doCreateIrelandExportProduct(product, declarationId)),
            createProductNotifs(t)
        );

    const createArrivalAtExitProduct = async (product: ArrivalAtExitProduct, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doCreateArrivalAtExitProduct(product, declarationId)),
            createProductNotifs(t)
        );

    const updateIrelandExportProduct = async (product: IrelandExportItem, declarationId: string, productId: string) =>
        withResponseNotifications(
            await dispatch(doUpdateIrelandExportProduct(product, declarationId, productId)),
            saveProductNotifs(t)
        );

    const updateArrivalAtExitProduct = async (
        product: ArrivalAtExitProduct,
        declarationId: string,
        productId: string
    ) =>
        withResponseNotifications(
            await dispatch(doUpdateArrivalAtExitProduct(product, declarationId, productId)),
            saveProductNotifs(t)
        );

    const deleteIrelandExportProduct = (productId: string, declarationId: string) => {
        return dispatch(doDeleteIrelandExportProduct(productId, declarationId));
    };

    const deleteArrivalAtExitProduct = (productId: string, declarationId: string) => {
        return dispatch(doDeleteArrivalAtExitProduct(productId, declarationId));
    };

    const createIrelandEnsProduct = async (product: EnsGoodsShipmentItem, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doCreateIrelandEnsProduct(product, declarationId)),
            createProductNotifs(t)
        );

    const updateIrelandEnsProduct = async (product: EnsGoodsShipmentItem, declarationId: string) =>
        withResponseNotifications(
            await dispatch(doUpdateIrelandEnsProduct(product, declarationId)),
            saveProductNotifs(t)
        );

    const deleteIrelandEnsProduct = (productId: string, declarationId: string) => {
        return dispatch(doDeleteIrelandEnsProduct(productId, declarationId));
    };

    const getIrelandEnsProduct = (productId: string, declarationId: string) => {
        return dispatch(doGetIrelandEnsProduct(productId, declarationId));
    };

    const listIrelandEnsProducts = (declarationId: string, params?: Partial<PaginatedParams>) => {
        return dispatch(doListIrelandEnsProducts(declarationId, params));
    };

    const listIrelandH1Products = (declarationId: string, params?: Partial<PaginatedParams>) => {
        return dispatch(doListIrelandH1Products(declarationId, params));
    };

    const listIrelandExportProducts = (declarationId: string, params?: Partial<PaginatedParams>) => {
        return dispatch(doListIrelandExportProducts(declarationId, params));
    };

    const listArrivalAtExitProducts = (declarationId: string, params?: Partial<PaginatedParams>) => {
        return dispatch(doListArrivalAtExitProducts(declarationId, params));
    };

    const getIrelandH1Product = (productId: string, declarationId: string) => {
        return dispatch(doGetIrelandH1Product(productId, declarationId));
    };

    const listIrelandH7Products = (declarationId: string, params?: Partial<PaginatedParams>) => {
        return dispatch(doListIrelandH7Products(declarationId, params));
    };

    const listIrelandEtdProducts = (declarationId: string, params?: Partial<PaginatedParams>) => {
        return dispatch(doListIrelandEtdProducts(declarationId));
    };

    const getIrelandH7Product = (productId: string, declarationId: string) => {
        return dispatch(doGetIrelandH7Product(productId, declarationId));
    };

    const getIrelandExportProduct = (productId: string, declarationId: string) => {
        return dispatch(doGetIrelandExportProduct(productId, declarationId));
    };

    const getArrivalAtExitProduct = (productId: string, declarationId: string) => {
        return dispatch(doGetArrivalAtExitProduct(productId, declarationId));
    };

    const listCdsProducts = (declarationId: string, params?: Partial<PaginatedParams>) => {
        return dispatch(doListCdsProducts(declarationId, params));
    };

    const getCdsProduct = (declarationId: string, productId: string) => {
        return dispatch(doGetCdsProduct(declarationId, productId));
    };

    const createCdsProduct = async (product: CdsGovernmentAgencyGoodsItem, declarationId: string) =>
        withResponseNotifications(await dispatch(doCreateCdsProduct(product, declarationId)), createProductNotifs(t));

    const updateCdsProduct = async (
        product: CdsExportGovernmentAgencyGoodsItemPayload,
        declarationId: string,
        productId: string,
        withNotification: boolean = true
    ) => {
        if (withNotification) {
            return withResponseNotifications(
                await dispatch(doUpdateCdsProduct(product, declarationId, productId)),
                saveProductNotifs(t)
            );
        } else {
            return await dispatch(doUpdateCdsProduct(product, declarationId, productId));
        }
    };

    const deleteCdsProduct = (productId: string, declarationId: string) => {
        return dispatch(doDeleteCdsProduct(productId, declarationId));
    };

    const updateIrelandEtdProduct = async (product: EtdGoodsShipmentItem, declarationId: string, productId: string) =>
        withResponseNotifications(
            await dispatch(doUpdateIrelandEtdProduct(product, declarationId, productId)),
            saveProductNotifs(t)
        );

    const getIrelandEtdProduct = (declarationId: string, productId: string) => {
        return dispatch(doGetIrelandEtdProduct(declarationId, productId));
    };

    const clearError = () => {
        return dispatch(doClearError());
    };

    const deleteDeclarationProduct = async ({
        productId,
        declarationId,
        formType,
        internalType,
        country,
    }: {
        productId: string;
        declarationId: string;
    } & DeclarationTypes) => {
        if (!(country && internalType && formType))
            throw new Error('No data provided for declaration products delete function.');

        let response;
        if (country === DeclarationCountry.UK) {
            response = await deleteCdsProduct(productId, declarationId);
        } else {
            if (internalType === DeclarationInternalType.EXPORT) {
                response = await deleteIrelandExportProduct(productId, declarationId);
            } else if (internalType === DeclarationInternalType.ARRIVAL) {
                response = await deleteArrivalAtExitProduct(productId, declarationId);
            } else if (internalType === DeclarationInternalType.TEMPORARY) {
                response = await deleteIrelandTsdProduct(productId, declarationId);
            } else if (internalType === DeclarationInternalType.NCTS) {
                response = await deleteIrelandNctsProduct(productId, declarationId);
            } else if (internalType === DeclarationInternalType.ETD) {
                response = await deleteIrelandEtdProduct(productId, declarationId);
            } else {
                if (formType === AISMessageTypes.H7) {
                    response = await deleteIrelandH7ImportProduct(productId, declarationId);
                } else if (formType === ENSMessageTypes.ENS) {
                    response = await deleteIrelandEnsProduct(productId, declarationId);
                } else {
                    response = await deleteIrelandImportProduct(productId, declarationId);
                }
            }
        }
        return withResponseNotifications(response, deleteDeclarationProductNotifs());
    };

    const createDeclarationProduct = async ({
        templateData,
        declarationId,
        formType,
        internalType,
        country,
    }: {
        templateData: TemplateData['defaults'];
        declarationId: string;
    } & DeclarationTypes) => {
        if (!(country && internalType && formType))
            throw new Error('No data provided for create declaration product function.');

        let response;
        if (country === DeclarationCountry.UK) {
            response = await createCdsProduct(templateData as CdsGovernmentAgencyGoodsItem, declarationId);
        } else {
            if (internalType === DeclarationInternalType.EXPORT) {
                response = await createIrelandExportProduct(templateData as IrelandExportItem, declarationId);
            } else if (internalType === DeclarationInternalType.ARRIVAL) {
                response = await createArrivalAtExitProduct(templateData as ArrivalAtExitProduct, declarationId);
            } else if (internalType === DeclarationInternalType.TEMPORARY) {
                response = await createIrelandTsdProduct(
                    templateData as IrelandTemporaryStorageDeclaration['goodsShipment']['goodsShipmentItem'][0],
                    declarationId
                );
            } else if (internalType === DeclarationInternalType.NCTS) {
                response = await createIrelandNctsProduct(templateData as any, declarationId);
            } else if (internalType === DeclarationInternalType.ETD) {
                response = await createIrelandEtdProduct(templateData, declarationId);
            } else {
                if (formType === AISMessageTypes.H7) {
                    response = await createIrelandH7ImportProduct(templateData, declarationId);
                } else if (formType === ENSMessageTypes.ENS) {
                    response = await createIrelandEnsProduct(templateData, declarationId);
                } else {
                    response = await createIrelandImportProduct(templateData, declarationId);
                }
            }
        }
        return response;
    };

    const getDeclarationProduct = async ({
        productId,
        declarationId,
        formType,
        internalType,
        country,
    }: {
        declarationId: string;
        productId: string;
    } & DeclarationTypes) => {
        if (!(country && internalType && formType))
            throw new Error('No data provided for get declaration product function.');

        let response;
        if (country === DeclarationCountry.UK) {
            response = await getCdsProduct(declarationId, productId);
        } else {
            if (internalType === DeclarationInternalType.EXPORT) {
                response = await getIrelandExportProduct(declarationId, productId);
            } else if (internalType === DeclarationInternalType.ARRIVAL) {
                response = await getArrivalAtExitProduct(declarationId, productId);
            } else if (internalType === DeclarationInternalType.TEMPORARY) {
                response = await getIrelandTsdProduct(declarationId, productId);
            } else if (internalType === DeclarationInternalType.NCTS) {
                response = await getIrelandNctsProduct(declarationId, productId);
            } else if (internalType === DeclarationInternalType.ETD) {
                response = await getIrelandEtdProduct(declarationId, productId);
            } else {
                if (formType === AISMessageTypes.H7) {
                    response = await getIrelandH7Product(declarationId, productId);
                } else if (formType === ENSMessageTypes.ENS) {
                    response = await getIrelandEnsProduct(declarationId, productId);
                } else {
                    response = await getIrelandH1Product(declarationId, productId);
                }
            }
        }
        return response;
    };

    const listDeclarationProducts = async ({
        declarationId,
        formType,
        internalType,
        country,
        params,
    }: {
        declarationId: string;
        params?: Partial<PaginatedParams>;
    } & DeclarationTypes) => {
        if (!(country && internalType && formType))
            throw new Error('No data provided for declaration product listing request.');

        let response;
        if (country === DeclarationCountry.UK) {
            response = await listCdsProducts(declarationId, params);
        } else {
            if (internalType === DeclarationInternalType.EXPORT) {
                response = await listIrelandExportProducts(declarationId, params);
            } else if (internalType === DeclarationInternalType.ARRIVAL) {
                response = await listArrivalAtExitProducts(declarationId, params);
            } else if (internalType === DeclarationInternalType.TEMPORARY) {
                response = await listIrelandTsdProducts(declarationId, params);
            } else if (internalType === DeclarationInternalType.NCTS) {
                response = await listIrelandNctsProducts(declarationId, params);
            } else if (internalType === DeclarationInternalType.ETD) {
                response = await listIrelandEtdProducts(declarationId, params);
            } else {
                if (formType === AISMessageTypes.H7) {
                    response = await listIrelandH7Products(declarationId, params);
                } else if (formType === ENSMessageTypes.ENS) {
                    response = await listIrelandEnsProducts(declarationId, params);
                } else {
                    response = await listIrelandH1Products(declarationId, params);
                }
            }
        }

        return response;
    };

    return {
        isLoading,
        error,
        products,
        product,
        createIrelandImportProduct,
        updateIrelandImportProduct,
        createIrelandH7ImportProduct,
        updateIrelandH7ImportProduct,
        createIrelandExportProduct,
        createArrivalAtExitProduct,
        updateIrelandExportProduct,
        updateArrivalAtExitProduct,
        createIrelandEnsProduct,
        updateIrelandEnsProduct,
        getIrelandEnsProduct,
        listIrelandH1Products,
        getIrelandH1Product,
        listIrelandH7Products,
        getIrelandH7Product,
        clearError,
        listIrelandExportProducts,
        listArrivalAtExitProducts,
        getIrelandExportProduct,
        getArrivalAtExitProduct,
        listCdsProducts,
        getCdsProduct,
        createIrelandEtdProduct,
        updateIrelandEtdProduct,
        listIrelandEtdProducts,
        getIrelandEtdProduct,
        listIrelandEnsProducts,
        createIrelandTsdProduct,
        createIrelandNctsProduct,
        updateIrelandTsdProduct,
        updateIrelandNctsProduct,
        getIrelandTsdProduct,
        listIrelandTsdProducts,
        listIrelandNctsProducts,
        createCdsProduct,
        updateCdsProduct,
        deleteDeclarationProduct,
        createDeclarationProduct,
        getDeclarationProduct,
        listDeclarationProducts,
    };
};

export default useProducts;
