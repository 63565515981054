import { Col, Row } from 'antd';
import Button from 'components/ui/base/button/Button';
import { H5 } from 'components/ui/base/typography';
import SelectTag from 'components/ui/composed/selectTag/SelectTag';
import { FormikProvider, useFormik } from 'formik';
import useProductsTemplates from 'hooks/useProductsTemplates';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { Tags } from 'views/declarations/Form.styles';
import { removeEmptyObjectsFromDeclarationArrays } from 'views/declarations/utils/form-utils';
import { StyledHeader, StyledLayout } from './Products.styles';
import { useTranslation } from 'react-i18next';
import useDeclarationNotifications from 'hooks/useDeclarationNotifications';
import { isEmpty } from 'lodash';
import useGlobalOverlay from '../../../hooks/useGlobalOverlay';
import useProductTemplateFormErrors from '../../../hooks/useProductTemplateFormErrors';
import DeclarationFormTabContent from '../../declarations/common/declaration-form/DeclarationFormTabContent';
import { DeclarationInternalType } from '../../../store/declarations/enums/common/declaration-internal-type';
import { productTypeMapHelpers, ProductTypeMapParams } from './productTemplateMap';
import { Box44Context } from '../../declarations/common/box44/Box44';
import { useRequestPromise } from 'hooks/useRequest';
import axiosClient from 'config/axios';
import config from 'config';
import { TemplateResponse } from 'store/template/template';
import validate, { FormModel, transformErrorsForFormik } from 'views/declarations/uk/export/validations/validations';
import { DeclarationContextProvider } from 'utils/DeclarationContext';
import DeclarationInformation from 'views/declarations/common/DeclarationInformation';
import { ProductTemplateContext } from 'utils/ProductTemplateContext';
import { TransformData } from '../../declarations/common/declaration-view/DeclarationView';
import useGetDeclarationMapValues from '../../../hooks/useGetDeclarationMapValues';
import { box44PathAndFieldNames } from '../../declarations/common/box44/box-44-utils';
import { MessageTypes } from '../../../store/declarations/enums/common/declaration-types';

const AddEditProductTemplate: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const { showErrorNotification } = useDeclarationNotifications();
    const { setFormProductTemplateErrors, clearFormProductTemplateErrors } = useProductTemplateFormErrors();
    const { id, type, country, internalType } = useParams<{
        id: string;
        type: MessageTypes;
        country: DeclarationCountry;
        internalType: DeclarationInternalType;
    }>();
    const { productTemplate, tagsList, saveProductTemplate, editProductTemplate, listProductTemplateTags } =
        useProductsTemplates({
            productId: id,
        });
    const declarationViewMapValues = useGetDeclarationMapValues();

    const transform = useMemo(
        () => declarationViewMapValues?.transformData as TransformData,
        [declarationViewMapValues]
    );

    const [searchParams] = useSearchParams();

    const declarationTemplateId = useMemo(
        () => searchParams.get('declarationTemplateId') ?? productTemplate?.declarationTemplateId,
        [productTemplate?.declarationTemplateId, searchParams]
    );

    const { data: declarationTemplate, refetch } = useRequestPromise(
        async () => {
            if (!declarationTemplateId) return undefined;
            return axiosClient
                .get<{ payload: TemplateResponse }>(`${config.declarationTemplatesUrl}/${declarationTemplateId}`)
                .then((res) => res.data.payload);
        },
        { loadingMessage: 'Getting declaration template data' }
    );

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declarationTemplateId]);

    const [initialValues, setInitialValues] = useState<any>({});
    const [suggestedTags, setSuggestedTags] = useState<string[]>(tagsList);
    const [usedTags, setUsedTags] = useState<string[]>([]);

    const typeMap = useMemo(() => {
        if (!country || !internalType) throw new Error('No country and internal type');

        const typeMap = productTypeMapHelpers[country][internalType];
        if (!typeMap) throw new Error('Fill the type map correctly');

        let final = typeMap.default;

        if (type && final) final = Object.assign(final, typeMap[type]);

        return final as ProductTypeMapParams;
    }, [country, internalType, type]);

    const formikInitialValues = useMemo(() => {
        const _productTemplateData = declarationTemplate?.template?.product.defaults;
        const productTemplateData =
            country === 'uk' ? _productTemplateData?.governmentAgencyGoodsItem : _productTemplateData;
        if (!id) return transform?.product?.forClient?.(productTemplateData, true) ?? {};

        return initialValues;
    }, [declarationTemplate?.template?.product.defaults, country, id, transform?.product, initialValues]);

    useEffect(() => {
        listProductTemplateTags();
        clearFormProductTemplateErrors();

        return () => clearFormProductTemplateErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (tagsList && id) {
            setSuggestedTags(Array.from(new Set([...tagsList, ...usedTags])));
        } else if (tagsList) {
            setSuggestedTags(tagsList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagsList, id]);

    useEffect(() => {
        if (productTemplate && id) {
            const initialValues = transform ? transform?.product?.forClient?.(productTemplate, true) : productTemplate;

            setInitialValues(initialValues);
            setUsedTags(productTemplate.tags ?? []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productTemplate, id, transform]);

    const formik = useFormik({
        initialValues: formikInitialValues,
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: typeMap.validationSchema,
        validate:
            typeMap.validate &&
            (async (values) => transformErrorsForFormik(await validate(new FormModel(values), typeMap.validate))),
        onSubmit: () => {},
    });

    const handleFormSubmit = useCallback(async () => {
        let formValues = removeEmptyObjectsFromDeclarationArrays({ ...formik.values });

        if (declarationTemplateId) formValues = { ...formValues, declarationTemplateId };

        let transformedForServer = transform ? transform?.product?.forServer?.(formValues, true) : formValues;

        if (country === DeclarationCountry.UK) transformedForServer = transformedForServer.governmentAgencyGoodsItem;

        const addOrEditParams: any = productTemplate.id
            ? { ...transformedForServer, id: productTemplate.id }
            : transformedForServer;

        const response = await (id ? editProductTemplate : saveProductTemplate)({ ...addOrEditParams, tags: usedTags });

        response && navigate(`/customs-declarations/${country}/${internalType}/products/${type}`);
    }, [
        formik.values,
        usedTags,
        navigate,
        country,
        internalType,
        type,
        transform,
        declarationTemplateId,
        productTemplate?.id,
        id,
        editProductTemplate,
        saveProductTemplate,
    ]);

    const handleValidateForm = useCallback(async () => {
        const errors = await formik.validateForm();

        if (isEmpty(errors)) {
            clearFormProductTemplateErrors();
            return true;
        }

        formik.setTouched(errors as any);
        if (typeMap.validationErrorsParser) setFormProductTemplateErrors(typeMap.validationErrorsParser(errors));
        return false;
    }, [formik, typeMap, clearFormProductTemplateErrors, setFormProductTemplateErrors]);

    const box44Values = useMemo(() => {
        if (!type)
            return { documentTypeName: 'emptyString', documentIdentifierName: 'emptyString', path: 'emptyString' };

        return {
            documentTypeName: box44PathAndFieldNames[type]?.documentTypeName,
            documentIdentifierName: box44PathAndFieldNames[type]?.documentIdentifierName,
            path: box44PathAndFieldNames[type]?.productPath,
        };
    }, [type]);

    return (
        <FormikProvider value={formik}>
            <ProductTemplateContext.Provider value={{ declarationTemplate, inProductTemplate: true }}>
                <StyledLayout style={{ height: '100%' }}>
                    <StyledHeader style={{ height: 'auto' }}>
                        <Box44Context.Provider value={{ ...box44Values }}>
                            <Row gutter={10} style={{ marginBottom: '2.4rem' }} justify="space-between" align="top">
                                <Col>
                                    <H5> {`${id ? 'Edit' : 'Add'} ${type} product`}</H5>
                                </Col>
                                <Col>
                                    <Button
                                        size="large"
                                        onClick={() => {
                                            navigate(
                                                `/customs-declarations/${country}/${internalType}/products/${type}`
                                            );
                                        }}
                                        style={{ marginRight: '1.6rem' }}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <Button
                                        size="large"
                                        onClick={async () => {
                                            showGlobalOverlay({
                                                type: 'LoadingOverlay',
                                            });

                                            const isValid = await handleValidateForm();

                                            if (!isValid) {
                                                showErrorNotification(
                                                    t('error.product_template_invalid_title'),
                                                    t('error.formRequired')
                                                );
                                                hideGlobalOverlay();
                                                return;
                                            }

                                            handleFormSubmit();

                                            hideGlobalOverlay();
                                        }}
                                    >
                                        {t('buttons.saveProduct')}
                                    </Button>
                                </Col>
                            </Row>
                            <Row gutter={10} style={{ marginBottom: '2.8rem' }} justify="space-between" align="middle">
                                <div style={{ marginRight: '2rem' }}>
                                    <Tags>
                                        <SelectTag
                                            usedTags={usedTags}
                                            suggestedTags={suggestedTags}
                                            onSuggestedTagsChange={(value: string[]) => setSuggestedTags(value)}
                                            onUsedTagsChange={(value: string[]) => setUsedTags(value)}
                                        />
                                    </Tags>
                                </div>
                                <DeclarationInformation
                                    options={{ visible: { template: true } }}
                                    hasBox44={
                                        country === DeclarationCountry.IRELAND &&
                                        (internalType === 'IMPORT' || internalType === 'ENS')
                                    }
                                />
                            </Row>
                        </Box44Context.Provider>
                    </StyledHeader>
                    <DeclarationContextProvider>
                        <DeclarationFormTabContent>
                            <typeMap.productSection formik={formik} productTemplate />
                        </DeclarationFormTabContent>
                    </DeclarationContextProvider>
                </StyledLayout>
            </ProductTemplateContext.Provider>
        </FormikProvider>
    );
};

export default AddEditProductTemplate;
