import { useCallback, useEffect } from 'react';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { useAppDispatch, useAppSelector } from '../config/hooks';
import {
    doDeleteH1ProductTemplate,
    doGetH1Product,
    doListH1Product,
    doListH1Tags,
    doListH7Tags,
    doSaveH1Product,
    doSaveCdsImportProduct,
    doUpdateH1Product,
    doUpdateH7Product,
    doUpdateCdsImportProduct,
    doSaveH7Product,
    doListH7Product,
    doGetH7Product,
    doDeleteH7ProductTemplate,
    doSaveCdsExportProduct,
    doListCdsExportProduct,
    doUpdateCdsExportProduct,
    doListCdsExportTags,
    doListEnsProducts,
    doSaveEnsProduct,
    doDeleteGeneralizedProductTemplate,
    doUpdateEnsProduct,
    doGetGeneralizedProductTemplate,
    doListEnsTags,
    doSaveAesProductTemplate,
    doListAesProductTemplates,
    doUpdateAesProduct,
    doListAesTags,
    doListCdsImportTags,
    doListCdsImportProduct,
    doListNctsProductTemplates,
    doSaveNctsProductTemplate,
    doUpdateNctsProduct,
    doListNctsTags,
} from '../store/products-templates/action';
import { GeneralizedProductTemplate, ProductTemplate } from '../store/products-templates/products';
import { useTranslation } from 'react-i18next';
import useResponseNotification from 'hooks/useResponseNotification';
import { createProductTemplatesNotifs, editProductTemplatesNotifs } from '../utils/notificationMessages';
import { ListProductTemplateParams } from 'store/products-templates/client';
import { AISMessageTypes, ENSMessageTypes } from '../store/declarations/enums/common/declaration-types';
import { DeclarationInternalType } from '../store/declarations/enums/common/declaration-internal-type';
import useFormUtils from './useFormUtils';

interface UseProductsTemplatesProps {
    productId?: string;
}

const useProductsTemplates = ({ productId }: UseProductsTemplatesProps) => {
    const isLoading = useAppSelector((state) => state.productsTemplate.isLoading);
    const error = useAppSelector((state) => state.productsTemplate.error);
    const productsTemplates = useAppSelector((state) => state.productsTemplate.products);
    const productTemplate = useAppSelector((state) =>
        productId ? state.productsTemplate.entities[productId] : ({} as ProductTemplate)
    );
    const tagsList = useAppSelector((state) => state.productsTemplate.tags);
    const { withResponseNotifications } = useResponseNotification();
    const { t } = useTranslation('common');
    const { countryLowerCase: country, internalType, formType: messageType } = useFormUtils();

    const dispatch = useAppDispatch();

    /** LIST PRODUCT TEMPLATES */
    const listH1ProductsTemplates = useCallback(
        (params?: Partial<ListProductTemplateParams>) => dispatch(doListH1Product(params)),
        [dispatch]
    );

    const listH7ProductsTemplates = useCallback(
        (params?: Partial<ListProductTemplateParams>) => dispatch(doListH7Product(params)),
        [dispatch]
    );

    const listCdsExportProductsTemplates = useCallback(
        (params: ListProductTemplateParams) => dispatch(doListCdsExportProduct(params)),
        [dispatch]
    );

    const listCdsImportProductTemplates = useCallback(
        (params: ListProductTemplateParams) => dispatch(doListCdsImportProduct(params)),
        [dispatch]
    );

    const listEnsProductsTemplates = useCallback(
        (params?: Partial<ListProductTemplateParams>) => dispatch(doListEnsProducts(params)),
        [dispatch]
    );

    const listAesProductsTemplates = useCallback(
        (params: ListProductTemplateParams) => dispatch(doListAesProductTemplates(params)),
        [dispatch]
    );

    const listNctsProductsTemplates = useCallback(
        (params: ListProductTemplateParams) => dispatch(doListNctsProductTemplates(params)),
        [dispatch]
    );

    /** CREATE PRODUCT TEMPLATES */

    const saveH1ProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doSaveH1Product(product)), createProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const saveH7ProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doSaveH7Product(product)), createProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const saveCdsExportProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doSaveCdsExportProduct(product)), createProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const saveCdsImportProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(dispatch(doSaveCdsImportProduct(product)), createProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const saveEnsProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doSaveEnsProduct(product)), createProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const saveAesProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(
                await dispatch(doSaveAesProductTemplate(product) as any),
                createProductTemplatesNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const saveNctsProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(
                await dispatch(doSaveNctsProductTemplate(product) as any),
                createProductTemplatesNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    /** EDIT PRODUCT TEMPLATES */

    const editH1ProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doUpdateH1Product(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const editH7ProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doUpdateH7Product(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const editCdsExportProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doUpdateCdsExportProduct(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const editCdsImportProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(dispatch(doUpdateCdsImportProduct(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const editEnsProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doUpdateEnsProduct(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const editAesProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doUpdateAesProduct(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    const editNctsProductTemplate = useCallback(
        async (product: ProductTemplate) =>
            withResponseNotifications(await dispatch(doUpdateNctsProduct(product)), editProductTemplatesNotifs(t)),
        [dispatch, withResponseNotifications, t]
    );

    /** GET PRODUCT TEMPLATES */

    const getH1ProductTemplate = useCallback((productId: string) => dispatch(doGetH1Product(productId)), [dispatch]);

    const getH7ProductTemplate = useCallback((productId: string) => dispatch(doGetH7Product(productId)), [dispatch]);

    const getGeneralizedProductTemplate = useCallback(
        (productId: string) => dispatch(doGetGeneralizedProductTemplate(productId)),
        [dispatch]
    );

    /** LIST TAGS */

    const listH1Tags = useCallback(() => dispatch(doListH1Tags()), [dispatch]);

    const listH7Tags = useCallback(() => dispatch(doListH7Tags()), [dispatch]);

    const listCdsExportTags = useCallback(
        (messageType: string) => dispatch(doListCdsExportTags(messageType)),
        [dispatch]
    );

    const listCdsImportTags = useCallback(
        (messageType: string) => dispatch(doListCdsImportTags(messageType)),
        [dispatch]
    );

    const listEnsTags = useCallback(() => dispatch(doListEnsTags()), [dispatch]);

    const listAesTags = useCallback((messageType: string) => dispatch(doListAesTags(messageType)), [dispatch]);

    const listNctsTags = useCallback((messageType: string) => dispatch(doListNctsTags(messageType)), [dispatch]);

    /** Generalized functions */
    const listProductTemplates = useCallback(
        (params?: Partial<ListProductTemplateParams>) => {
            if (!(country && internalType && messageType))
                throw new Error('No data provided for product templates listing function.');

            if (country === DeclarationCountry.UK) {
                if (internalType === DeclarationInternalType.EXPORT) {
                    listCdsExportProductsTemplates({ ...params, messageType });
                } else {
                    listCdsImportProductTemplates({ ...params, messageType });
                }
            } else {
                if (internalType === DeclarationInternalType.EXPORT) {
                    listAesProductsTemplates({ ...params, messageType });
                } else if (internalType === DeclarationInternalType.NCTS) {
                    listNctsProductsTemplates({ ...params, messageType });
                } else {
                    if (messageType === AISMessageTypes.H7) {
                        listH7ProductsTemplates(params);
                    } else if (messageType === ENSMessageTypes.ENS) {
                        listEnsProductsTemplates(params);
                    } else if (messageType === AISMessageTypes.H1) {
                        listH1ProductsTemplates(params);
                    }
                }
            }
        },
        [
            country,
            internalType,
            messageType,
            listH1ProductsTemplates,
            listH7ProductsTemplates,
            listCdsImportProductTemplates,
            listCdsExportProductsTemplates,
            listEnsProductsTemplates,
            listAesProductsTemplates,
            listNctsProductsTemplates,
        ]
    );

    const saveProductTemplate = useCallback(
        (params: ProductTemplate): Promise<ProductTemplate | GeneralizedProductTemplate> | undefined => {
            if (!(country && internalType && messageType))
                throw new Error('No data provided for save product template function.');
            let response;

            if (country === DeclarationCountry.UK) {
                if (internalType === DeclarationInternalType.EXPORT) {
                    response = saveCdsExportProductTemplate({ ...params, declarationName: messageType });
                } else {
                    response = saveCdsImportProductTemplate({ ...params, declarationName: messageType });
                }
            } else {
                if (internalType === DeclarationInternalType.EXPORT) {
                    response = saveAesProductTemplate({ ...params, declarationName: messageType });
                } else if (internalType === DeclarationInternalType.NCTS) {
                    response = saveNctsProductTemplate({ ...params, declarationName: messageType });
                } else {
                    if (messageType === AISMessageTypes.H7) {
                        response = saveH7ProductTemplate(params);
                    } else if (messageType === ENSMessageTypes.ENS) {
                        response = saveEnsProductTemplate(params);
                    } else if (messageType === AISMessageTypes.H1) {
                        response = saveH1ProductTemplate(params);
                    }
                }
            }

            return response;
        },
        [
            country,
            internalType,
            messageType,
            saveH1ProductTemplate,
            saveH7ProductTemplate,
            saveCdsImportProductTemplate,
            saveCdsExportProductTemplate,
            saveEnsProductTemplate,
            saveAesProductTemplate,
            saveNctsProductTemplate,
        ]
    );

    const editProductTemplate = useCallback(
        (params: ProductTemplate): Promise<ProductTemplate | GeneralizedProductTemplate> | undefined => {
            if (!(country && internalType && messageType))
                throw new Error('No data provided for edit product template function.');
            let response;

            if (country === DeclarationCountry.UK) {
                if (internalType === DeclarationInternalType.EXPORT) {
                    response = editCdsExportProductTemplate({ ...params, declarationName: messageType });
                } else {
                    response = editCdsImportProductTemplate({ ...params, declarationName: messageType });
                }
            } else {
                if (internalType === DeclarationInternalType.EXPORT) {
                    response = editAesProductTemplate({ ...params, declarationName: messageType });
                } else if (internalType === DeclarationInternalType.NCTS) {
                    response = editNctsProductTemplate({ ...params, declarationName: messageType });
                } else {
                    if (messageType === AISMessageTypes.H7) {
                        response = editH7ProductTemplate(params);
                    } else if (messageType === ENSMessageTypes.ENS) {
                        response = editEnsProductTemplate(params);
                    } else if (messageType === AISMessageTypes.H1) {
                        response = editH1ProductTemplate(params);
                    }
                }
            }

            return response;
        },
        [
            country,
            internalType,
            messageType,
            editH1ProductTemplate,
            editH7ProductTemplate,
            editAesProductTemplate,
            editCdsExportProductTemplate,
            editEnsProductTemplate,
            editCdsImportProductTemplate,
            editNctsProductTemplate,
        ]
    );

    const deleteProductTemplate = useCallback(
        (productId: string): Promise<any> | undefined => {
            if (!(productId && country && internalType && messageType))
                throw new Error('No data provided for delete product template function.');

            if (
                country === DeclarationCountry.UK ||
                (country === DeclarationCountry.IRELAND &&
                    (internalType === DeclarationInternalType.EXPORT ||
                        internalType === DeclarationInternalType.NCTS)) ||
                internalType === DeclarationInternalType.ENS
            )
                return dispatch(doDeleteGeneralizedProductTemplate(productId));
            else {
                if (messageType === AISMessageTypes.H7) {
                    return dispatch(doDeleteH7ProductTemplate(productId));
                } else if (messageType === AISMessageTypes.H1) {
                    return dispatch(doDeleteH1ProductTemplate(productId));
                }
            }
        },
        [internalType, country, dispatch, messageType]
    );

    const listProductTemplateTags = useCallback(() => {
        if (!(country && internalType && messageType))
            throw new Error('No data provided for product templates tags listing function.');

        if (country === DeclarationCountry.UK) {
            if (internalType === DeclarationInternalType.IMPORT) {
                listCdsImportTags(messageType);
            } else {
                listCdsExportTags(messageType);
            }
        } else {
            if (internalType === DeclarationInternalType.EXPORT) {
                listAesTags(messageType);
            } else if (internalType === DeclarationInternalType.NCTS) {
                listNctsTags(messageType);
            } else {
                if (messageType === AISMessageTypes.H7) {
                    listH7Tags();
                } else if (messageType === ENSMessageTypes.ENS) {
                    listEnsTags();
                } else if (messageType === AISMessageTypes.H1) {
                    listH1Tags();
                }
            }
        }
    }, [
        country,
        internalType,
        listAesTags,
        listCdsExportTags,
        listEnsTags,
        listH1Tags,
        listH7Tags,
        messageType,
        listCdsImportTags,
        listNctsTags,
    ]);

    useEffect(() => {
        if (!productId || productTemplate?.id === productId) return;
        if (
            country === DeclarationCountry.UK ||
            (country === DeclarationCountry.IRELAND &&
                (internalType === DeclarationInternalType.EXPORT || internalType === DeclarationInternalType.NCTS)) ||
            internalType === DeclarationInternalType.ENS
        ) {
            getGeneralizedProductTemplate(productId);
        } else {
            if (messageType === AISMessageTypes.H7) {
                getH7ProductTemplate(productId);
            } else if (messageType === AISMessageTypes.H1) {
                getH1ProductTemplate(productId);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, messageType, country, internalType]);

    return {
        isLoading,
        error,
        productsTemplates,
        productTemplate,
        tagsList,
        listProductTemplates,
        deleteProductTemplate,
        saveProductTemplate,
        editProductTemplate,
        listProductTemplateTags,
    };
};

export default useProductsTemplates;
