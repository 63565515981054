import Notification from 'components/ui/base/notification/Notification';
import { FormikErrors, FormikProps, FormikProvider, setNestedObjectValues } from 'formik';
import useDeclarationFooter from 'hooks/useDeclarationFooter';
import useDeclarations, { UpdateDeclarationFunction } from 'hooks/useDeclarations';
import useProducts from 'hooks/useProducts';
import { FC, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useMatch, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { DeclarationFooterType } from 'store/declaration-footer/declaration-footer';
import { Declaration, DeclarationHistory } from 'store/declarations/declaration';
import { IrelandExportDeclaration } from 'store/declarations/ireland/export-declaration';
import { IrelandH7ImportDeclaration } from 'store/declarations/ireland/h7-import-declaration';
import { IrelandImportDeclaration } from 'store/declarations/ireland/import-declaration';
import { StyledHeader, StyledLayout } from 'views/declarations/Form.styles';
import { isDeclarationAmendable } from 'views/declarations/utils/declaration-utils';
import {
    removeEmptyObjectsFromDeclarationArrays,
    removeEmptyObjects,
    trimWhiteSpaces,
    FormAction,
} from 'views/declarations/utils/form-utils';
import AmendmentFooter from './AmendmentFooter';
import AmendmentReasonModal from './AmendmentReasonModal';
import MasterProductDeclarationNav from '../../common/MasterProductDeclarationNav';
import { Box44Context } from '../../common/box44/Box44';
import DeclarationFormTabContent from 'views/declarations/common/declaration-form/DeclarationFormTabContent';
import { FooterRow, SubmitDiv } from 'views/declarations/footer/Footer.styles';
import { Button, notification } from 'antd';
import axiosClient from 'config/axios';
import { SuccessResponse } from 'core/http/response';
import config from 'config/config';
import styled from 'styled-components';
import CdsAmendmentModal, { AmendmentRecord } from './components/cds/CdsAmendmentModal';
import useRequest from 'hooks/useRequest';
import {
    prepareAesAmendment,
    prepareCdsAmendment,
    prepareGmrAmendment,
    submitCdsAmendmentRequest,
} from 'store/declarations/client';
import { isEmpty } from 'lodash';
import useGlobalOverlay from 'hooks/useGlobalOverlay';
import CdsExportDeclarationUtils from 'views/declarations/uk/export/utils';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import ValidationErrorContainer from 'views/declarations/common/ValidationErrorContainer/ValidationErrorContainer';
import useHandleErrors from 'views/declarations/common/declaration-view/utils/useHandleErrors';
import useDeclarationValidation from 'hooks/useDeclarationValidation';
import useHandleUnsavedChanges from 'views/declarations/common/declaration-view/utils/useHandleUnsavedChanges';
import useGetDeclarationMapValues from '../../../../hooks/useGetDeclarationMapValues';
import { box44PathAndFieldNames } from '../../common/box44/box-44-utils';
import useFormUtils from '../../../../hooks/useFormUtils';
import { FormSection } from '../../common/declaration-view/DeclarationView';
import InvalidateModalButton from '../../common/invalidate-modal/InvalidateModal';
import CancelDeclarationModalButton from '../../common/CancelDeclarationModal';
import PresentationNotificationModalButton from '../../common/PresentationNotificationModal';
import GeneralizedAmendmentModal from './GeneralizedAmendmentModal';
import ukGvmsRecordParser from '../../uk/gvms/validation/ukGvmsRecordParser';
import irelandExportValidationErrorsParser from '../../ireland/export/validation/IrelandExportValidationErrorsParser';

export const FooterButton = styled(Button)`
    padding-inline: 3rem;
    height: 4.5rem;
`;

type SubmitDeclaration = IrelandImportDeclaration | IrelandExportDeclaration | IrelandH7ImportDeclaration;

const ViewOnlyTab: FC<{}> = () => {
    const { declaration, productsFormik, formik } = useViewOnly() || {};
    const { declarationFormType, countryLowerCase: country, internalType, formType } = useFormUtils();
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const { declarationId } = useParams();
    const location = useLocation();
    const [selectedBt, setSelectedBt] = useState(
        location.pathname === `/declarations/${declarationId}/view-only`
            ? FormSection.MASTER_DETAILS
            : FormSection.PRODUCTS
    );

    const navigate = useNavigate();
    const { setDeclarationFooterType } = useDeclarationFooter();
    const products = useProducts();
    const { setFormDeclarationErrors, clearFormDeclarationErrors } = useDeclarationFormErrors();
    const declarationViewMapValues = useGetDeclarationMapValues();
    const [triedToSubmit, setTriedToSubmit] = useState(false);

    const [isAmendmentActive, setIsAmendmentActive] = useState<boolean>(false);

    const [isAmendmentReasonModalOpen, setIsAmendmentReasonModalOpen] = useState<boolean>(false);
    const [isCdsAmendmentModalOpen, setIsCdsAmendmentModalOpen] = useState<boolean>(false);
    const openCdsAmendmentModal = () => setIsCdsAmendmentModalOpen(true);
    const closeCdsAmendmentModal = () => setIsCdsAmendmentModalOpen(false);

    const [isGeneralizedAmendmentModalOpen, setIsGeneralizedAmendmentModalOpen] = useState<boolean>(false);
    const openGeneralizedAmendmentModal = () => setIsGeneralizedAmendmentModalOpen(true);
    const closeGeneralizedAmendmentModal = () => setIsGeneralizedAmendmentModalOpen(false);

    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const openErrorModal = () => setErrorModalOpen(true);
    const closeErrorModal = () => setErrorModalOpen(false);

    const declarations = useDeclarations();

    useEffect(() => {
        setSelectedBt(
            location.pathname === `/declarations/${declarationId}/view-only`
                ? FormSection.MASTER_DETAILS
                : FormSection.PRODUCTS
        );
    }, [location, declarationId]);

    useEffect(() => {
        if (
            !declarationId ||
            declarations?.declaration?.gvmsDeclaration ||
            declarations?.declaration?.preBoardingNotification
        )
            return;
        products.listDeclarationProducts({ declarationId, country, formType, internalType });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declarationId, declarations?.declaration?.gvmsDeclaration]);

    useEffect(() => {
        if (selectedBt === 0) {
            setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBt]);

    const inViewOnlyDeclarationProductsTable = useMatch('declarations/:declarationId/view-only/products');

    const viewOnly = useMemo(() => {
        if (inViewOnlyDeclarationProductsTable) {
            return true;
        }
        return !isAmendmentActive;
    }, [isAmendmentActive, inViewOnlyDeclarationProductsTable]);

    const handleSubmitForm = async (values: SubmitDeclaration) => {
        let form = removeEmptyObjects(
            removeEmptyObjectsFromDeclarationArrays(
                trimWhiteSpaces(values, {
                    emptyStringToNull: true,
                })
            )
        );
        if (declarationViewMapValues?.transformData?.declaration?.forServer !== undefined) {
            if (declarationViewMapValues?.payloadPath) {
                form = {
                    ...declaration?.[declarationViewMapValues?.declarationType],
                    [declarationViewMapValues?.payloadPath]: form,
                };
            }
            form = declarationViewMapValues.transformData.declaration.forServer(form);
        }
        const data = { ...declaration, [declarationViewMapValues?.declarationType as any]: form };
        if (!(declarations.declaration?.customerId! && declarationId)) return;
        showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Saving declaration...' } });
        return (declarations[declarationViewMapValues?.updateDeclarationFuncName] as UpdateDeclarationFunction)(
            declarations.declaration?.customerId,
            declarationId,
            data,
            false
        ).finally(() => {
            hideGlobalOverlay();
        });
    };

    const formikForProvider = useMemo(
        () => (!location.pathname.includes('products') ? formik : productsFormik),
        [formik, location.pathname, productsFormik]
    );

    const { data: amendments, doRequest: doPrepareCdsExportAmendment } = useRequest(prepareCdsAmendment);
    const { data: generalizedAmendmentData, doRequest: doPrepareGeneralizedAmendment } = useRequest(
        declaration?.gvmsDeclaration ? prepareGmrAmendment : prepareAesAmendment
    );

    const nonAmendmentFooter = useMemo(() => {
        if (isAmendmentActive) return null;

        const canSendArrivalNotification =
            declaration?.cdsDeclaration && ['REGISTERED', 'RISKED'].includes(declaration?.status);
        const sendArrivalNotification = () => {
            if (!canSendArrivalNotification) throw new Error('Not allowed to send arrival notification');

            axiosClient
                .post<SuccessResponse<any>>(
                    `${config.declarationsUrl}/uk/declarations/${declarationId}/arrivalNotification`
                )
                .then(() => {
                    notification.success({ message: 'Arrival Notification sent' });
                    declarations.getDeclaration(declarationId!);
                })
                .catch(() => {
                    notification.error({ message: 'Error sending Arrival Notification' });
                });
        };

        return (
            <SubmitDiv>
                <FooterRow justify="end" align="middle" padding="1.2rem 2.4rem 1.2rem 2.4rem">
                    <div style={{ display: 'grid', gridAutoFlow: 'column', gap: '1rem' }}>
                        {canSendArrivalNotification && (
                            <FooterButton onClick={sendArrivalNotification}>Send Arrival Notification</FooterButton>
                        )}

                        <PresentationNotificationModalButton
                            declaration={declaration}
                            declarationId={declarationId}
                            importPresentationNotificationRequest={declarations.importPresentationNotification}
                        />

                        <InvalidateModalButton declaration={declaration} />

                        {declaration && isDeclarationAmendable(declaration) && (
                            <FooterButton onClick={() => setIsAmendmentActive(true)}>Amend Declaration</FooterButton>
                        )}
                        <CancelDeclarationModalButton
                            declaration={declaration}
                            cancelCdsDeclarationRequest={declarations.cancelCdsDeclaration}
                        />
                    </div>
                </FooterRow>
            </SubmitDiv>
        );
    }, [isAmendmentActive, declaration, declarationId, declarations]);

    useEffect(() => {
        if (selectedBt === 0) {
            setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
        } else {
            setDeclarationFooterType(DeclarationFooterType.PRODUCTS);
        }
    }, [selectedBt, setDeclarationFooterType]);

    const [declarationSaved, setDeclarationSaved] = useState(false);

    useHandleErrors({
        formik,
        productsFormik,
        validationErrorsParser: declarationViewMapValues?.parseForm,
        triedToSubmit,
    });
    useHandleUnsavedChanges({
        declarationSaved,
        setDeclarationSaved,
        formik,
        productsFormik,
    });
    const { setFormAction } = useDeclarationValidation({
        formAction: null,
    });

    return (
        <>
            <StyledHeader>
                {declarationFormType && (
                    <FormikProvider value={formikForProvider}>
                        <Box44Context.Provider
                            value={{
                                documentTypeName: box44PathAndFieldNames[declarationFormType]?.documentTypeName,
                                documentIdentifierName:
                                    box44PathAndFieldNames[declarationFormType]?.documentIdentifierName,
                                path: !location.pathname.includes('products')
                                    ? box44PathAndFieldNames[declarationFormType]?.masterPath
                                    : box44PathAndFieldNames[declarationFormType]?.productPath,
                            }}
                        >
                            <MasterProductDeclarationNav
                                viewOnly
                                selectedBt={selectedBt}
                                amendment={isAmendmentActive}
                            />
                        </Box44Context.Provider>
                    </FormikProvider>
                )}
            </StyledHeader>
            <StyledLayout>
                <DeclarationFormTabContent>
                    <Outlet
                        context={{
                            formik,
                            viewOnly,
                            amendment: isAmendmentActive,
                            productsFormik,
                            onEdit: (id: string) => {
                                navigate(`/declarations/${declarationId}/view-only/products/${id}`);
                                setDeclarationFooterType(DeclarationFooterType.PRODUCTS);
                            },
                        }}
                    />
                </DeclarationFormTabContent>
            </StyledLayout>
            {!isAmendmentActive ? (
                <>{nonAmendmentFooter}</>
            ) : (
                <>
                    {declaration && isDeclarationAmendable(declaration) && (
                        <AmendmentFooter
                            cancelSaveProduct={() => {
                                navigate(`/declarations/${declarationId}/view-only/products`);
                                setDeclarationFooterType(DeclarationFooterType.MASTER_DETAILS);
                            }}
                            saveProduct={async () => {
                                showGlobalOverlay({
                                    type: 'LoadingOverlay',
                                    payload: { message: 'Saving product...' },
                                });
                                setTriedToSubmit(false);
                                setFormAction(FormAction.PRODUCT);
                            }}
                            cancelAmendment={() => {
                                setIsAmendmentActive(false);
                                declarations.cancelAmendment(declaration);
                            }}
                            saveDraft={async () => {
                                showGlobalOverlay({
                                    type: 'LoadingOverlay',
                                });
                                setTriedToSubmit(false);
                                setFormAction(FormAction.DRAFT);
                            }}
                            submitAmendment={async () => {
                                setTriedToSubmit(true);
                                showGlobalOverlay({
                                    type: 'LoadingOverlay',
                                    payload: { message: 'Saving declaration...' },
                                });
                                let data = { ...formik.values };
                                const response = await handleSubmitForm(data);

                                const errors = await formik.validateForm();
                                if (!isEmpty(errors)) {
                                    formik.setTouched(setNestedObjectValues(errors, true));
                                    if (declarationViewMapValues?.parseForm)
                                        setFormDeclarationErrors(declarationViewMapValues?.parseForm(errors));
                                    openErrorModal();
                                    hideGlobalOverlay();
                                    return;
                                } else {
                                    clearFormDeclarationErrors();
                                }

                                if (!response?.id) {
                                    console.error('No response on form submission');
                                    hideGlobalOverlay();
                                    return;
                                }

                                if (declaration?.cdsDeclaration) {
                                    showGlobalOverlay({
                                        type: 'LoadingOverlay',
                                        payload: { message: 'Preparing amendment...' },
                                    });
                                    const items = await doPrepareCdsExportAmendment(declarationId!);
                                    hideGlobalOverlay();
                                    if (isEmpty(items?.items)) {
                                        notification.warning({ message: 'No changes have been made.' });
                                        return;
                                    }
                                    openCdsAmendmentModal();
                                    return;
                                }

                                hideGlobalOverlay();

                                if (declaration?.irelandImportDeclaration || declaration?.entrySummaryDeclaration) {
                                    setIsAmendmentReasonModalOpen(true);

                                    return;
                                }

                                if (declaration?.ieExportDeclaration || declaration?.gvmsDeclaration) {
                                    showGlobalOverlay({
                                        type: 'LoadingOverlay',
                                        payload: { message: 'Preparing amendment...' },
                                    });
                                    const response = await doPrepareGeneralizedAmendment(declarationId!);
                                    hideGlobalOverlay();
                                    if (isEmpty(response)) {
                                        notification.warning({ message: 'No changes have been made.' });
                                        return;
                                    }

                                    openGeneralizedAmendmentModal();
                                    return;
                                }
                            }}
                        />
                    )}
                </>
            )}
            <AmendmentReasonModal
                open={isAmendmentReasonModalOpen}
                onCancel={() => {
                    setIsAmendmentReasonModalOpen(false);
                }}
                onSubmit={async ({ detailsAmended }) => {
                    if (!declarationId) return;
                    const createAmendment =
                        declaration?.irelandImportDeclaration || declaration?.irelandH7ImportDeclaration
                            ? declarations.createAmendmentIrelandImportDeclaration
                            : declaration?.entrySummaryDeclaration
                            ? declarations.createAmendmentIrelandEntrySummaryDeclaration
                            : undefined;

                    const response: DeclarationHistory | undefined = await createAmendment?.(
                        detailsAmended,
                        declarationId
                    );

                    if (response?.id) {
                        setIsAmendmentReasonModalOpen(false);

                        Notification({
                            type: 'success',
                            messageTitle: 'New Amendment',
                            description: 'A new amendment was successfully created.',
                        });
                    }
                }}
            />
            <CdsAmendmentModal
                amendments={amendments?.items}
                visible={isCdsAmendmentModalOpen}
                onCancel={closeCdsAmendmentModal}
                submitAmendmentRequest={async (value: AmendmentRecord[]) => {
                    if (!declarationId || !declaration?.mrn || !declaration?.cdsDeclaration?.lrn) {
                        notification.error({ message: 'Missing information for submitting amendment request' });
                        return Promise.reject();
                    }

                    showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Submitting amendment...' } });
                    return submitCdsAmendmentRequest(declarationId!, {
                        items: value,
                        declarationId: declarationId,
                        lrn: declaration.cdsDeclaration?.lrn,
                        mrn: declaration.mrn,
                    })
                        .then(() => {
                            setIsAmendmentActive(false);
                            notification.success({ message: 'Amendment submitted' });
                        })
                        .catch(() => {
                            notification.error({ message: 'Could not submit amendment' });
                        });
                }}
                onChangesReverted={async () => {
                    if (!declarationId) return true;
                    showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Validating declaration...' } });
                    const declaration = await declarations.getDeclaration(declarationId);
                    const errors = (await formik.validateForm(
                        CdsExportDeclarationUtils.transformForClient(declaration.cdsDeclaration).cdsDeclarationPayload
                    )) as FormikErrors<any>;
                    if (!isEmpty(errors)) {
                        formik.setTouched(setNestedObjectValues(errors, true));
                        if (declarationViewMapValues?.parseForm)
                            setFormDeclarationErrors(declarationViewMapValues?.parseForm(errors));
                        openErrorModal();
                        closeCdsAmendmentModal();
                        return true;
                    }
                    clearFormDeclarationErrors();
                    return false;
                }}
            />
            <GeneralizedAmendmentModal
                amendments={generalizedAmendmentData}
                visible={isGeneralizedAmendmentModalOpen}
                onCancel={closeGeneralizedAmendmentModal}
                fieldNameParams={{
                    productPath: declaration?.gvmsDeclaration ? null : 'goodsShipment.goodsItem',
                    formParser: declaration?.gvmsDeclaration ? ukGvmsRecordParser : irelandExportValidationErrorsParser,
                }}
                submitAmendmentRequest={async () => {
                    if (!(declaration && declaration?.id)) {
                        notification.error({ message: 'Missing information for submitting amendment request' });
                        return Promise.reject();
                    }

                    showGlobalOverlay({ type: 'LoadingOverlay', payload: { message: 'Submitting amendment...' } });

                    await declarations
                        .createAmendment(declaration, declaration.id)
                        ?.then(() => {
                            setIsAmendmentActive(false);
                            notification.success({ message: 'Amendment submitted' });
                        })
                        .catch(() => {
                            notification.error({ message: 'Could not submit amendment' });
                        })
                        .finally(() => closeGeneralizedAmendmentModal());
                }}
            />
            <ValidationErrorContainer isOpen={errorModalOpen} close={closeErrorModal} />
        </>
    );
};
export default ViewOnlyTab;

function useViewOnly() {
    return useOutletContext<{
        formik: FormikProps<any>;
        productsFormik: FormikProps<any>;
        declaration: Declaration | undefined;
        activeTabKey: string;
    }>();
}
