import { TemplateResponse } from 'components/ui/composed/template/TemplateContext';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { irelandExportMessageTypeLabels } from 'store/declarations/enums/ireland/message-type';
import { irelandDeclarationNameLabels } from 'store/declarations/ireland/ireland-declaration-name';
import { ukDeclarationNameLabels, ukExportDeclarationNameLabels } from 'store/declarations/uk/uk-declaration-name';
import {
    AESMessageTypes,
    AISMessageTypes,
    ETDMessageTypes,
    MessageTypes,
} from '../../store/declarations/enums/common/declaration-types';
import { nctsNameLabels } from '../../store/declarations/ireland/ncts-declaration';
import { tsdNameLabels } from '../../store/declarations/ireland/temporary-storage-declaration';
import { gvmsNameLabels } from '../../store/declarations/uk/gvms-declaration';

export const getDeclarationNameLabels = (
    country: DeclarationCountry | undefined,
    type: DeclarationInternalType | undefined
) => {
    if (!country || !type) return [];

    if (country === DeclarationCountry.UK) {
        if (type === DeclarationInternalType.IMPORT) {
            return [...ukDeclarationNameLabels];
        } else if (type === DeclarationInternalType.EXPORT) {
            return [...ukExportDeclarationNameLabels];
        } else if (type === DeclarationInternalType.GVMS) {
            return gvmsNameLabels;
        }
    } else {
        if (type === DeclarationInternalType.IMPORT) {
            return [...irelandDeclarationNameLabels];
        } else if (type === DeclarationInternalType.EXPORT) {
            return [...irelandExportMessageTypeLabels];
        } else if (type === DeclarationInternalType.TEMPORARY) {
            return tsdNameLabels;
        } else if (type === DeclarationInternalType.NCTS) {
            return nctsNameLabels;
        }
    }

    return [];
};

export const createOrDuplicateDeclarations = async (
    updateDeclaration: Function,
    createDeclaration: Function,
    jobId: string,
    setMyDeclaration: Function,
    country: string,
    customerId?: string,
    duplicateDeclarationId?: string,
    messageType?: MessageTypes,
    selectedDeclaration?: { id: string; mrn: string }
) => {
    if (!customerId) return;

    if (duplicateDeclarationId) {
        const response = (await updateDeclaration(duplicateDeclarationId, customerId, jobId)) as Declaration;
        if (response) {
            setMyDeclaration(response);
            return response;
        }
    } else {
        let response = {};
        if (messageType) {
            response = (await createDeclaration(country, customerId, jobId, messageType)) as Declaration;
        } else if (selectedDeclaration) {
            response = (await createDeclaration(
                country,
                customerId,
                jobId,
                undefined,
                selectedDeclaration
            )) as Declaration;
        } else {
            response = (await createDeclaration(country, customerId, jobId)) as Declaration;
        }
        if (response) {
            setMyDeclaration(response);
            return response;
        }
    }
};

export const createDeclarations = async (
    country: DeclarationCountry,
    customerId: string,
    jobId: string,
    type: DeclarationInternalType,
    createIrelandH7ImportDeclaration: Function,
    createIrelandImportDeclaration: Function,
    createEntrySummaryDeclaration: Function,
    createTemporaryStorageDeclaration: Function,
    createIrelandExportDeclaration: Function,
    createElectronicTransportDocument: Function,
    createUkExportDeclaration: Function,
    createUkImportNewDeclaration: Function,
    createArrivalAtExitDeclaration: Function,
    createGvmsRecord: Function,
    createPbnRecord: Function,
    createNctsDeclaration: Function,
    messageType?: MessageTypes,
    template?: TemplateResponse,
    selectedDeclaration?: { id: string; mrn: string }
) => {
    if (country === DeclarationCountry.IRELAND) {
        if (type === DeclarationInternalType.IMPORT) {
            if (messageType === AISMessageTypes.H7) {
                return await createIrelandH7ImportDeclaration(
                    customerId,
                    template?.template?.master.defaults,
                    jobId,
                    template?.id
                );
            } else {
                return await createIrelandImportDeclaration(
                    customerId,
                    { ...template?.template?.master.defaults, messageType: messageType as AISMessageTypes },
                    jobId,
                    template?.id
                );
            }
        } else if (type === DeclarationInternalType.ENS) {
            return await createEntrySummaryDeclaration(
                customerId,
                template?.template?.master.defaults,
                jobId,
                template?.id
            );
        } else if (type === DeclarationInternalType.ETD) {
            return await createElectronicTransportDocument(
                customerId,
                { declaration: { messageType: ETDMessageTypes.D3 } },
                jobId
            );
        } else if (type === DeclarationInternalType.TEMPORARY) {
            return await createTemporaryStorageDeclaration(
                customerId,
                { declaration: { msgType: messageType } },
                jobId
            );
        } else if (type === DeclarationInternalType.ARRIVAL) {
            return await createArrivalAtExitDeclaration(customerId, selectedDeclaration, jobId);
        } else if (type === DeclarationInternalType.EXPORT) {
            return await createIrelandExportDeclaration(
                customerId,
                { ...template?.template?.master.defaults, messageType: messageType as AESMessageTypes },
                jobId,
                template?.id
            );
        } else if (type === DeclarationInternalType.PBN) {
            return await createPbnRecord(
                customerId,
                /** Mandatory fields to create a record, will be added in BE. */
                {
                    direction: 'OUT_IRELAND',
                    emptyVehicle: true,
                },
                jobId
            );
        } else if (type === DeclarationInternalType.NCTS) {
            return await createNctsDeclaration(
                customerId,
                /** Workaround, create empty houseConsignment, so that a product can be created with its id. */
                { messageType, consignment: { houseConsignment: [{}] } },
                jobId
            );
        }
    } else if (country === DeclarationCountry.UK) {
        if (type === DeclarationInternalType.IMPORT) {
            return await createUkImportNewDeclaration(
                customerId,
                { cdsDeclarationPayload: template?.template?.master.defaults, messageType },
                jobId,
                template?.id,
                template?.template
            );
        } else if (type === DeclarationInternalType.EXPORT) {
            return await createUkExportDeclaration(
                customerId,
                { cdsDeclarationPayload: template?.template?.master.defaults, messageType },
                jobId,
                template?.id,
                template?.template
            );
        } else if (type === DeclarationInternalType.GVMS) {
            return await createGvmsRecord(customerId, { goodsMovementRecord: {}, messageType }, jobId);
        }
    }
};
