import { useCallback } from 'react';
import { doGetIrelandCodelists, doGetUkCodelists } from 'store/codelists/actions';
import { useAppDispatch, useAppSelector } from '../config/hooks';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationTypes } from 'store/declarations/enums/common/declaration-types';
const useCodelists = () => {
    const dispatch = useAppDispatch();

    const irelandCodelists = useAppSelector((state) => state.codelists.irelandCodelists);
    const ukCodelists = useAppSelector((state) => state.codelists.ukCodelists);

    const aisCodelists = irelandCodelists?.ais;
    const cdsCodelists = ukCodelists?.cds;
    const aesCodelists = irelandCodelists?.aes;
    const nctsCodelists = irelandCodelists?.ncts;

    const getIrelandCodelists = useCallback(
        (type: DeclarationTypes | undefined) => dispatch(doGetIrelandCodelists(type)),
        [dispatch]
    );
    const getUkCodelists = useCallback(
        (type: DeclarationTypes | undefined) => dispatch(doGetUkCodelists(type)),
        [dispatch]
    );

    const getCodeLists = useCallback(
        async (country: string, type: DeclarationTypes | undefined) => {
            if (!type) return [];

            let codelists: any = {};
            let getFunction = country === DeclarationCountry.IRELAND ? getIrelandCodelists : getUkCodelists;

            if (country === DeclarationCountry.IRELAND) {
                codelists = irelandCodelists;
                getFunction = getIrelandCodelists;
            } else {
                codelists = ukCodelists;
                getFunction = getUkCodelists;
            }

            const cachedCodelists = codelists?.[type.toLowerCase()];

            if (cachedCodelists) {
                return;
            }
            try {
                await getFunction(type);
            } catch (error) {
                console.error(`Failed to fetch codelists for ${country} with type ${type}:`, error);
            }
        },
        [getIrelandCodelists, getUkCodelists, irelandCodelists, ukCodelists]
    );

    return {
        nctsCodelists,
        aisCodelists,
        cdsCodelists,
        getIrelandCodelists,
        getUkCodelists,
        aesCodelists,
        getCodeLists,
    };
};

export default useCodelists;
