import { defaultPagination } from 'core/http/pagination';
import { useCallback } from 'react';
import { Declaration, NotifyEmails } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import {
    ElectronicTransportDocument,
    ElectronicTransportDocumentPayload,
} from 'store/declarations/ireland/electronic-transport-document';
import {
    IrelandTemporaryStorageDeclaration,
    IrelandTemporaryStorageDeclarationPayload,
} from 'store/declarations/ireland/temporary-storage-declaration';
import {
    IrelandEntrySummaryDeclaration,
    IrelandEntrySummaryDeclarationPayload,
} from 'store/declarations/ireland/entry-summary-declaration';
import {
    IrelandExportDeclaration,
    IrelandExportDeclarationPayload,
} from 'store/declarations/ireland/export-declaration';
import {
    IrelandH7ImportDeclaration,
    IrelandH7ImportDeclarationPayload,
} from 'store/declarations/ireland/h7-import-declaration';
import {
    IrelandImportDeclaration,
    IrelandImportDeclarationPayload,
} from 'store/declarations/ireland/import-declaration';
import { CdsDeclaration, CdsDeclarationPayload } from 'store/declarations/uk/cds-declaration';
import { useAppDispatch, useAppSelector } from '../config/hooks';
import {
    doCreateAmendmentIrelandEntrySummaryDeclaration,
    doCreateAmendmentIrelandExportDeclaration,
    doCreateAmendmentIrelandImportDeclaration,
    doCreateArrivalAtExitDeclaration,
    doCreateElectronicTransportDocument,
    doCreateEntrySummaryDeclaration,
    doCreateTemporaryStorageDeclaration,
    doCreateIrelandExportDeclaration,
    doCreateIrelandH7ImportDeclaration,
    doCreateIrelandImportDeclaration,
    doCreateCdsDeclaration,
    doDuplicateDeclaration,
    doGetDeclaration,
    doGetDeclarationDocuments,
    doGetDeclarationHistory,
    doInvalidateDeclaration,
    doInvalidateExportDeclaration,
    doInvalidateNctsDeclaration,
    doSubmitDeclaration,
    doSubmitIrelandImportDocuments,
    doSubmitImportPresentationNotification,
    doUpdateArrivalAtExitDeclaration,
    doToggleCoreTemplateStatus,
    doUpdateElectronicTransportDocument,
    doUpdateEntrySummaryDeclaration,
    doUpdateIrelandExportDeclaration,
    doUpdateIrelandH7ImportDeclaration,
    doUpdateIrelandImportDeclaration,
    doUpdateTemporaryStorageDeclaration,
    doCreateIrelandH1ImportDeclarationWithFile,
    doGetDeclarationTemplate,
    doCancelCdsDeclaration,
    doCancelAmendmentIrelandExportDeclaration,
    doCreateUkGvmsRecord,
    doUpdateUkGvmsRecord,
    doUpdateCdsDeclaration,
    doCancelAmendmentGmrRecord,
    doCreateAmendmentGmrRecord,
    doCreateIePbnRecord,
    doUpdateIePbnRecord,
    doCreateNctsDeclaration,
    doUpdateNctsDeclaration,
} from '../store/declarations/actions';
import useResponseNotification from 'hooks/useResponseNotification';
import { useTranslation } from 'react-i18next';
import {
    cancelAmendmentNotifs,
    removeDeclarationAsCoreTemplateNotifs,
    setDeclarationAsCoreTemplateNotifs,
    submitDeclarationNotifs,
    updateDeclarationNotifs,
} from '../utils/notificationMessages';
import { UkGvmsRecord } from '../store/declarations/uk/gvms-declaration';
import { ArrivalAtExitDeclarationPayload } from '../store/declarations/ireland/arrival-at-exit-declaration';
import { AESMessageTypes } from '../store/declarations/enums/common/declaration-types';
import { IrelandPbnRecord } from '../store/declarations/ireland/pbn-declaration';
import { NctsDeclaration } from '../store/declarations/ireland/ncts-declaration';
import { calculateGrossMass } from 'views/declarations/common/declaration-view/utils/grossMassUtils';

export type UpdateDeclarationFunction = (
    customerId: string,
    declarationId: string,
    declaration: any,
    withNotification?: boolean
) => Promise<any>;

const useDeclarations = () => {
    const declaration = useAppSelector((state) => state.declarations.declaration);
    const declarationTemplate = useAppSelector((state) => state.declarations.declarationTemplate);
    const isLoading = useAppSelector((state) => state.declarations.isLoading);
    const error = useAppSelector((state) => state.declarations.error);
    const documents = useAppSelector((state) => state.declarations.documents);
    const declarationHistory = useAppSelector((state) => state.declarations.declarationHistory);
    const { withResponseNotifications } = useResponseNotification();
    const { t } = useTranslation(['common', 'customs_declarations']);

    const dispatch = useAppDispatch();

    const getDeclaration = useCallback(
        (declarationId: string) => {
            return dispatch(doGetDeclaration(declarationId));
        },
        [dispatch]
    );
    const getDeclarationTemplate = useCallback(
        (templateId?: string) => {
            return dispatch(doGetDeclarationTemplate(templateId ?? declaration?.templateId));
        },
        [declaration?.templateId, dispatch]
    );

    const createIrelandImportDeclaration = useCallback(
        (
            customerId: string,
            irelandImportDeclaration?: IrelandImportDeclaration,
            jobId?: string,
            templateId?: string
        ) =>
            dispatch(
                doCreateIrelandImportDeclaration(
                    customerId,
                    {
                        jobId,
                        declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                        declarationInternalType: DeclarationInternalType.IMPORT,
                        irelandImportDeclaration: irelandImportDeclaration,
                    },
                    templateId
                )
            ),
        [dispatch]
    );

    const createIrelandH1ImportDeclarationWithFile = useCallback(
        (file: File, jobId: string, customerId: string): Promise<IrelandImportDeclarationPayload> | undefined =>
            dispatch(doCreateIrelandH1ImportDeclarationWithFile(file, jobId, customerId)),
        [dispatch]
    );

    const createIrelandH7ImportDeclaration = useCallback(
        (
            customerId: string,
            irelandH7ImportDeclaration?: IrelandH7ImportDeclaration,
            jobId?: string,
            templateId?: string
        ) =>
            dispatch(
                doCreateIrelandH7ImportDeclaration(
                    customerId,
                    {
                        jobId,
                        declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                        declarationInternalType: DeclarationInternalType.IMPORT,
                        irelandH7ImportDeclaration: irelandH7ImportDeclaration,
                    },
                    templateId
                )
            ),
        [dispatch]
    );

    const createIrelandExportDeclaration = useCallback(
        (customerId: string, ieExportDeclaration?: IrelandExportDeclaration, jobId?: string, templateId?: string) =>
            dispatch(
                doCreateIrelandExportDeclaration(
                    customerId,
                    {
                        jobId,
                        declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                        declarationInternalType: DeclarationInternalType.EXPORT,
                        ieExportDeclaration,
                    },
                    templateId
                )
            ),
        [dispatch]
    );

    const createEntrySummaryDeclaration = useCallback(
        (customerId: string, declaration?: IrelandEntrySummaryDeclaration, jobId?: string, templateId?: string) =>
            dispatch(
                doCreateEntrySummaryDeclaration(
                    customerId,
                    {
                        jobId,
                        declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                        declarationInternalType: DeclarationInternalType.ENS,
                        entrySummaryDeclaration: declaration,
                    },
                    templateId
                )
            ),
        [dispatch]
    );

    const createTemporaryStorageDeclaration = useCallback(
        (
            customerId: string,
            ieImportTemporaryStorageDeclaration?: IrelandTemporaryStorageDeclaration,
            jobId?: string
        ) =>
            dispatch(
                doCreateTemporaryStorageDeclaration(customerId, {
                    jobId,
                    declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                    declarationInternalType: DeclarationInternalType.TEMPORARY,
                    ieImportTemporaryStorageDeclaration,
                })
            ),
        [dispatch]
    );

    const createNctsDeclaration = useCallback(
        (customerId: string, ieNctsDeclaration?: NctsDeclaration, jobId?: string) =>
            dispatch(
                doCreateNctsDeclaration(customerId, {
                    jobId,
                    declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                    declarationInternalType: DeclarationInternalType.NCTS,
                    ieNctsDeclaration,
                })
            ),
        [dispatch]
    );

    const updateTemporaryStorageDeclaration = useCallback(
        async (customerId: string, declarationId: string, declaration: IrelandTemporaryStorageDeclarationPayload) =>
            withResponseNotifications(
                await dispatch(doUpdateTemporaryStorageDeclaration(customerId, declarationId, declaration)),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const updateNctsDeclaration = useCallback(
        async (customerId: string, declarationId: string, declaration: NctsDeclaration) =>
            withResponseNotifications(
                await dispatch(doUpdateNctsDeclaration(customerId, declarationId, declaration)),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const createUkImportNewDeclaration = useCallback(
        (customerId: string, cdsDeclaration?: CdsDeclaration, jobId?: string, templateId?: string) =>
            dispatch(
                doCreateCdsDeclaration(
                    customerId,
                    {
                        jobId,
                        declarationExternalEntity: DeclarationExternalEntity.CDS,
                        declarationInternalType: DeclarationInternalType.IMPORT_NEW,
                        cdsDeclaration,
                    },
                    templateId
                )
            ),
        [dispatch]
    );

    const createUkExportDeclaration = useCallback(
        (customerId: string, cdsDeclaration?: CdsDeclaration, jobId?: string, templateId?: string) =>
            dispatch(
                doCreateCdsDeclaration(
                    customerId,
                    {
                        jobId,
                        declarationExternalEntity: DeclarationExternalEntity.CDS,
                        declarationInternalType: DeclarationInternalType.EXPORT,
                        cdsDeclaration,
                    },
                    templateId
                )
            ),
        [dispatch]
    );

    const createGvmsRecord = useCallback(
        (customerId: string, gvmsDeclaration?: UkGvmsRecord, jobId?: string) =>
            dispatch(
                doCreateUkGvmsRecord(customerId, {
                    jobId,
                    declarationExternalEntity: DeclarationExternalEntity.GVMS,
                    declarationInternalType: DeclarationInternalType.GVMS,
                    gvmsDeclaration,
                })
            ),
        [dispatch]
    );

    const createPbnRecord = useCallback(
        (customerId: string, preBoardingNotification?: IrelandPbnRecord, jobId?: string) =>
            dispatch(
                doCreateIePbnRecord(customerId, {
                    jobId,
                    declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                    declarationInternalType: DeclarationInternalType.PBN,
                    preBoardingNotification,
                })
            ),
        [dispatch]
    );

    const updateIrelandImportDeclaration = useCallback(
        async (
            customerId: string,
            declarationId: string,
            declaration: IrelandImportDeclarationPayload,
            withNotification: boolean = true
        ) => {
            if (withNotification) {
                return withResponseNotifications(
                    await dispatch(doUpdateIrelandImportDeclaration(customerId, declarationId, declaration)),
                    updateDeclarationNotifs(t)
                );
            } else {
                return await dispatch(
                    doUpdateIrelandImportDeclaration(customerId, declarationId, declaration, calculateGrossMass)
                );
            }
        },

        [dispatch, withResponseNotifications, t]
    );

    const updateIrelandH7ImportDeclaration = useCallback(
        async (
            customerId: string,
            declarationId: string,
            declaration: IrelandH7ImportDeclarationPayload,
            _: boolean = true,
            calculateGrossMass: boolean = false
        ) =>
            withResponseNotifications(
                await dispatch(
                    doUpdateIrelandH7ImportDeclaration(customerId, declarationId, declaration, calculateGrossMass)
                ),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const updateIrelandExportDeclaration = useCallback(
        async (customerId: string, declarationId: string, declaration: IrelandExportDeclarationPayload) =>
            withResponseNotifications(
                await dispatch(doUpdateIrelandExportDeclaration(customerId, declarationId, declaration)),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const updateCdsDeclaration = useCallback(
        async (
            customerId: string,
            declarationId: string,
            declaration: CdsDeclarationPayload,
            withNotification: boolean = true
        ) => {
            if (withNotification) {
                return withResponseNotifications(
                    await dispatch(doUpdateCdsDeclaration(customerId, declarationId, declaration)),
                    updateDeclarationNotifs(t)
                );
            } else {
                return await dispatch(doUpdateCdsDeclaration(customerId, declarationId, declaration));
            }
        },

        [dispatch, t, withResponseNotifications]
    );

    const updateGvmsRecord = useCallback(
        async (customerId: string, declarationId: string, gvmsRecord: UkGvmsRecord) =>
            withResponseNotifications(
                await dispatch(doUpdateUkGvmsRecord(customerId, declarationId, gvmsRecord)),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const updatePbnRecord = useCallback(
        async (customerId: string, declarationId: string, pbnRecord: IrelandPbnRecord) =>
            withResponseNotifications(
                await dispatch(doUpdateIePbnRecord(customerId, declarationId, pbnRecord)),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const submitDeclaration = useCallback(
        async (declarationId: string, emails: NotifyEmails, applySuggestedGrossMass?: boolean) =>
            withResponseNotifications(
                await dispatch(doSubmitDeclaration(declarationId, emails, applySuggestedGrossMass)),
                submitDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const listDeclarationDocuments = useCallback(
        (declarationId: string | undefined) =>
            dispatch(
                doGetDeclarationDocuments({
                    ...defaultPagination,
                    size: 1000,
                    declarationId,
                })
            ),
        [dispatch]
    );

    const toggleCoreTemplateStatus = useCallback(
        async (declarationId: string, options: { notificationType: 'setAsCoreTemplate' | 'removeAsCoreTemplate' }) => {
            const notification =
                options.notificationType === 'setAsCoreTemplate'
                    ? setDeclarationAsCoreTemplateNotifs
                    : removeDeclarationAsCoreTemplateNotifs;

            return withResponseNotifications(
                await dispatch(doToggleCoreTemplateStatus(declarationId)),
                notification(t)
            );
        },
        [dispatch, withResponseNotifications, t]
    );

    const updateEntrySummaryDeclaration = useCallback(
        async (customerId: any, declarationId: string, declaration: IrelandEntrySummaryDeclarationPayload) =>
            withResponseNotifications(
                await dispatch(doUpdateEntrySummaryDeclaration(declarationId, declaration)),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const duplicateDeclaration = useCallback(
        (declarationId: string, customerId: string, jobId: string) =>
            dispatch(doDuplicateDeclaration(declarationId, customerId, jobId)),
        [dispatch]
    );

    const invalidateDeclaration = useCallback(
        (declarationId: string, reason: string, declarationType: DeclarationInternalType) => {
            if (declarationType === DeclarationInternalType.IMPORT) {
                return dispatch(doInvalidateDeclaration(declarationId, reason));
            } else if (declarationType === DeclarationInternalType.EXPORT) {
                return dispatch(doInvalidateExportDeclaration(declarationId, reason));
            }
        },
        [dispatch]
    );

    const invalidateNctsDeclaration = useCallback(
        (declarationId: string, decision: boolean, justification: string, initiatedByCustoms: boolean) => {
            return dispatch(doInvalidateNctsDeclaration(declarationId, decision, justification, initiatedByCustoms));
        },
        [dispatch]
    );

    const importPresentationNotification = useCallback(
        async (declarationId: string, type?: string, body?: {}) => {
            return dispatch(doSubmitImportPresentationNotification(declarationId, type, body));
        },
        [dispatch]
    );

    const submitIrelandImportDocuments = useCallback(
        async (declarationId: string, documentIds: string[]) => {
            return dispatch(doSubmitIrelandImportDocuments(declarationId, documentIds));
        },
        [dispatch]
    );

    const createAmendmentIrelandImportDeclaration = useCallback(
        (reason: string, declarationId: string) => {
            return dispatch(doCreateAmendmentIrelandImportDeclaration(declarationId, reason));
        },
        [dispatch]
    );

    const createAmendmentIrelandExportDeclaration = useCallback(
        (declarationId: string) => {
            return dispatch(doCreateAmendmentIrelandExportDeclaration(declarationId));
        },
        [dispatch]
    );

    const createAmendmentGmrRecord = useCallback(
        (declarationId: string) => {
            return dispatch(doCreateAmendmentGmrRecord(declarationId));
        },
        [dispatch]
    );

    const cancelAmendmentIrelandExportDeclaration = useCallback(
        async (declarationId: string) =>
            withResponseNotifications(
                await dispatch(doCancelAmendmentIrelandExportDeclaration(declarationId)),
                cancelAmendmentNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const cancelAmendmentGmrRecord = useCallback(
        async (declarationId: string) =>
            withResponseNotifications(
                await dispatch(doCancelAmendmentGmrRecord(declarationId)),
                cancelAmendmentNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const createAmendmentIrelandEntrySummaryDeclaration = useCallback(
        (reason: string, declarationId: string) => {
            return dispatch(doCreateAmendmentIrelandEntrySummaryDeclaration(declarationId, reason));
        },
        [dispatch]
    );

    const getDeclarationHistory = useCallback(
        (declarationId: string) => {
            return dispatch(doGetDeclarationHistory(declarationId));
        },
        [dispatch]
    );

    const createElectronicTransportDocument = useCallback(
        (customerId: string, ieImportElectronicTransportDocument?: ElectronicTransportDocument, jobId?: string) =>
            dispatch(
                doCreateElectronicTransportDocument(customerId, {
                    jobId,
                    declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                    declarationInternalType: DeclarationInternalType.ETD,
                    ieImportElectronicTransportDocument,
                })
            ),
        [dispatch]
    );

    const updateElectronicTransportDocument = useCallback(
        async (customerId: string, declarationId: string, declaration: ElectronicTransportDocumentPayload) =>
            withResponseNotifications(
                await dispatch(doUpdateElectronicTransportDocument(customerId, declarationId, declaration)),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const createArrivalAtExitDeclaration = useCallback(
        (customerId: string, selectedDeclaration: { id: string; mrn: string }, jobId?: string) =>
            dispatch(
                doCreateArrivalAtExitDeclaration(customerId, {
                    id: selectedDeclaration.id,
                    jobId,
                    declarationExternalEntity: DeclarationExternalEntity.REVENUE,
                    declarationInternalType: DeclarationInternalType.ARRIVAL,
                    ieArrivalAtExitDeclaration: {
                        messageType: AESMessageTypes.B1, // not used, mandatory when submitting
                        exportOperation: { mrn: selectedDeclaration.mrn },
                    },
                })
            ),
        [dispatch]
    );

    const updateArrivalAtExitDeclaration = useCallback(
        async (customerId: string, declarationId: string, declaration: ArrivalAtExitDeclarationPayload) =>
            withResponseNotifications(
                await dispatch(doUpdateArrivalAtExitDeclaration(customerId, declarationId, declaration)),
                updateDeclarationNotifs(t)
            ),
        [dispatch, withResponseNotifications, t]
    );

    const cancelCdsDeclaration = useCallback(
        (reason: string) => dispatch(doCancelCdsDeclaration(declaration?.id, reason)),
        [declaration?.id, dispatch]
    );

    const cancelAmendment = (declaration: Declaration) => {
        if (!declaration?.id) return;

        if (declaration?.ieExportDeclaration) {
            cancelAmendmentIrelandExportDeclaration(declaration.id);
        } else if (declaration?.gvmsDeclaration) {
            cancelAmendmentGmrRecord(declaration.id);
        }
    };

    const createAmendment = (declaration: Declaration, declarationId: string): Promise<any> | undefined => {
        if (declaration?.ieExportDeclaration) {
            return createAmendmentIrelandExportDeclaration(declarationId);
        } else if (declaration?.gvmsDeclaration) {
            return createAmendmentGmrRecord(declarationId);
        }
    };

    return {
        isLoading,
        error,
        declaration,
        declarationTemplate,
        documents,
        getDeclaration,
        getDeclarationTemplate,
        createIrelandImportDeclaration,
        createIrelandH7ImportDeclaration,
        createIrelandExportDeclaration,
        createUkExportDeclaration,
        createGvmsRecord,
        createPbnRecord,
        updateGvmsRecord,
        updatePbnRecord,
        createUkImportNewDeclaration,
        updateIrelandImportDeclaration,
        updateIrelandExportDeclaration,
        updateIrelandH7ImportDeclaration,
        updateCdsDeclaration,
        submitDeclaration,
        listDeclarationDocuments,
        toggleCoreTemplateStatus,
        updateEntrySummaryDeclaration,
        createEntrySummaryDeclaration,
        createTemporaryStorageDeclaration,
        updateTemporaryStorageDeclaration,
        duplicateDeclaration,
        invalidateDeclaration,
        invalidateNctsDeclaration,
        importPresentationNotification,
        submitIrelandImportDocuments,
        createAmendmentIrelandImportDeclaration,
        createAmendment,
        declarationHistory,
        createElectronicTransportDocument,
        updateElectronicTransportDocument,
        createArrivalAtExitDeclaration,
        updateArrivalAtExitDeclaration,
        createAmendmentIrelandEntrySummaryDeclaration,
        createIrelandH1ImportDeclarationWithFile,
        cancelCdsDeclaration,
        cancelAmendment,
        getDeclarationHistory,
        createNctsDeclaration,
        updateNctsDeclaration,
    };
};

export default useDeclarations;
