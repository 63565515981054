import { useTemplateContext } from 'components/ui/composed/template/TemplateContext';
import { FormikProvider, setNestedObjectValues, useFormik, useFormikContext } from 'formik';
import useDeclarationFormErrors from 'hooks/useDeclarationFormErrors';
import useDeclarations from 'hooks/useDeclarations';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationExternalEntity } from 'store/declarations/enums/common/declaration-external-entity';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { useDeclarationContext } from 'utils/DeclarationContext';
import useFormUtils from '../../hooks/useFormUtils';
import useGetDeclarationMapValues from '../../hooks/useGetDeclarationMapValues';
import useProducts from '../../hooks/useProducts';
import { ProductContext } from './common/declaration-view/ProductContext';
import EnsProductSection from './ireland/ens/EnsProductSection';
import IrelandEtdProductsSection from './ireland/etd/IrelandEtdProductsSection';
import IrelandExportProductsSection from './ireland/export/IrelandExportProductsSection';
import IrelandH1ProductsSection from './ireland/import/h1/IrelandH1ProductsSection';
import IrelandH7ProductsSection from './ireland/import/h7/IrelandH7ProductsSection';
import IrelandNctsProductsSection from './ireland/ncts/IrelandNctsProductsSection';
import IrelandTsdProductsSection from './ireland/tsd/IrelandTsdProductsSection';
import UkProductsSection from './uk/UkProductsSection';

interface Props {
    declaration?: Declaration | null | undefined;
}

const DeclarationProductView = ({ declaration: declarationProp }: Props) => {
    const context = useProductView();
    const { productId, declarationId } = useParams<string>();
    const { getDeclarationProduct, product } = useProducts({ productId });
    const { formType, internalType, countryLowerCase: country } = useFormUtils();
    const { setProductId } = useContext(ProductContext);
    const { transformData } = useGetDeclarationMapValues();

    const { template } = useTemplateContext();
    const { setForm } = useDeclarationContext();
    useEffect(() => {
        if (template) return;
        setForm?.('product');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mockFormik = useFormik({
        initialValues: {},
        onSubmit: () => {},
    });
    const formik = useMemo(() => context?.productsFormik || mockFormik, [context?.productsFormik, mockFormik]);

    const { declaration: _declaration } = useDeclarations();
    const declaration = declarationProp ?? _declaration;

    const { declarationErrors: formDeclarationErrors } = useDeclarationFormErrors();

    useEffect(() => {
        if (isEmpty(formDeclarationErrors.items)) return;

        context?.productsFormik?.validateForm().then((v) => {
            context?.productsFormik?.setTouched(setNestedObjectValues(v, true));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setUpContextAndFormik = useCallback(
        (product: any, productId: string | undefined) => {
            setProductId?.(productId);

            if (transformData?.product?.forClient) {
                const transformedProduct = transformData?.product?.forClient?.(product);
                formik.setValues(transformedProduct ?? {});
            }
        },
        [formik, setProductId, transformData?.product]
    );

    const fetchProduct = useCallback(
        (declarationId: string, productId: string) => {
            const fetchedProduct = getDeclarationProduct({ declarationId, productId, formType, internalType, country });
            setUpContextAndFormik(fetchedProduct, productId);
        },
        [country, formType, getDeclarationProduct, internalType, setUpContextAndFormik]
    );

    useEffect(() => {
        if (declarationId && productId && productId !== 'new') {
            if (product?.id !== productId && !context?.cancelFetchProduct) {
                fetchProduct(declarationId, productId);
            } else {
                setUpContextAndFormik(product, productId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context?.cancelFetchProduct, declarationId, productId]);

    const container = useMemo(() => {
        if (declaration) {
            switch (declaration.declarationExternalEntity) {
                case DeclarationExternalEntity.REVENUE: {
                    if (declaration.declarationInternalType === DeclarationInternalType.IMPORT) {
                        if (declaration.irelandImportDeclaration) {
                            return (
                                <IrelandH1ProductsSection
                                    formik={formik}
                                    toggleHelp={context?.toggleHelp}
                                    viewOnly={context?.viewOnly}
                                    productTemplate={context?.productTemplate}
                                    selectCard={context?.selectCard}
                                />
                            );
                        } else {
                            return (
                                <IrelandH7ProductsSection
                                    formik={formik}
                                    toggleHelp={context?.toggleHelp}
                                    viewOnly={context?.viewOnly}
                                />
                            );
                        }
                    } else if (
                        declaration.declarationInternalType === DeclarationInternalType.EXPORT ||
                        declaration.declarationInternalType === DeclarationInternalType.ARRIVAL
                    ) {
                        return <IrelandExportProductsSection formik={formik} />;
                    } else if (declaration.declarationInternalType === DeclarationInternalType.ENS) {
                        return (
                            <EnsProductSection
                                formik={formik}
                                toggleHelp={context?.toggleHelp}
                                viewOnly={context?.viewOnly}
                            />
                        );
                    } else if (declaration.declarationInternalType === DeclarationInternalType.TEMPORARY) {
                        return <IrelandTsdProductsSection formik={formik} />;
                    } else if (DeclarationInternalType.NCTS === declaration.declarationInternalType) {
                        return <IrelandNctsProductsSection formik={formik} />;
                    } else if (declaration.declarationInternalType === DeclarationInternalType.ETD) {
                        return <IrelandEtdProductsSection formik={formik} />;
                    } else return <></>;
                }
                case DeclarationExternalEntity.CDS:
                    return (
                        <UkProductsSection
                            formik={formik}
                            toggleHelp={context?.toggleHelp}
                            viewOnly={context?.viewOnly}
                        />
                    );

                default:
                    <></>;
            }
        }
        return <></>;
    }, [declaration, context, formik]);

    return <FormikProvider value={formik}>{container}</FormikProvider>;
};
export default DeclarationProductView;

export function useProductView() {
    return useOutletContext<
        | {
              productsFormik?: ReturnType<typeof useFormik>; // FIXME use correct type
              toggleHelp?: (refNumber: string | number) => void;
              viewOnly?: boolean;
              productTemplate?: boolean;
              selectCard?: (id: string) => void;
              cancelFetchProduct?: boolean;
          }
        | undefined
    >();
}
